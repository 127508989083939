import styled from "styled-components";
import { WHITE, BLACK } from "constants/index";
import PrioritiesForm from "containers/Forms/PrioritiesForm";
import HeadingForm from "containers/Forms/HeadingForm";
import StageForm from "containers/Forms/StageForm";
import SubStageForm from "containers/Forms/SubStageForm";
import NeedsForm from "containers/Forms/NeedsForm";
import TimeForm from "containers/Forms/TimeForm";
import ActivitiesForm from "containers/Forms/ActivitiesForm";
import MultiForm from "containers/Forms/MultiForm";
import Documents from "containers/Forms/Documents";
import ShareForm from "containers/Forms/ShareForm";
import RequiredSkillsForm from "containers/Forms/RequiredSkillsForm";
import TouchpointsForm from "containers/Forms/TouchpointsForm";
import DefaultForm from "containers/Forms/DefaultForm";
import AddHeadingForm from "containers/Forms/AddHeadingForm";
import AddStageForm from "containers/Forms/AddStageForm";
import AddSubStageForm from "containers/Forms/AddSubStageForm";
import DeleteHeadingForm from "containers/Forms/DeleteHeadingForm";
import DeleteStageForm from "containers/Forms/DeleteStageForm";
import DeleteSubStageForm from "containers/Forms/DeleteSubStageForm";
import MapTypeForm from "containers/Forms/MapTypeForm";
import { mapSettingsActions } from "redux/store/map-settings-slice";
import { useDispatch, useSelector } from "react-redux";
import BackgroundImage from "assets/images/popup-bar.png";
import { IoIosClose } from "react-icons/io";

const Backdrop = styled.div`
  background: ${BLACK};
  opacity: 0.4;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 4;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 4;
  width: 100%;
  position: fixed;
  top: -20px;
`;

const Modal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 4;
  width: 60vw;
  height: auto;
  min-height: 310px;
  position: fixed;
  padding-top: 55px;
  top: 3vh;
  background-color: ${WHITE};
  border-radius: 15px;
`;

const TopBar = styled.div`
  background-image: url(${BackgroundImage});
  background-repeat: "no-repeat";
  background-position: center top;
  background-size: cover;
  width: 60vw;
  position: relative;
  top: -55px;
  height: 45px;
  border-radius: 15px 15px 0px 0px;
  display: flex;
  justify-content: flex-end;
`;

const CloseIcon = styled.div`
  color: ${WHITE};
  font-size: 2.7em;
  width: 44px;
  height: 44px;
  display: flex;
  cursor: pointer;
`;

const Popup = () => {
  const dispatch = useDispatch();
  const formName = useSelector((state) => state.mapSettingsReducer.formName);

  const FormSwitch = (formName) => {
    switch (formName) {
      case "heading":
        return <HeadingForm />;
      case "addheading":
        return <AddHeadingForm />;
      case "deleteheading":
        return <DeleteHeadingForm />;
      case "stage":
        return <StageForm />;
      case "addstage":
        return <AddStageForm />;
      case "deletestage":
        return <DeleteStageForm />;
      case "addsubstage":
        return <AddSubStageForm />;
      case "deletesubstage":
        return <DeleteSubStageForm />;
      case "needs":
        return <NeedsForm />;
      case "priorities":
        return <PrioritiesForm />;
      case "substage":
        return <SubStageForm />;
      case "time":
        return <TimeForm />;
      case "activities":
        return <ActivitiesForm />;
      case "varifocal":
        return <MultiForm rowNumber={7} />;
      case "b2b":
        return <MultiForm rowNumber={8} />;
      case "hygiene":
        return <MultiForm rowNumber={10} />;
      case "touchpoints":
        return <TouchpointsForm />;
      case "skills":
        return <RequiredSkillsForm />;
      case "technology":
        return <MultiForm rowNumber={12} />;
      case "documents":
        return <Documents />;
      case "share":
        return <ShareForm />;
      case "maptype":
        return <MapTypeForm />;
      case "default":
        return <DefaultForm name="Journey Stage" row="1" />;
      default:
        return <NeedsForm />;
    }
  };

  const modalCloseHandler = () => {
    dispatch(mapSettingsActions.popupToggle());
  };
  return (
    <>
      <Backdrop id="backdrop" onClick={modalCloseHandler} />
      <Container>
        <Modal>
          <TopBar>
            <CloseIcon>
              <IoIosClose onClick={modalCloseHandler} />
            </CloseIcon>
          </TopBar>
          {FormSwitch(formName)}
        </Modal>
      </Container>
    </>
  );
};

export default Popup;
