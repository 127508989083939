import { useSelector, useDispatch } from "react-redux";
import { useRef, useState } from "react";
import { WHITE } from "constants/index";
import styled from "styled-components";
import { ResizableBox } from "react-resizable";
import "react-resizable/css/styles.css";
import moment from "moment";
import { dataActions } from "redux/store/data-slice";

const StyledResizableBox = styled(ResizableBox)`
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${WHITE};
  text-align: center;
  text-decoration: none;
  background: ${(props) => props.backgroundColour};
  border-radius: 5px 0px 0px 5px;
  height: ${(props) => props.height};
  cursor: default;
  font-size: 1rem;
  z-index: 3;
  position: relative;
  top: ${(props) => props.top};
`;

const StaticBox = styled.div`
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${WHITE};
  text-align: center;
  text-decoration: none;
  background: ${(props) => props.backgroundColour};
  border-radius: 5px;
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  cursor: default;
  font-size: 1rem;
  z-index: 3;
  position: relative;
  top: ${(props) => props.top};
`;

const TempDiv = styled.h1`
  display: flex;
  flex-direction: column;
  line-height: 2rem;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  padding: 16px 0px 16px 0px;
  position: relative;
  width: 100%;
  height: 60px;
  border-radius: 5px;
  background-color: ${(props) => props.backgroundColour};
`;

const Time = styled.p`
  font-weight: 400;
  color: ${WHITE};
  line-height: 1.2rem;
`;

const WarningTime = styled.p`
  font-weight: 400;
  color: ${WHITE};
  background-color: #e30613;
  border-radius: 10px;
  padding: 5px 10px 5px 10px;
  line-height: 1.2rem;
`;

const ResizableBlock = ({
  index,
  row,
  text,
  moreLess,
  initialTime,
  warningTime,
  backgroundColour,
  width,
  height,
  minWidth,
  top,
  children,
}) => {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.dataReducer.data);
  const [blockWidth, setBlockWidth] = useState(width);
  const lockMap = useSelector((state) => state.mapSettingsReducer.lockMap);

  const resizeHandler = (index) => {
    dispatch(dataActions.updateCurrentId(index));
    const initialPreference =
      data?.row[6].column[index].customer_time_preference;

    const neutralValue = data?.row[6].column[index].neutral;
    const unsatisfactoryValue = data?.row[6].column[index].unsatisfactory;
    const badValue = data?.row[6].column[index].bad;
    const veryBadValue = data?.row[6].column[index].very_bad;

    setBlockWidth(ref.current.offsetWidth);

    const newTime = ref.current.offsetWidth / 2.5;
    dispatch(
      dataActions.updateTime({
        time: newTime,
        preference: initialPreference,
        neutral: neutralValue,
        unsatisfactory: unsatisfactoryValue,
        bad: badValue,
        veryBad: veryBadValue,
      })
    );
  };

  const WidthToTimeHandler = (pixels) => {
    let totalTime = pixels / 2.5;

    if (totalTime > 119.9) {
      totalTime = moment
        .utc(totalTime * 1000)
        .format("m [minutes] s [seconds]");
    }

    if (totalTime < 59.9) {
      totalTime = moment.utc(totalTime * 1000).format("s [seconds]");
    } else if (totalTime > 59.9) {
      totalTime = moment.utc(totalTime * 1000).format("m [minute] s [seconds]");
    }

    // Makes 'seconds' 'second' if 1 second
    if (totalTime.includes(" 1 sec")) {
      totalTime = totalTime.substring(0, totalTime.length - 1);
    }

    return totalTime;
  };
  const initialTimeHandler = () => {
    if (moreLess === "less") {
      if (initialTime > warningTime) {
        return true;
      } else {
        return false;
      }
    }

    if (moreLess === "more") {
      if (initialTime < warningTime) {
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <>
      {!lockMap ? (
        <StyledResizableBox
          id="unscrollable"
          width={blockWidth}
          axis="x"
          className="box"
          height={height}
          onResize={() => resizeHandler(index)}
          backgroundColour={backgroundColour}
          top={top}
        >
          <TempDiv
            width={blockWidth}
            top={top}
            backgroundColour={backgroundColour}
            ref={ref}
          >
            <p>{text}</p>
            {initialTimeHandler() ? (
              <WarningTime>{WidthToTimeHandler(blockWidth)}</WarningTime>
            ) : (
              <Time>{WidthToTimeHandler(blockWidth)}</Time>
            )}
          </TempDiv>
        </StyledResizableBox>
      ) : (
        <StaticBox
          id="unscrollable"
          width={blockWidth + "px"}
          axis="x"
          className="box"
          height={height}
          onResize={() => resizeHandler(index)}
          backgroundColour={backgroundColour}
          top={top}
        >
          <TempDiv
            width={blockWidth}
            top={top}
            backgroundColour={backgroundColour}
            ref={ref}
          >
            <p>{text}</p>
            {blockWidth / 2.5 > 300 ? (
              <WarningTime>{WidthToTimeHandler(blockWidth)}</WarningTime>
            ) : (
              <Time>{WidthToTimeHandler(blockWidth)}</Time>
            )}
          </TempDiv>
        </StaticBox>
      )}
    </>
  );
};

export default ResizableBlock;
