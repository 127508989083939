import styled from "styled-components";
import Menu from "./index";
import { FiCheck } from "react-icons/fi";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

const CountryName = styled.p`
  cursor: pointer;
  &:hover {
    cursor: pointer;
    filter: drop-shadow(0px 0px 8px #fff);
  }
`;

const DisabledCountryName = styled.p`
  opacity: 0.4;
`;

const TickIcon = styled(FiCheck)`
  margin-left: 45px;
  font-size: 1.1rem;
  position: relative;
  top: 3px;
`;

const Markets = () => {
  return (
    <Menu height="150px">
      <Container>
        <CountryName>
          Norway
          <TickIcon />
        </CountryName>
        <DisabledCountryName>Sweden</DisabledCountryName>
        <DisabledCountryName>Finand</DisabledCountryName>
        <DisabledCountryName>Denmark</DisabledCountryName>
        <DisabledCountryName>Netherlands</DisabledCountryName>
      </Container>
    </Menu>
  );
};

export default Markets;
