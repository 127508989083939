import styled from "styled-components";
import axiosConfig from "services/api/axiosConfiig";
import { useDispatch } from "react-redux";
import { authActions } from "redux/store/auth-slice";
import { useRef, useState } from "react";
import { IoIosMail } from "react-icons/io";
import { RiLockPasswordFill } from "react-icons/ri";
import { useHistory, Link } from "react-router-dom";
import PrimaryButton from "components/shared/Buttons/PrimaryButton";
import { CgPassword } from "react-icons/cg";

const Container = styled.div`
  margin-top: 144px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LoginForm = styled.form`
  background-color: #eeeeee;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  width: 300px;
`;

const InputContainer = styled.div`
  position: relative;
`;

const InputBox = styled.input`
  height: 40px;
  width: 275px;
  padding: 5px 5px 5px 30px;
  margin-bottom: 12px;
  font-size: 0.9rem;
  font-weight: 400;
  color: #000;
`;

const EmailIcon = styled(IoIosMail)`
  position: absolute;
  font-size: 1.1rem;
  color: #6a6f73;
  left: 7.5px;
  top: 17px;
`;

const PasswordIcon = styled(RiLockPasswordFill)`
  position: absolute;
  font-size: 1.1rem;
  color: #6a6f73;
  left: 7.5px;
  top: 17px;
`;
const CodeIcon = styled(CgPassword)`
  position: absolute;
  font-size: 1.1rem;
  color: #6a6f73;
  left: 7.5px;
  top: 17px;
`;

const WarningBox = styled.div`
  width: 270px;
  border: 1px #767676 solid;
  margin-bottom: 12px;
  background-color: #ff8282;
  padding: 20px;
  font-size: 0.9rem;
  text-align: center;
  line-height: 18px;
`;

const ForgotPasswordText = styled.p`
  margin-top: 12px;
  margin-bottom: 200px;

  a {
    color: #009b4f;
    text-decoration: none;
  }
`;

const IntroText = styled.p`
  font-weight: 600;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #eee;
`;

const LoginCodeInfo = styled.div`
  max-width: 280px;
  height: auto;
  border-top: 1px solid #bbbbbb;
  padding-top: 18px;
  margin-top: 10px;
  margin-bottom: 18px;
  text-align: center;
  line-height: 18px;
`;

const Index = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const emailInputRef = useRef();
  const passwordInputRef = useRef();
  const codeInputRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [warningMessage, setWarningMessage] = useState();
  const [showCodeWarning, setShowCodeWarning] = useState(false);
  const [loginCodeValue, setLoginCodeValue] = useState("");

  const loginSuccessHandler = async (userId, accessToken) => {
    const config = {
      method: "get",
      headers: {
        Authorization: accessToken,
      },
    };

    await axiosConfig(`/users/${userId}`, config)
      .then(function (response) {
        dispatch(
          authActions.loginHandler({
            accessToken: accessToken,
            userId: userId,
            accountType: response.data.accountType,
            market: response.data.marketType,
          })
        );
        dispatch(authActions.isLoggedInToggle());
        history.replace("/");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    const enteredEmail = emailInputRef.current.value;
    const enteredPassword = passwordInputRef.current.value;

    try {
      setIsLoading(true);
      const { data } = await axiosConfig.post(`/authentication/login`, {
        email: enteredEmail,
        password: enteredPassword,
        loginCode: loginCodeValue,
      });
      // Success
      localStorage.setItem("token", data.accessToken);
      localStorage.setItem("userId", data.userId);
      localStorage.setItem("refresh", data.refreshToken);
      loginSuccessHandler(data.userId, data.accessToken);
    } catch (err) {
      const errorMessage = err.response.data.message;
      if (errorMessage === "IncorrectUsernamePassword") {
        setWarningMessage(
          "There was a problem logging in. Check your email and password."
        );
        setShowWarning(true);
        setIsLoading(false);
      } else if (errorMessage === "UserLockedOut") {
        setWarningMessage(
          "There was a problem logging in. This account has been locked."
        );
        setShowWarning(true);
        setIsLoading(false);
      } else if (errorMessage === "TwoFactorAuthIsNeeded") {
        setShowCodeWarning(true);
        setIsLoading(false);
      } else {
        setWarningMessage("An unknown error occurred. Please try again.");
        setShowWarning(true);
        setIsLoading(false);
      }
    }
  };

  return (
    <Container>
      <IntroText>Log in to your Customer Journey Portal account</IntroText>
      <LoginForm>
        {showWarning && <WarningBox>{warningMessage}</WarningBox>}
        <InputContainer>
          <EmailIcon />
          <InputBox
            placeholder="Email"
            required
            ref={emailInputRef}
            onChange={() => {
              setShowWarning(false);
            }}
          />
        </InputContainer>
        <InputContainer>
          <PasswordIcon />
          <InputBox
            type="password"
            placeholder="Password"
            ref={passwordInputRef}
            onChange={() => {
              setShowWarning(false);
            }}
          />
        </InputContainer>
        {showCodeWarning && (
          <>
            <LoginCodeInfo>
              A login code has been sent to your email, please enter it below.
            </LoginCodeInfo>
            <InputContainer>
              <CodeIcon />
              <InputBox
                placeholder="Login Code"
                required
                ref={codeInputRef}
                onChange={(t) => setLoginCodeValue(t.target.value)}
              />
            </InputContainer>
          </>
        )}
        <PrimaryButton width="314px" fontSize="1rem" onClick={submitHandler}>
          {isLoading ? <p>Logging In....</p> : <p>Log In</p>}
        </PrimaryButton>
      </LoginForm>
      <ForgotPasswordText>
        or <Link to="/forgotpassword">Forgot Password</Link>
      </ForgotPasswordText>
    </Container>
  );
};

export default Index;
