import styled from "styled-components";

const StyledDropDown = styled.div`
  min-width: 135px;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  position: relative;
  left: -40px;
  top: -1px;
  z-index: 5;
  background: linear-gradient(
    350deg,
    rgba(13, 157, 76, 1) 20%,
    rgba(69, 175, 71, 1) 100%
  );
  line-height: 30px;
  padding: 15px;
  font-size: 1rem;
`;

const index = ({ width, height, children }) => {
  return (
    <StyledDropDown width={width} height={height}>
      {children}
    </StyledDropDown>
  );
};

export default index;
