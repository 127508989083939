import { useState, useRef } from "react";
import styled from "styled-components";
import { dataActions } from "redux/store/data-slice";
import { mapSettingsActions } from "redux/store/map-settings-slice";
import { useSelector, useDispatch } from "react-redux";
import { FaRegSave, FaRegTrashAlt } from "react-icons/fa";
import PrimaryButton from "components/shared/Buttons/PrimaryButton";

const Container = styled.div`
  position: relative;
  top: -35px;
`;

const FormHeading = styled.h1`
  font-size: 1.5rem;
`;

const FormContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Input = styled.input`
  padding: 10px;
  color: #000000;
  background: #ffffff;
  border: 0.5px solid #000000;
  border-radius: 3px;
  width: 100%;
  height: 40px;
  margin-bottom: 20px;
  margin-top: 5px;
  font-size: 1.4em;
`;

const SecondaryButton = styled.button`
  width: 160px;
  padding: 10px;
  background-color: #e30613;
  height: 60px;
  border: none;
  border-radius: 3px;
  margin-top: 15px;
  margin-left: 3px;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  font-size: 1.2em;

  &:hover {
    background-color: #00693b;
  }
`;

const StyledLabel = styled.label``;

const Form = () => {
  const primaryTextAreaRef = useRef();
  const secondaryTextAreaRef = useRef();

  const dispatch = useDispatch();
  const id = useSelector((state) => state.dataReducer.currentChildId);
  const data = useSelector((state) => state.dataReducer.data);
  const containerId = useSelector((state) => state.dataReducer.currentId);
  const text = data?.row[5].column[containerId].primary[id];

  const saveHandler = () => {
    dispatch(
      dataActions.updateColleagueActivites({
        updatedPrimaryText: primaryTextInput,
        updatedSecondaryText: secondaryTextInput,
      })
    );
    dispatch(mapSettingsActions.popupToggle());
  };

  const deleteHandler = () => {
    dispatch(dataActions.deleteColleagueActivity({ id }));
    dispatch(mapSettingsActions.popupToggle());
  };

  const [primaryTextInput, setPrimaryTextInput] = useState(text.primary_text);
  const [secondaryTextInput, setSecondaryTextInput] = useState(
    text.secondary_text
  );

  return (
    <Container>
      <FormHeading>Store Activities</FormHeading>
      <FormContainer>
        <StyledLabel>English:</StyledLabel>

        <Input
          id="Popuptext"
          ref={primaryTextAreaRef}
          value={primaryTextInput}
          autocomplete="off"
          onChange={(e) => setPrimaryTextInput(e.target.value)}
        ></Input>
        <StyledLabel>Market Language:</StyledLabel>
        <Input
          id="Popuptext"
          ref={secondaryTextAreaRef}
          value={secondaryTextInput}
          autocomplete="off"
          onChange={(e) => setSecondaryTextInput(e.target.value)}
        />
      </FormContainer>

      <PrimaryButton width="160px" fontSize="1.2rem" onClick={saveHandler}>
        Save <FaRegSave id="icon" style={{ verticalAlign: "middle" }} />
      </PrimaryButton>
      <SecondaryButton onClick={deleteHandler}>
        Delete{" "}
        <FaRegTrashAlt size="1.2rem" style={{ verticalAlign: "middle" }} />
      </SecondaryButton>
    </Container>
  );
};

export default Form;
