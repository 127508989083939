import { useRef, useState } from "react";
import styled from "styled-components";
import { useParams, Link } from "react-router-dom";
import axiosConfig from "services/api/axiosConfiig";
import PrimaryButton from "components/shared/Buttons/PrimaryButton";

const Container = styled.div`
  margin-left: 10vw;
  width: 80vw;
  height: auto;
  margin-top: 80px;
  margin-bottom: 40px;
`;

const Header = styled.div`
  width: 100%;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #d1d7dc;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 1.4rem;
  margin-bottom: 10px;
`;

const SetUpContainer = styled.div`
  width: 70%;
  margin-left: 15%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 28px;
  padding-bottom: 30px;
  border-bottom: 1px solid #d1d7dc;
  margin-bottom: 40px;
`;

const InputBox = styled.input`
  height: 40px;
  width: 64%;
  padding: 5px 5px 5px 15px;
  margin-top: 6px;
  margin-bottom: 16px;
  font-size: 0.9rem;
  font-weight: 400;
  color: #000;
`;

const Label = styled.label`
  width: 66%;
`;

const UserSetUpMessage = styled.p`
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
`;

const WarningBox = styled.div`
  width: 270px;
  border: 1px #767676 solid;
  margin-bottom: 12px;
  background-color: #ff8282;
  padding: 20px;
  font-size: 0.9rem;
  text-align: center;
  line-height: 18px;
`;

const Index = () => {
  const { token } = useParams();
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const passwordRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [userSetUp, setUserSetUp] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [warningMessage, setWarningMessage] = useState();

  const setUpUserHandler = async (event) => {
    event.preventDefault();
    const currentFirstName = firstNameRef.current.value;
    const currentLastName = lastNameRef.current.value;
    const currentPassword = passwordRef.current.value;

    try {
      setIsLoading(true);
      const { data } = await axiosConfig.post(`/users`, {
        token: token,
        firstName: currentFirstName,
        lastName: currentLastName,
        password: currentPassword,
      });
      // Success
      setIsLoading(false);
      setShowWarning(false);
      setUserSetUp(true);
    } catch (err) {
      const errorMessage = err.response.data.message;
      if (errorMessage === "WeakPassword") {
        setWarningMessage(
          "Password is too weak. Password must contain letters, numbers, a symbol and be 8 characters long."
        );
        setShowWarning(true);
        setIsLoading(false);
      } else if (errorMessage === "InvalidInvitationToken") {
        setWarningMessage(
          "Invalid Invitation Token. You may already have an account or require a new invitation."
        );
        setShowWarning(true);
        setIsLoading(false);
      } else {
        setWarningMessage("An unknown error occurred. Please try again.");
        setShowWarning(true);
        setIsLoading(false);
      }
    }
  };

  return (
    <Container>
      <Header>
        <Title>User Set Up</Title>Complete setting up your account.
      </Header>
      <SetUpContainer>
        {showWarning && <WarningBox>{warningMessage}</WarningBox>}
        {!userSetUp && (
          <>
            <Label>First Name:</Label>
            <InputBox
              placeholder="Enter First Name"
              ref={firstNameRef}
              onChange={() => {
                setShowWarning(false);
              }}
            ></InputBox>
            <Label>Last Name:</Label>
            <InputBox
              placeholder="Enter Last Name"
              ref={lastNameRef}
              onChange={() => {
                setShowWarning(false);
              }}
            ></InputBox>
            <Label>Password:</Label>
            <InputBox
              type="password"
              placeholder="Enter Password"
              ref={passwordRef}
              onChange={() => {
                setShowWarning(false);
              }}
            ></InputBox>
          </>
        )}
        {userSetUp ? (
          <UserSetUpMessage>
            Account created! You can now login.
            <Link to="/login">
              {" "}
              <PrimaryButton width="314px" fontSize="1rem">
                Log In
              </PrimaryButton>
            </Link>
          </UserSetUpMessage>
        ) : (
          <PrimaryButton
            width="314px"
            fontSize="1rem"
            onClick={setUpUserHandler}
          >
            {isLoading ? <p>Creating Account...</p> : <p>Create Account</p>}
          </PrimaryButton>
        )}
      </SetUpContainer>
    </Container>
  );
};

export default Index;
