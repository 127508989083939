export const getColorPalette = (wait = 1000): Promise<string[]> =>
  new Promise((resolve) => {
    setTimeout(() => resolve(colorPalette), wait);
  });

const colorPalette = [
  "#f0ff4d",
  "#eeff33",
  "#ecff1a",
  "#eaff00",
  "#d2e600",
  "#c1d100",
  "#bbcc00",
  "#a4b300",
  "#7cd477",
  "#69ce64",
  "#57c851",
  "#44c23d",
  "#3dae37",
  "#3baa35",
  "#369b31",
  "#2f882b",
  "#02e389",
  "#02ca7a",
  "#02b16b",
  "#02975b",
  "#017e4c",
  "#016c42",
  "#01653d",
  "#014c2e",
  "#a6d8ce",
  "#95d0c4",
  "#83c9bb",
  "#71c1b1",
  "#63baa9",
  "#5fb9a7",
  "#4eb19d",
  "#46a08e",
  "#1c84ca",
  "#1876b4",
  "#15679d",
  "#125887",
  "#0f4970",
  "#0d3f61",
  "#0c3b5a",
  "#092c43",
  "#fbe1b6",
  "#fad79e",
  "#f9cd86",
  "#f7c36e",
  "#f6b855",
  "#f5ae3d",
  "#f4a425",
  "#f29a0d",
  "#ff4d5b",
  "#ff3344",
  "#ff1a2d",
  "#ff0015",
  "#e60013",
  "#d30012",
  "#cc0011",
  "#b3000f",
];
