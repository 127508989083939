import styled from "styled-components";
import { WHITE, SPECSAVERS_GREEN } from "constants/index";

const StyledTable = styled.table`
  border: none;
  border-collapse: collapse;
  td,
  th {
    border: none;
    height: 40px;
    padding-right: 8px;
    :last-child {
      padding-right: 14px;
    }
  }
  td {
    padding: 5px 10px;
  }
  tbody tr {
    text-align: center;
    :nth-of-type(odd) {
      background-color: #f2f2f2;
    }
    :hover {
      background-color: #e6e6e6;
    }
  }
  thead > tr {
    background-color: ${SPECSAVERS_GREEN};
    color: ${WHITE};
  }
  caption {
    font-size: 0.9em;
    padding: 5px;
    font-weight: bold;
  }
`;

export default StyledTable;
