import { useState, useEffect } from "react";
import styled from "styled-components";
import { WHITE } from "constants/index";
import { useSelector, useDispatch } from "react-redux";
import { dataActions } from "redux/store/data-slice";
import { mapSettingsActions } from "redux/store/map-settings-slice";
import { backgroundHandler } from "utils/backgroundHandler";

const Container = styled.div`
  min-width: ${(props) => props.columnWidth};
  display: flex;
  margin-right: 5px;
  text-align: center;
  justify-content: center;
`;

const StyledBubble = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${WHITE};
  text-align: center;
  padding: 16px;
  text-decoration: none;
  background: ${(props) => props.backgroundGradient};
  border-radius: 22px;
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  cursor: default;
  font-size: 1rem;
  max-width: 373px;
  min-width: 373px;
  margin-left: -22px;
  line-height: 22px;
`;

const Pointer = styled.div`
  background-color: ${(props) => props.backgroundColour};
  width: 0px;
  height: 0px;
  position: relative;
  border-right: 20px solid ${WHITE};
  border-left: 8px solid transparent;
  border-bottom: 8px solid ${WHITE};
  border-top: 16px solid transparent;
  top: 107px;
  left: 60px;
`;

const Bubble = ({ id, sectionId, text, width, height, columnWidth }) => {
  const data = useSelector((state) => state.dataReducer.data);
  const headingData = data.row[0].column
  const sectionIndex = headingData.findIndex(function (getHeadingId) {
    return getHeadingId.id === sectionId;
  });

  const primaryHex = useSelector(
    (state) => state.dataReducer.data.row[0].column[sectionIndex].background_colour
  );
  const lockMap = useSelector((state) => state.mapSettingsReducer.lockMap);

  const dispatch = useDispatch();
  const needsData = data?.row[2].column;
  const [events, updateEvents] = useState(needsData);
  useEffect(() => {
    updateEvents(needsData);
  }, [needsData]);

  const blockDataHandler = (id) => {
    dispatch(mapSettingsActions.popupForm("needs"));
    dispatch(dataActions.updateCurrentId(id));
    dispatch(mapSettingsActions.popupToggle());
    updateEvents(needsData);
  };

  return (
    <Container
      columnWidth={columnWidth}
      {...(!lockMap && { onClick: () => blockDataHandler(id) })}
    >
      <Pointer backgroundColour={primaryHex} />
      <StyledBubble
        width={width}
        height={height}
        backgroundGradient={backgroundHandler(primaryHex)}
      >
        <h3 style={{ fontWeight: "300" }}>{text}</h3>
      </StyledBubble>
    </Container>
  );
};

export default Bubble;
