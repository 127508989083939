import Heading from "components/DynamicMappingTool/Rows/Heading";
import JourneyStage from "components/DynamicMappingTool/Rows/JourneyStage";
import CustomerNeeds from "components/DynamicMappingTool/Rows/CustomerNeeds";
import ColleaguePriorities from "components/DynamicMappingTool/Rows/ColleaguePriorities";
import TimedSubStages from "components/DynamicMappingTool/Rows/TimedSubStages";
import ColleagueActivities from "components/DynamicMappingTool/Rows/ColleagueActivities";
import CustomerSatisfaction from "components/DynamicMappingTool/Rows/CustomerSatisfaction";
import SatisfactionLineToggle from "components/DynamicMappingTool/Rows/SatisfactionLineToggle";
import VarifocalDifferences from "components/DynamicMappingTool/Rows/VarifocalDifferences";
import B2BDifferences from "components/DynamicMappingTool/Rows/B2BDifferences";
import HygieneProcedures from "components/DynamicMappingTool/Rows/HygieneProcedures";
import CustomerTouchpoints from "components/DynamicMappingTool/Rows/CustomerTouchpoints";
import RequiredSkills from "components/DynamicMappingTool/Rows/RequiredSkills";
import Technology from "components/DynamicMappingTool/Rows/Technology";
import TechnologyDocuments from "components/DynamicMappingTool/Rows/TechnologyDocuments";
import DocumentsRow from "components/DynamicMappingTool/Rows/Documents";
import { useSelector } from "react-redux";

const MapContainer = () => {
  const rowVisibilityData = useSelector(
    (state) => state.mapSettingsReducer.rowVisibilityData
  );

  return (
    <div>
      {/* DMT  */}
      <Heading />
      {rowVisibilityData.row[0].visible && <JourneyStage />}
      {rowVisibilityData.row[1].visible && <CustomerNeeds />}
      {rowVisibilityData.row[2].visible && <ColleaguePriorities />}
      {rowVisibilityData.row[3].visible && <TimedSubStages />}
      {rowVisibilityData.row[4].visible && <ColleagueActivities />}
      {rowVisibilityData.row[5].visible && (
        <CustomerSatisfaction
          backgroundTop="-110px"
          top="-135px"
          width="4342px"
        />
      )}
      {rowVisibilityData.row[5].visible && <SatisfactionLineToggle />}
      {rowVisibilityData.row[6].visible && <VarifocalDifferences />}
      {rowVisibilityData.row[7].visible && <B2BDifferences />}
      {rowVisibilityData.row[8].visible && <CustomerTouchpoints />}
      {rowVisibilityData.row[9].visible && <HygieneProcedures />}
      {rowVisibilityData.row[10].visible && <RequiredSkills />}
      {rowVisibilityData.row[11].visible && <Technology />}
      {/* {rowVisibilityData.row[12].visible && <TechnologyDocuments />}
      {rowVisibilityData.row[13].visible && <DocumentsRow />} */}
    </div>
  );
};

export default MapContainer;
