import { useState, useRef } from "react";
import styled from "styled-components";
import { WHITE, BLACK, SPECSAVERS_GREEN } from "constants/index";
import { mapSettingsActions } from "redux/store/map-settings-slice";
import { dataActions } from "redux/store/data-slice";
import { useSelector, useDispatch } from "react-redux";
import { FaRegSave, FaRegTrashAlt } from "react-icons/fa";
import PrimaryButton from "components/shared/Buttons/PrimaryButton";
import TPIcon00 from "assets/images/touchpoint_icons/icon_tp_00.png";
import TPIcon01 from "assets/images/touchpoint_icons/icon_tp_01.png";
import TPIcon02 from "assets/images/touchpoint_icons/icon_tp_02.png";
import TPIcon03 from "assets/images/touchpoint_icons/icon_tp_03.png";
import TPIcon04 from "assets/images/touchpoint_icons/icon_tp_04.png";
import TPIcon05 from "assets/images/touchpoint_icons/icon_tp_05.png";
import TPIcon06 from "assets/images/touchpoint_icons/icon_tp_06.png";
import TPIcon07 from "assets/images/touchpoint_icons/icon_tp_07.png";
import TPIcon08 from "assets/images/touchpoint_icons/icon_tp_08.png";
import TPIcon09 from "assets/images/touchpoint_icons/icon_tp_09.png";
import TPIcon10 from "assets/images/touchpoint_icons/icon_tp_10.png";
import TPIcon11 from "assets/images/touchpoint_icons/icon_tp_11.png";
import TPIcon12 from "assets/images/touchpoint_icons/icon_tp_12.png";
import TPIcon13 from "assets/images/touchpoint_icons/icon_tp_13.png";
import TPIcon14 from "assets/images/touchpoint_icons/icon_tp_14.png";
import TPIcon15 from "assets/images/touchpoint_icons/icon_tp_15.png";
import TPIcon16 from "assets/images/touchpoint_icons/icon_tp_16.png";
import TPIcon17 from "assets/images/touchpoint_icons/icon_tp_17.png";
import TPIcon18 from "assets/images/touchpoint_icons/icon_tp_18.png";
import TPIcon19 from "assets/images/touchpoint_icons/icon_tp_19.png";
import TPIcon20 from "assets/images/touchpoint_icons/icon_tp_20.png";

const Container = styled.div`
  position: relative;
  top: -35px;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
`;

const FormContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const FormHeading = styled.h1`
  font-size: 1.5rem;
`;

const Input = styled.input`
  padding: 10px;
  color: ${BLACK};
  background: ${WHITE};
  border: 0.5px solid ${BLACK};
  border-radius: 3px;
  width: 300px;
  height: 40px;
  margin-bottom: 20px;
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 10px;
  font-size: 1.4em;
`;

const SecondaryButton = styled.button`
  width: 160px;
  padding: 10px;
  background-color: #e30613;
  height: 60px;
  border: none;
  border-radius: 3px;
  margin-top: 15px;
  margin-left: 3px;
  color: ${WHITE};
  font-size: 1rem;
  cursor: pointer;
  font-size: 1.2em;

  &:hover {
    background-color: #00693b;
  }
`;

const ButtonContainer = styled.div`
  width: 400px;
  height: 100px;
  text-align: center;
  margin-top: 25px;
`;

const IconSelectContainer = styled.div`
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
  width: 500px;
  padding-bottom: 5px;
`;

const StyledTPIcon = styled.img`
  width: 100px;
  height: 100px;
  margin-bottom: 3px;
  border-radius: 3px;
`;

const IconBox = styled.div`
  border: 2px solid ${WHITE};
  border-radius: 4px;
  margin-right: 2px;

  &:hover {
    border: 2px solid ${SPECSAVERS_GREEN};
    cursor: pointer;
  }
`;

const CurrentIcon = styled.div`
  border: 2px solid ${SPECSAVERS_GREEN};
  border-radius: 4px;
  margin-right: 2px;
`;

const StyledLabel = styled.label`
  position: relative;
  width: 320px;
  text-align: left;
`;

const Form = () => {
  const primaryTextAreaRef = useRef();
  const secondaryTextAreaRef = useRef();

  const dispatch = useDispatch();
  const id = useSelector((state) => state.dataReducer.currentChildId);
  const data = useSelector((state) => state.dataReducer.data);
  const containerId = useSelector((state) => state.dataReducer.currentId);
  const text = data?.row[9].column[containerId].primary[id];
  const currentIcon = data?.row[9].column[containerId].primary[id].icon;

  const [selectedIcon, setSelectedIcon] = useState(currentIcon);

  const iconChangeHandler = (i) => {
    setSelectedIcon(i);
  };

  const saveHandler = () => {
    dispatch(mapSettingsActions.setIsBlockUpdated("touchpoints"));
    dispatch(
      dataActions.updateCustomerTouchpoints({
        id,
        updatedPrimaryText: primaryTextInput,
        updatedSecondaryText: secondaryTextInput,
        updatedIcon: selectedIcon,
      })
    );

    dispatch(mapSettingsActions.popupToggle());
  };
  const deleteHandler = () => {
    dispatch(mapSettingsActions.setIsBlockUpdated("touchpoints"));

    dispatch(dataActions.deleteCustomerTouchpoints({ id }));

    dispatch(mapSettingsActions.popupToggle());
  };

  const [primaryTextInput, setPrimaryTextInput] = useState(text.primary_text);
  const [secondaryTextInput, setSecondaryTextInput] = useState(
    text.secondary_text
  );

  const iconSwitch = (icon) => {
    switch (icon) {
      case 0:
        return <StyledTPIcon src={TPIcon00} alt="Touchpoint Icon" />;
      case 1:
        return <StyledTPIcon src={TPIcon01} alt="Touchpoint Icon" />;
      case 2:
        return <StyledTPIcon src={TPIcon02} alt="Touchpoint Icon" />;
      case 3:
        return <StyledTPIcon src={TPIcon03} alt="Touchpoint Icon" />;
      case 4:
        return <StyledTPIcon src={TPIcon04} alt="Touchpoint Icon" />;
      case 5:
        return <StyledTPIcon src={TPIcon05} alt="Touchpoint Icon" />;
      case 6:
        return <StyledTPIcon src={TPIcon06} alt="Touchpoint Icon" />;
      case 7:
        return <StyledTPIcon src={TPIcon07} alt="Touchpoint Icon" />;
      case 8:
        return <StyledTPIcon src={TPIcon08} alt="Touchpoint Icon" />;
      case 9:
        return <StyledTPIcon src={TPIcon09} alt="Touchpoint Icon" />;
      case 10:
        return <StyledTPIcon src={TPIcon10} alt="Touchpoint Icon" />;
      case 11:
        return <StyledTPIcon src={TPIcon11} alt="Touchpoint Icon" />;
      case 12:
        return <StyledTPIcon src={TPIcon12} alt="Touchpoint Icon" />;
      case 13:
        return <StyledTPIcon src={TPIcon13} alt="Touchpoint Icon" />;
      case 14:
        return <StyledTPIcon src={TPIcon14} alt="Touchpoint Icon" />;
      case 15:
        return <StyledTPIcon src={TPIcon15} alt="Touchpoint Icon" />;
      case 16:
        return <StyledTPIcon src={TPIcon16} alt="Touchpoint Icon" />;
      case 17:
        return <StyledTPIcon src={TPIcon17} alt="Touchpoint Icon" />;
      case 18:
        return <StyledTPIcon src={TPIcon18} alt="Touchpoint Icon" />;
      case 19:
        return <StyledTPIcon src={TPIcon19} alt="Touchpoint Icon" />;
      case 20:
        return <StyledTPIcon src={TPIcon20} alt="Touchpoint Icon" />;

      default:
        return <StyledTPIcon src={TPIcon01} alt="Touchpoint Icon" />;
    }
  };

  return (
    <Container>
      <FormHeading>Customer Touchpoints</FormHeading>
      <FormContainer>
        <StyledLabel>English:</StyledLabel>

        <Input
          id="Popuptext"
          ref={primaryTextAreaRef}
          value={primaryTextInput}
          autocomplete="off"
          onChange={(e) => setPrimaryTextInput(e.target.value)}
        ></Input>
        <StyledLabel>Market Language:</StyledLabel>

        <Input
          id="Popuptext"
          ref={secondaryTextAreaRef}
          value={secondaryTextInput}
          autocomplete="off"
          onChange={(e) => setSecondaryTextInput(e.target.value)}
        />

        <IconSelectContainer>
          {[...Array(20)].map((x, i) => (
            <>
              {i === selectedIcon ? (
                <CurrentIcon>{iconSwitch(i)}</CurrentIcon>
              ) : (
                <IconBox
                  onClick={() => {
                    iconChangeHandler(i);
                  }}
                >
                  {" "}
                  {iconSwitch(i)}{" "}
                </IconBox>
              )}
            </>
          ))}
        </IconSelectContainer>
      </FormContainer>

      <ButtonContainer>
        <PrimaryButton width="160px" fontSize="1.2rem" onClick={saveHandler}>
          Save <FaRegSave id="icon" style={{ verticalAlign: "middle" }} />
        </PrimaryButton>
        <SecondaryButton onClick={deleteHandler}>
          Delete{" "}
          <FaRegTrashAlt size="1.2rem" style={{ verticalAlign: "middle" }} />
        </SecondaryButton>
      </ButtonContainer>
    </Container>
  );
};

export default Form;
