import { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams, Link } from "react-router-dom";
import MapDetails from "./MapDetails";
import axiosConfig from "services/api/axiosConfiig";
import PrimaryButton from "components/shared/Buttons/PrimaryButton";
import { SPECSAVERS_GREEN, SPECSAVERS_DARK_GREEN } from "constants/index";
import { FaFileDownload } from "react-icons/fa";
import DotLoader from "react-spinners/DotLoader";
import { useSelector } from "react-redux";

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 18.5%;
  margin-top: 100px;
  margin-bottom: 120px;

  h1 {
    margin-left: 4px;
  }

  h2 {
    margin-left: 4px;
    margin-top: 20px;
  }
`;

const MapDetailsRow = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  max-width: 80%;

  img {
    width: 80%;
    height: auto;
  }

  a:link {
    color: #000;
    text-decoration: none;
  }

  a:visited {
    color: #000;
    text-decoration: none;
  }
`;

const MapListBlock = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 80%;
  white-space: initial;
  word-wrap: break-word;
  padding-bottom: 20px;
  height: auto;
  display: inline-flexbox;
  flex-flow: wrap;

  img {
    width: 80%;
    height: auto;
  }

  a:link {
    color: #000;
    text-decoration: none;
  }

  a:visited {
    color: #000;
    text-decoration: none;
  }
`;

const FileBox = styled.div`
  width: 250px;
  height: 75px;
  color: white;
  background-color: ${SPECSAVERS_GREEN};
  margin-top: 10px;
  margin-right: 15px;
  margin-left: 15px;
  margin-bottom: 25px;
  line-height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 20px;
  border: none;
  border-radius: 3px;
  word-break: break-all;
  font-size: 14px;

  &:hover {
    cursor: pointer;
    background-color: ${SPECSAVERS_DARK_GREEN};
  }
`;

const ButtonContainer = styled.div`
  width: 60.5vw;
  padding: 0px 0px 20px 20px;
  display: flex;
  justify-content: center;
  margin-left: 45px;
`;

const ButtonContainerSpace = styled.div`
  height: 40px;
`;

const LoadingDataContainer = styled.div`
  width: 60vw;
  position: relative;
  height: 400px;
  top: 8vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 2;
`;

const LoadingText = styled.p`
  margin-top: 25px;
  font-size: 2rem;
  font-weight: bold;
  color: ${SPECSAVERS_GREEN};
`;

const StyledDownloadIcon = styled(FaFileDownload)`
  font-size: 40px;
  position: relative;
  margin-bottom: 5px;
`;

const NoMapsMessage = styled.p`
  font-size: 1.2rem;
  margin-left: 3px;
  margin-top: 20px;
  margin-bottom: 35px;
`;

const MarketLanding = () => {
  const accountType = useSelector((state) => state.authReducer.accountType);
  const userId = localStorage.getItem("userId");

  let canManageFiles = false;
  let hasPrivateMaps = true;

  if (accountType === "Admin" || accountType === "SuperAdmin") {
    canManageFiles = true;
  }

  if (accountType === "User") {
    hasPrivateMaps = false;
  }

  const [rendered, setRendered] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [fileData, setFileData] = useState([]);

  const [mapList, setMapList] = useState([]);

  const accessToken = localStorage.getItem("token");

  /** Changes the first character of the parameter a capital */
  let { country } = useParams();
  let marketTitle = country[0].toUpperCase() + country.slice(1);
  let marketTitleLower = country.toLowerCase();
  /** Inserts a space btweeen 'All markets' */
  if (marketTitle === "Allmarkets") {
    marketTitle = marketTitle.replace(/.{7}$/, " $&");
  }

  const marketIDSwitch = (marketTitleLower) => {
    switch (marketTitleLower) {
      case "norway":
        return 1;
      case "sweden":
        return 2;
      case "finland":
        return 3;
      case "denmark":
        return 4;
      case "netherlands":
        return 5;

      default:
        return 1;
    }
  };

  const marketID = marketIDSwitch(marketTitleLower);

  const getDataHandler = async () => {
    const config = {
      method: "get",
      headers: {
        Authorization: accessToken,
      },
    };

    let allMarketsData = [];
    await axiosConfig(`/markets/1/maps`, config)
      .then(function (response) {
        allMarketsData = allMarketsData.concat(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    await axiosConfig(`/markets/2/maps`, config)
      .then(function (response) {
        allMarketsData = allMarketsData.concat(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    await axiosConfig(`/markets/3/maps`, config)
      .then(function (response) {
        allMarketsData = allMarketsData.concat(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    await axiosConfig(`/markets/4/maps`, config)
      .then(function (response) {
        allMarketsData = allMarketsData.concat(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    await axiosConfig(`/markets/5/maps`, config)
      .then(function (response) {
        allMarketsData = allMarketsData.concat(response.data);
        setMapList(allMarketsData);
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const deleteMapHandler = async (mapId) => {
    alert("You can only delete a map from within the maps market page");
  };

  const noMapsHandler = () => {
    let usersMapCount = 0;
    for (let i = 0; i < mapList.length; i++) {
      if (mapList[i].createdBy === userId) {
        usersMapCount++;
      }
    }

    if (usersMapCount === 0) {
      return true;
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getDataHandler();
    setRendered(true);
  }, [rendered, country]);

  return (
    <Container>
      {!isLoading ? (
        <>
          <h1>{marketTitle}</h1>
          <h2>Glasses</h2>
          <MapListBlock>
            {mapList.map((mapInfo, index) => (
              <>
                {mapInfo.mapType === "Original" &&
                  mapInfo.productType === "Glasses" && (
                    <MapDetails
                      key={index}
                      deleteMapHandler={deleteMapHandler}
                      mapName={mapInfo.name}
                      mapMarket={marketTitleLower}
                      mapTime=""
                      mapId={mapInfo.id}
                      mapOwner={mapInfo.createdBy}
                      userGenerated={true}
                    />
                  )}
              </>
            ))}
          </MapListBlock>
          <MapDetailsRow></MapDetailsRow>
          <h2>Contact Lenses</h2>
          <MapListBlock>
            {mapList.map((mapInfo, index) => (
              <>
                {mapInfo.mapType === "Original" &&
                  mapInfo.productType === "Lenses" && (
                    <MapDetails
                      key={index}
                      deleteMapHandler={deleteMapHandler}
                      mapName={mapInfo.name}
                      mapMarket={marketTitleLower}
                      mapTime=""
                      mapId={mapInfo.id}
                      mapOwner={mapInfo.createdBy}
                      userGenerated={true}
                    />
                  )}
              </>
            ))}
          </MapListBlock>

          {(marketTitleLower === "netherlands" ||
            marketTitleLower === "allmarkets") && (
            <>
              <MapDetailsRow />
              <h2>Audiology</h2>
              <MapListBlock>
                {mapList.map((mapInfo, index) => (
                  <>
                    {mapInfo.mapType === "Original" &&
                      mapInfo.productType === "Audiology" && (
                        <MapDetails
                          key={index}
                          deleteMapHandler={deleteMapHandler}
                          mapName={mapInfo.name}
                          mapMarket={marketTitleLower}
                          mapTime=""
                          mapId={mapInfo.id}
                          mapOwner={mapInfo.createdBy}
                          userGenerated={true}
                        />
                      )}
                  </>
                ))}
              </MapListBlock>
            </>
          )}

          <MapDetailsRow></MapDetailsRow>
          <h2>Public Maps</h2>
          <MapListBlock>
            {mapList.map((mapInfo, index) => (
              <>
                {mapInfo.mapType === "Public" &&
                  !(mapInfo.createdBy === userId) && (
                    <MapDetails
                      key={index}
                      deleteMapHandler={deleteMapHandler}
                      mapName={mapInfo.name}
                      mapMarket={marketTitleLower}
                      mapTime=""
                      mapId={mapInfo.id}
                      mapOwner={mapInfo.createdBy}
                      userGenerated={true}
                    />
                  )}
              </>
            ))}
          </MapListBlock>
          {hasPrivateMaps && (
            <>
              <h2>My Maps</h2>
              <MapListBlock>
                {noMapsHandler() && (
                  <NoMapsMessage>
                    You currently don't have any maps. Duplicate an Original or
                    Public map and make it your own!
                  </NoMapsMessage>
                )}

                {mapList.map((mapInfo, index) => (
                  <>
                    {mapInfo.createdBy === userId && (
                      <MapDetails
                        key={index}
                        deleteMapHandler={deleteMapHandler}
                        mapName={mapInfo.name}
                        mapMarket={marketTitleLower}
                        mapTime=""
                        mapId={mapInfo.id}
                        mapOwner={mapInfo.createdBy}
                        userGenerated={true}
                      />
                    )}
                  </>
                ))}
              </MapListBlock>
            </>
          )}
        </>
      ) : (
        <LoadingDataContainer>
          <DotLoader color={SPECSAVERS_GREEN} />
          <LoadingText>Loading Market Data...</LoadingText>
        </LoadingDataContainer>
      )}
    </Container>
  );
};

export default MarketLanding;
