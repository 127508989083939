import styled from "styled-components";
import { useRef, useState } from "react";
import axiosConfig from "services/api/axiosConfiig";
import { FaHome } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import PrimaryButton from "components/shared/Buttons/PrimaryButton";

const Container = styled.div`
  margin-left: 10vw;
  width: 80vw;
  height: auto;
  margin-top: 84px;
  margin-bottom: 40px;
`;

const Header = styled.div`
  width: 100%;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #d1d7dc;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 1.4rem;
  margin-bottom: 10px;
`;

const BadgeContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #d1d7dc;
`;

const Badge = styled.div`
  width: 125px;
  height: 125px;
  border-radius: 100px;
  background-color: #009b4f;
  font-size: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  margin-bottom: 20px;
`;

const EmailContainer = styled.div`
  width: 70%;
  margin-left: 15%;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 23px;
  padding-bottom: 13px;
  border-bottom: 1px solid #d1d7dc;
`;

const PasswordContainer = styled.div`
  width: 70%;
  margin-left: 15%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 28px;
  padding-bottom: 30px;
  border-bottom: 1px solid #d1d7dc;
  margin-bottom: 40px;
`;

const InputBox = styled.input`
  height: 40px;
  width: 64%;
  padding: 5px 5px 5px 15px;
  margin-top: 6px;
  margin-bottom: 12px;
  font-size: 0.9rem;
  font-weight: 400;
  color: #000;
`;

const Label = styled.label`
  width: 66%;
`;

const PasswordUpdatedMessage = styled.p`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
`;

const HomeIcon = styled(FaHome)`
  margin-top: 12px;
  font-size: 1.6rem;
  color: #009b4f;
  cursor: pointer;

  &:hover {
    color: #00693b;
  }
`;

const WarningBox = styled.div`
  width: 270px;
  border: 1px #767676 solid;
  margin-bottom: 12px;
  background-color: #ff8282;
  padding: 20px;
  font-size: 0.9rem;
  text-align: center;
  line-height: 18px;
`;

const Index = () => {
  const history = useHistory();

  const currentPasswordRef = useRef();
  const newPasswordRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [warningMessage, setWarningMessage] = useState();
  const [passwordUpdated, setPasswordUpdated] = useState(false);
  const accessToken = localStorage.getItem("token");
  const userId = localStorage.getItem("userId");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const getUserData = async () => {
    const config = {
      method: "get",
      headers: {
        Authorization: accessToken,
      },
    };

    await axiosConfig(`/users/${userId}`, config)
      .then(function (response) {
        setFirstName(response.data.firstName);
        setLastName(response.data.lastName);
        setEmail(response.data.email);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  getUserData();

  const initialsHandler = (firstName, lastName) => {
    const firstInitial = firstName.charAt(0).toUpperCase();
    const secondInitial = lastName.charAt(0).toUpperCase();
    return firstInitial + secondInitial;
  };

  const updatePasswordHandler = async (event) => {
    event.preventDefault();
    const currentPassword = currentPasswordRef.current.value;
    const newPassword = newPasswordRef.current.value;

    try {
      setIsLoading(true);
      const { data } = await axiosConfig.patch(
        `/users/${userId}/password`,
        {
          oldPassword: currentPassword,
          newPassword: newPassword,
        },
        {
          headers: {
            Authorization: accessToken,
          },
        }
      );
      // Success
      setPasswordUpdated(true);
    } catch (err) {
      const errorMessage = err.response.data.message;
      if (errorMessage === "InvalidPassword") {
        setWarningMessage("Incorrect password. Please try again.");
        setShowWarning(true);
        setIsLoading(false);
      } else if (errorMessage === "WeakPassword") {
        setWarningMessage("Your new password is too weak. Please try again.");
        setShowWarning(true);
        setIsLoading(false);
      } else {
        setWarningMessage("An unknown error occurred. Please try again.");
        setShowWarning(true);
        setIsLoading(false);
      }
    }
  };

  const goHomeHandler = () => {
    history.replace("/");
  };

  return (
    <Container>
      <Header>
        <Title>Account Settings</Title>Edit your account settings and change
        your password here.
      </Header>
      <BadgeContainer>
        <Badge>{initialsHandler(firstName, lastName)}</Badge>
        {firstName} {lastName}
      </BadgeContainer>
      <EmailContainer>
        <Label>Email:</Label>
        <InputBox value={email} disabled></InputBox>
      </EmailContainer>
      <PasswordContainer>
        {showWarning && <WarningBox>{warningMessage}</WarningBox>}
        <Label>Password:</Label>
        <InputBox
          type="password"
          placeholder="Enter Current Password"
          ref={currentPasswordRef}
          onChange={() => {
            setShowWarning(false);
          }}
        ></InputBox>
        <InputBox
          type="password"
          placeholder="Enter New Password"
          ref={newPasswordRef}
          onChange={() => {
            setShowWarning(false);
          }}
        ></InputBox>

        {passwordUpdated ? (
          <PasswordUpdatedMessage>
            Password Updated
            <HomeIcon onClick={goHomeHandler} />
          </PasswordUpdatedMessage>
        ) : (
          <PrimaryButton
            width="314px"
            fontSize="1rem"
            onClick={updatePasswordHandler}
          >
            {isLoading ? <p>Updating Password...</p> : <p>Update Password</p>}
          </PrimaryButton>
        )}
      </PasswordContainer>
    </Container>
  );
};

export default Index;
