import { useRef, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import axiosConfig from "services/api/axiosConfiig";

const Container = styled.div`
  margin-left: 10vw;
  width: 80vw;
  height: auto;
  margin-top: 84px;
  margin-bottom: 40px;
`;

const Header = styled.div`
  width: 100%;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #d1d7dc;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 1.4rem;
  margin-bottom: 10px;
`;

const InviteContainer = styled.div`
  width: 70%;
  margin-left: 15%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 28px;
  padding-bottom: 30px;
  border-bottom: 1px solid #d1d7dc;
  margin-bottom: 40px;
`;

const InputBox = styled.input`
  height: 40px;
  width: 64%;
  padding: 5px 5px 5px 15px;
  margin-top: 6px;
  margin-bottom: 16px;
  font-size: 0.9rem;
  font-weight: 400;
  color: #000;
`;

const Label = styled.label`
  width: 66%;
`;

const PrimaryButton = styled.button`
  width: 314px;
  padding: 5px;
  background-color: #009b4f;
  height: 50px;
  border: none;
  border-radius: 3px;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 15px;
  &:hover {
    background-color: #00693b;
  }
`;

const UserInvitedMessage = styled.p`
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  line-height: 18px;
`;

const WarningBox = styled.div`
  width: 270px;
  border: 1px #767676 solid;
  margin-bottom: 12px;
  background-color: #ff8282;
  padding: 20px;
  font-size: 0.9rem;
  text-align: center;
  line-height: 18px;
`;

const SelectBox = styled.select`
  padding: 10px;
  height: 55px;
  margin-top: 6px;
  margin-bottom: 22px;
  width: 66%;
  font-size: 0.9rem;
`;

const Index = () => {
  const marketRef = useRef();
  const accountTypeRef = useRef();
  const emailAddressRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [userInvited, setUserInvited] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [warningMessage, setWarningMessage] = useState();
  const accessToken = localStorage.getItem("token");
  const inviteUserHandler = async (event) => {
    event.preventDefault();
    const currentMarket = marketRef.current.value;
    const currentAccountType = accountTypeRef.current.value;
    const currentEmail = emailAddressRef.current.value;

    try {
      setIsLoading(true);
      const { data } = await axiosConfig.post(
        `/invitations`,
        {
          marketType: currentMarket,
          accountType: currentAccountType,
          email: currentEmail,
        },
        {
          headers: {
            Authorization: accessToken,
          },
        }
      );
      // Success
      setIsLoading(false);
      setShowWarning(false);
      setUserInvited(true);
    } catch (err) {
      const errorMessage = err.response.data.message;
      if (errorMessage === "InvitationExists") {
        setWarningMessage(
          "An invitation has already been sent to this email address."
        );
        setShowWarning(true);
        setIsLoading(false);
      } else if (errorMessage === "UserExists") {
        setWarningMessage("A user with this email address already exists.");
        setShowWarning(true);
        setIsLoading(false);
      } else {
        setWarningMessage("An unknown error occurred. Please try again.");
        setShowWarning(true);
        setIsLoading(false);
      }
    }
  };

  return (
    <Container>
      <Header>
        <Title>Invite a user</Title>Send an invite email to a new user so they
        can create an account.
      </Header>
      <InviteContainer>
        {showWarning && <WarningBox>{warningMessage}</WarningBox>}
        {!userInvited && (
          <>
            <Label>Market:</Label>
            <SelectBox ref={marketRef}>
              <option selected value="Norway">
                Norway
              </option>
              <option value="Sweden">Sweden</option>
              <option value="Finland">Finland</option>
              <option value="Denmark">Denmark</option>
              <option value="Netherlands">Netherlands</option>
              {/* <option value="HQ">HQ</option> */}
            </SelectBox>
            <Label>Account Type:</Label>
            <SelectBox ref={accountTypeRef}>
              <option selected value="User">
                User
              </option>
              <option value="Editor">Editor</option>
              <option value="Admin">Admin</option>
            </SelectBox>
            <Label>Email:</Label>
            <InputBox
              placeholder="Enter Email Address"
              ref={emailAddressRef}
              onChange={() => {
                setShowWarning(false);
              }}
            ></InputBox>
          </>
        )}
        {userInvited ? (
          <UserInvitedMessage>
            An email has been sent to the email address provided. The user
            will now be able to finish up setting up their account by clicking
            the link in the email.
            <Link to="/admin/adminpanel">
              {" "}
              <PrimaryButton>Admin Panel</PrimaryButton>
            </Link>
          </UserInvitedMessage>
        ) : (
          <PrimaryButton onClick={inviteUserHandler}>
            {isLoading ? <p>Inviting User...</p> : <p>Invite user</p>}
          </PrimaryButton>
        )}
      </InviteContainer>
    </Container>
  );
};

export default Index;
