import styled from "styled-components";

const NavTopBlock = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  min-height: 47px;
  width: 100vw;
`;

const NavTop = ({ children }) => {
  return <NavTopBlock>{children}</NavTopBlock>;
};

export default NavTop;
