import styled from "styled-components";
import { string } from "prop-types";

const StyledFileArea = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 80%;
  white-space: initial;
  word-wrap: break-word;
  padding-top: 20px;
  padding-bottom: 30px;
  height: auto;
  display: inline-flexbox;
  flex-flow: wrap;
  background-color: #eee;
  margin-bottom: 45px;
`;

const Title = styled.div`
  font-size: 20px;
  margin-bottom: 15px;
`;
const FileArea = ({ name, children }) => {
  return (
    <>
      <Title>{name}</Title>
      <StyledFileArea>{children}</StyledFileArea>
    </>
  );
};

FileArea.propTypes = {
  name: string.isRequired,
};

export default FileArea;
