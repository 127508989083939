import styled from "styled-components";
import { useSelector } from "react-redux";
import Menu from "./index";
import { FiCheck } from "react-icons/fi";
import { useParams, Link } from "react-router-dom";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;

  a:link {
    color: white;
    text-decoration: none;
  }

  a:visited {
    color: white;
    text-decoration: none;
  }

  a:active {
    color: white;
    text-decoration: none;
  }
`;
const CustomerType = styled.p`
  cursor: pointer;
  &:hover {
    cursor: pointer;
    filter: drop-shadow(0px 0px 8px #fff);
  }
`;

const TickIcon = styled(FiCheck)`
  margin-left: 45px;
  font-size: 1.1rem;
  position: relative;
  top: 3px;
`;

const Customers = () => {
  const data = useSelector((state) => state.dataReducer.data);
  const customerArr = data.header[0].customer_maps;

  let { marketTitle } = useParams();
  let marketTitleLower = marketTitle.toLowerCase();

  const changeMapHandler = (mapId) => {
    // Load new map here, as 'Link' doesn't redirect because only param/prop changes
    // Short cut...
    // Could do this another way by updating the id in redux and running the axios call again
    window.location.href = `/map/${marketTitleLower}/${mapId}`;
  };

  return (
    <Menu width="220px">
      <Container>
        {customerArr.map((productData, index) => {
          return (
            <Link
              to={`/map/${marketTitleLower}/${customerArr[index].map_id}`}
              onClick={() => {
                changeMapHandler(customerArr[index].map_id);
              }}
            >
              <CustomerType>
                {customerArr[index].customer_type}
                {customerArr[index].selected && <TickIcon />}
              </CustomerType>
            </Link>
          );
        })}
      </Container>
    </Menu>
  );
};

export default Customers;
