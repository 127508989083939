import { useState, useRef } from "react";
import styled from "styled-components";
import { WHITE, SPECSAVERS_GREEN } from "constants/index";
import { dataActions } from "redux/store/data-slice";
import { mapSettingsActions } from "redux/store/map-settings-slice";
import { useSelector, useDispatch } from "react-redux";
import PrimaryButton from "components/shared/Buttons/PrimaryButton";
import { FaRegSave } from "react-icons/fa";
import TPIcon00 from "assets/images/touchpoint_icons/icon_tp_00.png";
import BookingIllustration from "assets/images/stage_icons/ill_booking.png";
import CheckInIllustration from "assets/images/stage_icons/ill_check_in.png";
import PreTestIllustration from "assets/images/stage_icons/ill_pre_test.png";
import SightTestIllustration from "assets/images/stage_icons/ill_exam.png";
import ThreeWayIllustration from "assets/images/stage_icons/ill_three_way.png";
import ProductSelectioIllustration from "assets/images/stage_icons/ill_product_selection.png";
import PurchaseIllustration from "assets/images/stage_icons/ill_purchase.png";
import CollectIllustration from "assets/images/stage_icons/ill_collection.png";

const Container = styled.div`
  position: relative;
  top: -35px;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
`;

const FormContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const FormHeading = styled.h1`
  font-size: 1.5rem;
  margin-left: 27px;
`;

const Input = styled.input`
  padding: 10px;
  color: #000000;
  background: #ffffff;
  border: 0.5px solid #000000;
  border-radius: 3px;
  width: 300px;
  height: 40px;
  margin-bottom: 20px;
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 10px;
  font-size: 1.4em;
`;

const IconSelectContainer = styled.div`
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
  width: 500px;
  padding-bottom: 5px;
  margin-top: 15px;
  margin-bottom: 15px;
`;

const IconBox = styled.div`
  border: 2px solid ${WHITE};
  border-radius: 4px;
  margin-right: 2px;

  &:hover {
    border: 2px solid ${SPECSAVERS_GREEN};
    cursor: pointer;
  }
`;

const CurrentIcon = styled.div`
  border: 2px solid ${SPECSAVERS_GREEN};
  border-radius: 4px;
  margin-right: 2px;
`;

const StyledLabel = styled.label`
  position: relative;
  width: 320px;
  text-align: left;
`;

const Form = () => {
  const primaryTextAreaRef = useRef();
  const secondaryTextAreaRef = useRef();
  const dispatch = useDispatch();
  const id = useSelector((state) => state.dataReducer.currentId);
  const data = useSelector((state) => state.dataReducer.data);
  const currentIcon = data?.row[1].column[id].illustration;
  const primaryTextValue = data?.row[1].column[id].primary_text
  const secondaryTextValue = data?.row[1].column[id].secondary_text

  const [selectedIcon, setSelectedIcon] = useState(currentIcon);

  const iconChangeHandler = (i) => {
    setSelectedIcon(i);
  };

  const IllustrationSwitch = (illustration) => {
    switch (illustration) {
      case 0:
        return <img src={TPIcon00} alt="Illustration" height="150" />;
      case 1:
        return (
          <img src={BookingIllustration} alt="Illustration" height="150" />
        );
      case 2:
        return (
          <img src={CheckInIllustration} alt="Illustration" height="150" />
        );
      case 3:
        return (
          <img src={PreTestIllustration} alt="Illustration" height="150" />
        );
      case 4:
        return (
          <img src={SightTestIllustration} alt="Illustration" height="150" />
        );
      case 5:
        return (
          <img src={ThreeWayIllustration} alt="Illustration" height="150" />
        );
      case 6:
        return (
          <img
            src={ProductSelectioIllustration}
            alt="Illustration"
            height="150"
          />
        );
      case 7:
        return (
          <img src={PurchaseIllustration} alt="Illustration" height="150" />
        );
      case 8:
        return (
          <img src={CollectIllustration} alt="Illustration" height="150" />
        );
      default:
        return (
          <img src={BookingIllustration} alt="Illustration" height="150" />
        );
    }
  };

  const saveHandler = () => {
    let newPrimaryText = primaryTextAreaRef.current.value;
    let newSecondaryText = secondaryTextAreaRef.current.value;

    if (newPrimaryText === "") {
      newPrimaryText = newSecondaryText;
    }

    if (newSecondaryText === "") {
      newSecondaryText = newPrimaryText;
    }

    dispatch(
      dataActions.updateJourneyStage({
        id,
        updatePrimaryText: newPrimaryText,
        updateSecondaryText: newSecondaryText,
        updateIllustration: selectedIcon,
      })
    );
    dispatch(mapSettingsActions.popupToggle());
  };

  const [primaryTextInput, setPrimaryTextInput] = useState(
    primaryTextValue
  );
  const [secondaryTextInput, setSecondaryTextInput] = useState(
    secondaryTextValue
  );

  return (
    <Container>
      <FormHeading>Journey Stage</FormHeading>
      <FormContainer>
        <StyledLabel>English:</StyledLabel>
        <Input
          id="text"
          ref={primaryTextAreaRef}
          value={primaryTextInput}
          autocomplete="off"
          onChange={(t) => setPrimaryTextInput(t.target.value)}
        />
        <StyledLabel>Market Language:</StyledLabel>
        <Input
          id="text"
          ref={secondaryTextAreaRef}
          value={secondaryTextInput}
          autocomplete="off"
          onChange={(t) => setSecondaryTextInput(t.target.value)}
        />
        <IconSelectContainer>
          {[...Array(9)].map((x, i) => (
            <>
              {i === selectedIcon ? (
                <CurrentIcon>{IllustrationSwitch(i)}</CurrentIcon>
              ) : (
                <IconBox
                  onClick={() => {
                    iconChangeHandler(i);
                  }}
                >
                  {" "}
                  {IllustrationSwitch(i)}{" "}
                </IconBox>
              )}
            </>
          ))}
        </IconSelectContainer>
      </FormContainer>
      <PrimaryButton width="160px" fontSize="1.2rem" onClick={saveHandler}>
        Save <FaRegSave id="icon" style={{ verticalAlign: "middle" }} />
      </PrimaryButton>
    </Container>
  );
};

export default Form;
