import { createSlice } from "@reduxjs/toolkit";
import RowVisibility from "./rowvisibility.json";

const initialMapSettingsState = {
  rowVisibilityData: JSON.parse(localStorage.getItem("rowdata"))
    ? JSON.parse(localStorage.getItem("rowdata"))
    : RowVisibility,
  mapZoom: 90,
  lockMap: false,
  isLoading: false,
  showPopup: false,
  formName: "needs",
  showMap: true,
  primaryMapLanguage: true,
  activeToolbarMenu: "Market",
  showSatisafactionXScale: false,
  downloadPdf: false,
  isBlockUpdated: "",
  currentMapId: "",
  mapName: "",
  mapMarket: "",
  mapType: "",
  mapProductType: "",
  mapCustomerType: "",
  mapOwner: "",
};

const mapSettingsSlice = createSlice({
  name: "mapsettings",
  initialState: initialMapSettingsState,
  reducers: {
    zoomInHandler(state) {
      state.mapZoom = state.mapZoom + 10;
    },
    zoomOutHandler(state) {
      state.mapZoom = state.mapZoom - 10;
    },
    zoomResetHandler(state) {
      state.mapZoom = 90;
    },
    lockToggle(state) {
      state.lockMap = !state.lockMap;
    },
    lockMap(state) {
      state.lockMap = true;
    },
    popupToggle(state) {
      state.showPopup = !state.showPopup;
    },
    popupForm(state, action) {
      state.formName = action.payload;
    },
    setIsBlockUpdated(state, action) {
      state.isBlockUpdated = action.payload;
    },
    setMapId(state, action) {
      state.currentMapId = action.payload;
    },
    setMapName(state, action) {
      state.mapName = action.payload;
    },
    setMapOwner(state, action) {
      state.mapOwner = action.payload;
    },
    setMapMarket(state, action) {
      state.mapMarket = action.payload;
    },
    setMapType(state, action) {
      state.mapType = action.payload;
    },
    setMapProductType(state, action) {
      state.mapProductType = action.payload;
    },
    setMapCustomerType(state, action) {
      state.mapCustomerType = action.payload;
    },
    toggleLoading(state) {
      state.isLoading = !state.isLoading;
    },
    toggleMapGantt(state, action) {
      state.showMap = !state.showMap;
    },
    toggleMapLanguage(state) {
      state.primaryMapLanguage = !state.primaryMapLanguage;
    },
    toggleRowVisibility(state, action) {
      const id = action.payload;
      state.rowVisibilityData.row[id].visible =
        !state.rowVisibilityData.row[id].visible;
      localStorage.setItem("rowdata", JSON.stringify(state.rowVisibilityData));
    },
    toggleSatisfactionXScale(state) {
      state.showSatisafactionXScale = !state.showSatisafactionXScale;
    },
    toggleDownloadPdf(state) {
      state.downloadPdf = !state.downloadPdf;
    },
  },
});

export const mapSettingsActions = mapSettingsSlice.actions;

export default mapSettingsSlice.reducer;
