import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import RowBackground from "components/DynamicMappingTool/common/RowBackground";
import RowContainer from "components/DynamicMappingTool/common/RowContainer";
import Row from "components/DynamicMappingTool/common/Row";
import Bubble from "components/DynamicMappingTool/common/Shapes/Bubble";

const CustomerNeeds = () => {
  const primaryMapLanguage = useSelector(
    (state) => state.mapSettingsReducer.primaryMapLanguage
  );
  const data = useSelector((state) => state.dataReducer.data);
  const needsData = data?.row[2].column;
  const [events, updateEvents] = useState(needsData);

  useEffect(() => {
    updateEvents(needsData);
  }, [needsData]);

  const widthHandler = (index) => {
    let columns = data.row[1].column[index].columns;

    let widthResult = columns * 424;
    widthResult = widthResult + "px";
    return widthResult;
  };

  return (
    <RowContainer height="132px">
      <RowBackground rowName="Customer Expectations" />
      <Row display="flex" top="-80px">
        {events.map((columnData, i) => (
          <Bubble
            key={columnData.id}
            id={i}
            sectionId={columnData.section_id}
            text={
              primaryMapLanguage
                ? columnData.primary_text
                : columnData.secondary_text
            }
            columnWidth={widthHandler(i)}
            height="75px"
          ></Bubble>
        ))}
      </Row>
    </RowContainer>
  );
};

export default CustomerNeeds;
