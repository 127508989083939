import styled from "styled-components";
import { string } from "prop-types";
import { Link } from "react-router-dom";

const MarketName = styled.h1`
  font-size: 1.5rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  position: relative;
  top: -225px;
  width: 225px;
  left: 13px;
  justify-content: center;
  color: #016c42;

  &:hover {
    color: #3baa35;
    transition: 0.5s;
  }
  @media (max-width: 1550px) {
    font-size: 1.2rem;
    top: -182px;
    left: -6px;
    height: auto;
  }
`;

const DisabledMarketName = styled.h1`
  font-size: 1.5rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  position: relative;
  top: -225px;
  width: 225px;
  left: 13px;
  justify-content: center;
  color: #bbb;

  @media (max-width: 1550px) {
    font-size: 1.2rem;
    top: -182px;
    left: -6px;
    height: auto;
  }
`;

const PinImage = styled.img`
  width: 250px;
  height: auto;

  &:hover {
    cursor: pointer;
  }

  &:hover ${MarketName} {
    color: #3baa35;
    transition: 0.5s;
  }

  @media (max-width: 1550px) {
    width: 200px;
    height: auto;
  }
`;

const DisabledPinImage = styled.img`
  width: 250px;
  height: auto;
  filter: saturate(0%);

  @media (max-width: 1550px) {
    width: 200px;
    height: auto;
  }
`;

const PinContainer = styled.div`
  text-align: left;
  width: 85px;
  padding-bottom: -50px;
  position: relative;
  left: -20px;
  top: -20px;
  height: 80px;
  margin-right: 90px;
  margin-left: 15px;

  &:hover ${MarketName} {
    color: #3baa35;
    transition: 0.5s;
  }

  @media (max-width: 1550px) {
    margin-bottom: 125px;
  }

  @media (max-width: 686px) {
    top: -150px;
  }
`;

const MarketButton = ({ market, marketPin, link, disabled }) => {
  return (
    <>
    {disabled ?
      <PinContainer>
          <DisabledPinImage src={marketPin} alt={market} />
        <DisabledMarketName>{market}&nbsp;</DisabledMarketName>
      </PinContainer> : 
    
    <Link to={`marketlanding/${link}`}>
    <PinContainer>
      <Link to={`marketlanding/${link}`}>
        <PinImage src={marketPin} alt={market} />
                </Link>
      <MarketName>{market}&nbsp;</MarketName>
    </PinContainer>
  </Link>
    
    } 
    </>
  );
};

MarketButton.propTypes = {
  market: string.isRequired,
  marketPin: string.isRequired,
  link: string.isRequired,
};

export default MarketButton;
