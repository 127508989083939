import styled from "styled-components";
import Menu from "./index";
import { mapSettingsActions } from "redux/store/map-settings-slice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;
const DownloadType = styled.p`
  cursor: pointer;
  color: white;

  &:hover {
    cursor: pointer;
    filter: drop-shadow(0px 0px 8px #fff);
  }
`;

const Download = () => {
  const dispatch = useDispatch();

  const data = useSelector((state) => state.dataReducer.data);

  const csvFile = data.header[0].csv_file;

  const downloadPdfTrigger = () => {
    dispatch(mapSettingsActions.lockMap());
    dispatch(mapSettingsActions.toggleDownloadPdf());
  };

  return (
    <Menu>
      <Container>
        <DownloadType onClick={() => downloadPdfTrigger()}>
          PDF File
        </DownloadType>
        <a style={{ textDecoration: "none" }} href={csvFile} download>
          <DownloadType>CSV File</DownloadType>
        </a>
      </Container>
    </Menu>
  );
};

export default Download;
