import { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { dataActions } from "redux/store/data-slice";
import { mapSettingsActions } from "redux/store/map-settings-slice";
import { useSelector, useDispatch } from "react-redux";
import { FaRegTrashAlt } from "react-icons/fa";
import { ImWarning } from "react-icons/im";

const Container = styled.div`
  position: relative;
  top: -35px;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
`;

const FormContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const FormHeading = styled.h1`
  font-size: 1.5rem;
  margin-left: 27px;
`;

const SelectBox = styled.select`
  margin-left: 20px;
  padding: 10px;
  font-size: 1rem;
  margin-top: 15px;
`;

const StyledP = styled.p`
  margin-top: 9px;
`;

const SecondaryButton = styled.button`
  width: 160px;
  padding: 10px;
  background-color: #e30613;
  height: 60px;
  border: none;
  border-radius: 3px;
  margin-top: 15px;
  margin-left: 3px;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  font-size: 1.2em;

  &:hover {
    background-color: #00693b;
  }
`;

const StyledLabel = styled.label`
  margin-top: 30px;
`;

const Warning = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  color: #c70512;
  background-color: #fc9ca2;
  border: 2px solid #f91f2e;
  text-align: center;
  padding: 16px;
  height: 70px;
  font-size: 1rem;
  width: 550px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
`;

const WarningText = styled.div`
  width: 70%;
  position: relative;
  left: 141px;
  top: -52px;
  line-height: 20px;
`;

const WarningIcon = styled(ImWarning)`
  font-size: 2.5rem;
  color: #c70512;
  margin-top: 5px;
`;

const WarningIconContainer = styled.div`
  border-right: 1px #c70512 solid;
  width: 80px;
  height: inherit;
  padding-right: 15px;
`;

const Form = () => {
  const columnRef = useRef();

  const [columnChoice, setColumnChoice] = useState();

  useEffect(() => {
    setColumnChoice(columnRef.current.value);
  }, []);

  const dispatch = useDispatch(0);
  const data = useSelector((state) => state.dataReducer.data);
  const primaryMapLanguage = useSelector(
    (state) => state.mapSettingsReducer.primaryMapLanguage
  );
  const deleteHandler = () => {
    // Work out stage index
    let calcStages = 0;
    for (let i = 0; i < columnChoice; ++i) {
      calcStages = calcStages + data.row[0].column[i].stages;
    }
    // Work out sub stage index
    let calcSubStages = 0;
    for (let i = 0; i < columnChoice; ++i) {
      calcSubStages = calcSubStages + data.row[0].column[i].columns;
    }

    const selectedColumn = parseInt(columnChoice);
    (async () => {
      dispatch(
        dataActions.deleteColumn({
          removeColumnIndex: selectedColumn,
          removeStageIndex: calcStages,
          removeSubStageIndex: calcSubStages,
        })
      );
      dispatch(mapSettingsActions.toggleLoading());
    })().then(() => {
      dispatch(mapSettingsActions.popupToggle());
      dispatch(mapSettingsActions.toggleLoading());
    });
  };

  const handleColumnChange = (event) => {
    setColumnChoice(event.target.value);
  };

  return (
    <Container>
      <FormHeading>Delete a column</FormHeading>
      <FormContainer>
        <Warning>
          <WarningIconContainer>
            <WarningIcon />
          </WarningIconContainer>
          <WarningText>
            You can only delete a column that has 1 stage within it. If the
            column you want to delete isn't listed here, remove the stages and
            sub-stages first.
          </WarningText>
        </Warning>
        <StyledP>Column:</StyledP>
        <SelectBox ref={columnRef} onChange={handleColumnChange}>
          {data.row[0].column.map((columnData, index) => (
            <>
              {columnData.stages === 1 && columnData.columns === 1 && (
                <option value={index}>
                  {" "}
                  {primaryMapLanguage
                    ? columnData.primary_text
                    : columnData.secondary_text}
                </option>
              )}
            </>
          ))}
        </SelectBox>
      </FormContainer>
      <SecondaryButton width="160px" fontSize="1.2rem" onClick={deleteHandler}>
        Delete
        <FaRegTrashAlt
          size="1.2rem"
          style={{ verticalAlign: "top", marginLeft: "10px" }}
        />
      </SecondaryButton>
    </Container>
  );
};

export default Form;
