import { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { CgMenu } from "react-icons/cg";
import { BLACK, NAV_GREY, SPECSAVERS_GREEN } from "constants/index";
import { IoIosClose } from "react-icons/io";

const NavBar = styled.div`
  width: 100%;
  height: 60px;
  border-bottom: 1px ${NAV_GREY} solid;
  border-top: 1px ${NAV_GREY} solid;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 600;
  position: sticky;

  a {
    text-decoration: none;
    color: ${BLACK};
    margin-left: 25px;

    &:hover {
      border-bottom: #009b4f 5px solid;
      padding-bottom: 14px;
      margin-top: 20px;
    }
  }
`;

const LinkContainer = styled.div`
  @media (max-width: 900px) {
    display: none;
  }
`;

const IconContainer = styled.div`
  display: none;
  cursor: pointer;
  position: relative;
  left: 9px;
  @media (max-width: 900px) {
    display: flex;
  }
`;

const CloseIcon = styled(IoIosClose)`
  font-size: 30px;
  color: ${BLACK};
  position: absolute;
  top: -13px;
  left: 48vw;
`;

const MobileMenu = styled.div`
  display: none;
  justify-content: space-between;
  align-items: center;
  position: relative;
  top: 173px;
  padding-bottom: 20px;
  border-bottom: 1px ${NAV_GREY} solid;

  a {
    text-decoration: none;
    color: ${BLACK};
    margin-top: 25px;

    &:hover {
      color: ${SPECSAVERS_GREEN};
      border-bottom: 0px;
      padding-bottom: 0px;
      margin-top: 25px;
    }
  }

  @media (max-width: 900px) {
    display: flex;
    overflow: hidden;
    flex-direction: column;
    max-height: 300px;
    transition: max-height 0.3s ease-in;
    width: 100%;
    background-color: WHITE;
  }
`;

const DisabledLink = styled.a`
  color: #bbb !important;

  &:hover {
    border-bottom: none !important;
    padding-bottom: 14px;
    margin-top: 20px;
  }
`;

const NavLinks = () => {
  const isLoggedIn = useSelector((state) => state.authReducer.isLoggedIn);
  const accountType = useSelector((state) => state.authReducer.accountType);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <NavBar>
      <LinkContainer>
        {isLoggedIn && (
          <>
            <Link to="/marketlanding/norway">Norway</Link>
            <Link to="/marketlanding/sweden">Sweden</Link>
            <Link to="/marketlanding/finland">Finland</Link>
            <Link to="/marketlanding/denmark">Denmark</Link>
            <Link to="/marketlanding/netherlands">Netherlands</Link>
            <Link to="/marketlanding/allmarkets">All Markets</Link>
            {(accountType === "Admin" || accountType === "SuperAdmin") && (
              <Link to="/admin/adminpanel">Admin Panel</Link>
            )}
          </>
        )}
      </LinkContainer>
      {isLoggedIn ? (
        <IconContainer>
          {isMenuOpen ? (
            <CloseIcon onClick={() => setIsMenuOpen(!isMenuOpen)} />
          ) : (
            <CgMenu onClick={() => setIsMenuOpen(!isMenuOpen)} />
          )}
        </IconContainer>
      ) : (
        <Link to="/login">Log In</Link>
      )}
      {isMenuOpen ? (
        <MobileMenu>
          {isLoggedIn && (
            <>
              <Link to="/marketlanding/norway">Norway</Link>
              <Link to="/marketlanding/sweden">Sweden</Link>
              <Link to="/marketlanding/finland">Finland</Link>
              <Link to="/marketlanding/denmark">Denmark</Link>
              <Link to="/marketlanding/netherlands">Netherlands</Link>
            <Link to="/marketlanding/allmarkets">All Markets</Link>
              {(accountType === "Admin" || accountType === "SuperAdmin") && (
                <Link to="/admin/adminpanel">Admin Panel</Link>
              )}
            </>
          )}
        </MobileMenu>
      ) : (
        ""
      )}
    </NavBar>
  );
};

export default NavLinks;
