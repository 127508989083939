import styled from "styled-components";
import { WHITE } from "constants/index";
import { useSelector, useDispatch } from "react-redux";
import { mapSettingsActions } from "redux/store/map-settings-slice";
import { dataActions } from "redux/store/data-slice";

const StyledBlock = styled.div`
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${WHITE};
  text-align: center;
  padding-top: 16px;
  padding-bottom: 16px;
  text-decoration: none;
  background: ${(props) => props.backgroundColour};
  border-radius: 5px;
  margin-right: 5px;
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  min-width: ${(props) => props.minWidth};
  cursor: default;
  font-size: 1rem;
`;

const Block = ({
  index,
  row,
  text,
  backgroundColour,
  width,
  height,
  minWidth,
  columnWidth,
  children,
}) => {
  const dispatch = useDispatch();

  const lockMap = useSelector((state) => state.mapSettingsReducer.lockMap);

  const blockDataHandler = (index) => {
    if (row === "heading") {
      dispatch(mapSettingsActions.popupForm("heading"));
      dispatch(dataActions.updateCurrentId(index));
    } else if (row === "stage") {
      dispatch(mapSettingsActions.popupForm("stage"));
      dispatch(dataActions.updateCurrentId(index));
    }

    dispatch(mapSettingsActions.popupToggle());
  };

  return (
    <StyledBlock
      width={columnWidth}
      height={height}
      minWidth={columnWidth}
      backgroundColour={backgroundColour}
      {...(!lockMap && { onClick: () => blockDataHandler(index) })}
    >
      <h3>{text}</h3>
      {children}
    </StyledBlock>
  );
};

export default Block;
