import { useState } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { mapSettingsActions } from "redux/store/map-settings-slice";
import axiosConfig from "services/api/axiosConfiig";
import { FaHome, FaUsers } from "react-icons/fa";
import { RiSave3Fill } from "react-icons/ri";
import { HiRefresh, HiDuplicate } from "react-icons/hi";
import { FiZoomIn, FiZoomOut } from "react-icons/fi";
import { ImUndo2 } from "react-icons/im";
import { WHITE, MEDIUM_GREY } from "constants/index";
import { IoLockClosed, IoLockOpen } from "react-icons/io5";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

const Container = styled.div`
  width: 98%;
  color: ${WHITE};
  font-size: 1.5rem;
  margin-top: 12px;
  margin-left: -12px;
  display: flex;
  flex-direction: row;
`;

const LeftSection = styled.div`
  display: flex;
  flex-direction: row;
`;

const RightSection = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
`;

const TopBarButton = styled.div`
  width: 35px;
  &:hover {
    cursor: pointer;
    filter: drop-shadow(0px 0px 8px ${WHITE});
  }
  &:active {
    cursor: pointer;
    filter: drop-shadow(0px 0px 8px ${WHITE});
  }
`;

const DisabledTopBarButton = styled.div`
  width: 35px;
  opacity: 0.4;
`;

const Divider = styled.div`
  border-left: 1px ${MEDIUM_GREY} solid;
  width: 10px;
  height: 20px;
  margin-top: 3px;
`;

const ResetZoom = styled(ImUndo2)`
  position: relative;
  font-size: 0.6rem;
  left: -17.9px;
  top: -7.4px;
  border-radius: 200px;
  font-weight: 700;
`;

const ButtonText = styled.p`
  width: 200px;
  font-size:1.1rem;
  margin-top: 4px;
`;

const StyledLink = styled(Link)`
  color: ${WHITE};
&:link { text-decoration: none; }
&:visited { text-decoration: none; }
&:hover { text-decoration: none; }
&:active { text-decoration: none; }
`;

const Index = () => {
  const dispatch = useDispatch();
  const lockMap = useSelector((state) => state.mapSettingsReducer.lockMap);
  let { marketTitle, mapId } = useParams();
  let marketTitleLower = marketTitle.toLowerCase();

  const currentZoom = useSelector((state) => state.mapSettingsReducer.mapZoom);
  const accountType = useSelector((state) => state.authReducer.accountType);

  const currentMapData = useSelector((state) => state.dataReducer.data);
  const currentMapDataStringify = JSON.stringify(currentMapData);
  const userId = useSelector((state) => state.authReducer.userId);
  const mapName = useSelector((state) => state.mapSettingsReducer.mapName);
  const mapOwner = useSelector((state) => state.mapSettingsReducer.mapOwner);
  const mapType = useSelector((state) => state.mapSettingsReducer.mapType);
  const accessToken = localStorage.getItem("token");

  const marketIDSwitch = (marketTitleLower) => {
    switch (marketTitleLower) {
      case "norway":
        return 1;
      case "sweden":
        return 2;
      case "finland":
        return 3;
      case "denmark":
        return 4;
      case "netherlands":
        return 5;

      default:
        return 1;
    }
  };

  const marketID = marketIDSwitch(marketTitleLower);

  let canEdit = false;
  let canDuplicate = false;
  let canChangeMapType = true;

  if (mapType === "Original") {
    canChangeMapType = false;
  }

  if (mapOwner === userId || accountType === "SuperAdmin") {
    canEdit = true;
  } else {
    canChangeMapType = false;
    dispatch(mapSettingsActions.lockMap());
  }

  if (accountType === "SuperAdmin" || accountType === "Admin" || accountType === "Editor") {
    canDuplicate = true;
  } 

  const toggleLockHandler = () => {
    dispatch(mapSettingsActions.lockToggle());
  };

  const zoomInHandler = () => {
    dispatch(mapSettingsActions.zoomInHandler());
  };

  const zoomOutHandler = () => {
    dispatch(mapSettingsActions.zoomOutHandler());
  };

  const zoomResetHandler = () => {
    dispatch(mapSettingsActions.zoomResetHandler());
  };

  const saveMapHandler = async () => {
    try {
      // setIsLoading(true);
      const { data } = await axiosConfig.patch(
        `/markets/${marketID}/maps/${mapId}`,
        {
          data: currentMapDataStringify,
          name: mapName,
          mapType: mapType,
        },
        {
          headers: {
            Authorization: accessToken,
          },
        }
      );
      // Success
      alert("Changes saved!");
    } catch (err) {
      console.log(err);
    }
  };

  const refreshHandler = () => {
    window.location.reload();
  };

  const openFormHandler = (form) => {
    dispatch(mapSettingsActions.popupForm(form));
    dispatch(mapSettingsActions.popupToggle());
  };

  return (
    <Container>
      <LeftSection>
        <StyledLink to="/">
          <TopBarButton>
            <FaHome />
          </TopBarButton>
        </StyledLink>
        <Divider />
        {!canEdit ? (
          <DisabledTopBarButton>
            <RiSave3Fill />
          </DisabledTopBarButton>
        ) : (
          <TopBarButton>
            <RiSave3Fill onClick={() => saveMapHandler()} />
          </TopBarButton>
        )}
        <Divider />
        {!canEdit ? (
          <DisabledTopBarButton>
            <IoLockClosed />
          </DisabledTopBarButton>
        ) : (
          <TopBarButton onClick={toggleLockHandler}>
            {lockMap ? <IoLockClosed /> : <IoLockOpen />}
          </TopBarButton>
        )}
        <Divider />
        <TopBarButton onClick={refreshHandler}>
          <HiRefresh />
        </TopBarButton>
        <Divider />
        {!canChangeMapType ? (
          <DisabledTopBarButton>
            <FaUsers />
          </DisabledTopBarButton>
        ) : (
          <TopBarButton>
        <StyledLink onClick={() => {openFormHandler("maptype")}}>
          <TopBarButton>
            <FaUsers />
          </TopBarButton>
        </StyledLink>
          </TopBarButton>
        )}
        <Divider />
        {!canDuplicate ? (
          <DisabledTopBarButton>
            <StyledLink to={`/duplicatemap/${marketTitleLower}/${mapId}`}>
            <ButtonText>Duplicate Map</ButtonText>
            </StyledLink>
          </DisabledTopBarButton>
        ) : (
          <TopBarButton>
            <StyledLink to={`/duplicatemap/${marketTitleLower}/${mapId}`}>
              <ButtonText>Duplicate Map</ButtonText>
            </StyledLink>
          </TopBarButton>
        )}
      </LeftSection>
      <RightSection>
        <TopBarButton onClick={zoomInHandler}>
          {" "}
          <FiZoomIn />
        </TopBarButton>
        <Divider />
        {currentZoom === 10 ? (
          <DisabledTopBarButton>
            {" "}
            <FiZoomOut />
          </DisabledTopBarButton>
        ) : (
          <TopBarButton onClick={zoomOutHandler}>
            {" "}
            <FiZoomOut />
          </TopBarButton>
        )}

        <Divider />
        <TopBarButton onClick={zoomResetHandler}>
          {" "}
          <FiZoomOut />
          <ResetZoom />
        </TopBarButton>
      </RightSection>
    </Container>
  );
};

export default Index;
