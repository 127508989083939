import { useState, useRef } from "react";
import moment from "moment";
import styled from "styled-components";
import { dataActions } from "redux/store/data-slice";
import { mapSettingsActions } from "redux/store/map-settings-slice";
import { useSelector, useDispatch } from "react-redux";
import { FaRegSave } from "react-icons/fa";
import { ImWarning } from "react-icons/im";
import PrimaryButton from "components/shared/Buttons/PrimaryButton";

const Container = styled.div`
  position: relative;
  top: -35px;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
`;

const FormContainer = styled.div``;

const FormHeading = styled.h1`
  font-size: 1.5rem;
  margin-left: 27px;
`;

const Input = styled.input`
  padding: 10px;
  color: #000000;
  background: #ffffff;
  border: 0.5px solid #000000;
  border-radius: 3px;
  width: 150px;
  height: 40px;
  margin-bottom: 20px;
  margin-top: 30px;
  margin-left: 20px;
  margin-right: 10px;
  font-size: 1.4em;
`;

const Warning = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  color: #c70512;
  background-color: #fc9ca2;
  border: 2px solid #f91f2e;
  text-align: center;
  padding: 16px;
  height: 55px;
  font-size: 1rem;
  width: 550px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
`;

const WarningText = styled.div`
  width: 70%;
  position: relative;
  left: 141px;
  top: -52px;
  line-height: 20px;
`;

const WarningIcon = styled(ImWarning)`
  font-size: 2.5rem;
  color: #c70512;
  margin-top: 5px;
`;

const WarningIconContainer = styled.div`
  border-right: 1px #c70512 solid;
  width: 80px;
  height: inherit;
  padding-right: 15px;
`;

const SelectBox = styled.select`
  margin-left: 20px;
  padding: 10px;
  font-size: 1rem;
  margin-top: -5px;
`;

const StyledP = styled.p`
  margin-top: 9px;
`;

const PreferenceContainer = styled.div`
  display: flex;
  width: 400px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Form = () => {
  const minutesRef = useRef();
  const secondsRef = useRef();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.dataReducer.data);
  const containerId = useSelector((state) => state.dataReducer.currentId);
  const warningTime = data?.row[6].column[containerId].unsatisfactory;
  const initialPreference =
    data?.row[6].column[containerId].customer_time_preference;

  const [customerPreference, setCustomerPreference] =
    useState(initialPreference);

  const minutes = moment
    .utc(data?.row[5].column[containerId].total_time * 1000)
    .format("m");
  const seconds = moment
    .utc(data?.row[5].column[containerId].total_time * 1000)
    .format("s");

  const neutralValue = data?.row[6].column[containerId].neutral;
  const unsatisfactoryValue = data?.row[6].column[containerId].unsatisfactory;
  const badValue = data?.row[6].column[containerId].bad;
  const veryBadValue = data?.row[6].column[containerId].very_bad;

  const initialTimeHandler = () => {
    const moreLess = data?.row[6].column[containerId].customer_time_preference;
    const initialTime = data?.row[5].column[containerId].total_time;

    if (moreLess === "less") {
      if (initialTime > warningTime) {
        return true;
      } else {
        return false;
      }
    }

    if (moreLess === "more") {
      if (initialTime < warningTime) {
        return true;
      } else {
        return false;
      }
    }
  };

  const warningTimeHandler = (warningTime) => {
    const warningMinutes = moment.utc(warningTime * 1000).format("m");
    const warningSeconds = moment.utc(warningTime * 1000).format("s");

    return warningMinutes + " minutes " + warningSeconds + " seconds";
  };

  const onChangeHandler = () => {
    const newMinutes = parseInt(minutesRef.current.value * 60);
    const newSeconds = parseInt(secondsRef.current.value);
    const newTime = newMinutes + newSeconds;

    setMinutesInput(minutesRef.current.value);
    setSecondsInput(secondsRef.current.value);

    if (customerPreference === "less") {
      if (newTime > warningTime) {
        setShowWarning(true);
      } else {
        setShowWarning(false);
      }
    }

    if (customerPreference === "more") {
      if (newTime < warningTime) {
        setShowWarning(true);
      } else {
        setShowWarning(false);
      }
    }
  };

  const saveHandler = (customerPreference) => {
    const newMinutes = parseInt(minutesRef.current.value * 60);
    const newSeconds = parseInt(secondsRef.current.value);
    const newTime = newMinutes + newSeconds;

    dispatch(
      dataActions.updateTime({
        time: newTime,
        preference: customerPreference,
        neutral: neutralValue,
        unsatisfactory: unsatisfactoryValue,
        bad: badValue,
        veryBad: veryBadValue,
      })
    );

    dispatch(mapSettingsActions.popupToggle());
  };

  const [minutesInput, setMinutesInput] = useState(minutes);
  const [secondsInput, setSecondsInput] = useState(seconds);
  const [showWarning, setShowWarning] = useState(initialTimeHandler());

  const preferenceHandler = (preference) => {
    if (preference === "less") {
      return "below ";
    } else {
      return "above ";
    }
  };

  const handlePreferenceChange = (event) => {
    setCustomerPreference(event.target.value);
  };

  return (
    <Container>
      <FormHeading>Activity Time</FormHeading>
      <FormContainer>
        <Input
          id="minutes"
          ref={minutesRef}
          value={minutesInput}
          autocomplete="off"
          type="number"
          max="59"
          min="0"
          onChange={(m) => onChangeHandler(m.target.value)}
        />
        minutes
        <Input
          id="seconds"
          ref={secondsRef}
          value={secondsInput}
          autocomplete="off"
          type="number"
          max="59"
          min="0"
          onChange={(s) => onChangeHandler(s.target.value)}
        />{" "}
        seconds
      </FormContainer>
      <PreferenceContainer>
        <StyledP>Customer Preference:</StyledP>

        {customerPreference === "less" ? (
          <SelectBox onChange={handlePreferenceChange}>
            <option value="less" selected>
              Less time
            </option>
            <option value="more">More time</option>
          </SelectBox>
        ) : (
          <SelectBox onChange={handlePreferenceChange}>
            <option value="less">Less time</option>
            <option value="more" selected>
              More time
            </option>
          </SelectBox>
        )}
      </PreferenceContainer>
      {showWarning && (
        <Warning>
          <WarningIconContainer>
            <WarningIcon />
          </WarningIconContainer>
          <WarningText>
            The activity time exceeds the recommended time for this stage of the
            journey! Try keeping it{" "}
            <b>{preferenceHandler(customerPreference)}</b>
            <b>{warningTimeHandler(warningTime)}</b>.
          </WarningText>
        </Warning>
      )}
      <PrimaryButton
        width="160px"
        fontSize="1.2rem"
        onClick={() => {
          saveHandler(customerPreference);
        }}
      >
        Save <FaRegSave id="icon" style={{ verticalAlign: "middle" }} />
      </PrimaryButton>
    </Container>
  );
};

export default Form;
