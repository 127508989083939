import { useState, useRef } from "react";
import styled from "styled-components";
import { dataActions } from "redux/store/data-slice";
import { mapSettingsActions } from "redux/store/map-settings-slice";
import { useSelector, useDispatch } from "react-redux";
import { FaRegSave } from "react-icons/fa";
import PrimaryButton from "components/shared/Buttons/PrimaryButton";

const Container = styled.div`
  position: relative;
  top: -35px;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
`;

const FormContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const FormHeading = styled.h1`
  font-size: 1.5rem;
  margin-left: 27px;
`;

const Input = styled.input`
  padding: 10px;
  color: #000000;
  background: #ffffff;
  border: 0.5px solid #000000;
  border-radius: 3px;
  width: 300px;
  height: 40px;
  margin-bottom: 20px;
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 10px;
  font-size: 1.4em;
`;

const StyledLabel = styled.label``;

const DefaultForm = ({ name, dataRow }) => {
  const textRef = useRef();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.dataReducer.data);
  const currentId = useSelector((state) => state.dataReducer.currentId);
  const primaryText = data?.row[dataRow].column[currentId].primary_text;
  const secondaryText = data?.row[dataRow].column[currentId].secondary_text;
  const [primaryTextInput, setPrimaryTextInput] = useState(primaryText);
  const [secondaryTextInput, setSecondaryTextInput] = useState(secondaryText);
  const saveHandler = () => {
    dispatch(dataActions.updateHeading(primaryTextInput));
    dispatch(mapSettingsActions.popupToggle());
  };

  return (
    <Container>
      <FormHeading>{name}</FormHeading>
      <FormContainer>
        <StyledLabel>English:</StyledLabel>
        <Input
          id="text"
          ref={textRef}
          value={primaryTextInput}
          autocomplete="off"
          onChange={(t) => setPrimaryTextInput(t.target.value)}
        />
        <StyledLabel>Market Language:</StyledLabel>
        <Input
          id="text"
          ref={textRef}
          value={secondaryTextInput}
          autocomplete="off"
          onChange={(t) => setSecondaryTextInput(t.target.value)}
        />
      </FormContainer>
      <PrimaryButton width="160px" fontSize="1.2rem" onClick={saveHandler}>
        Save <FaRegSave id="icon" style={{ verticalAlign: "middle" }} />
      </PrimaryButton>
    </Container>
  );
};

export default DefaultForm;
