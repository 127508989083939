import styled from "styled-components";
import { BsArrowRightShort } from "react-icons/bs";
import JourneyTime from "../JourneyTime";
import { useSelector, useDispatch } from "react-redux";
import { mapSettingsActions } from "redux/store/map-settings-slice";
import { dataActions } from "redux/store/data-slice";

const Container = styled.div`
  width: 22vw;
  height: 70px;
  font-size: 0.9vw;
  margin-left: 30px;
  padding: 0px 20px 0px 20px;
  text-align: center;
  position: relative;
  top: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const TitleContainer = styled.div`
  width: 100%;
  font-size: 0.9vw;
  text-align: center;
  position: relative;
  top: -1px;
  margin-bottom: 20px;
`;

const TitleConnector = styled(BsArrowRightShort)`
  margin-left: 12px;
  margin-right: 12px;
  font-size: 1.5vw;
  position: relative;
  top: 8px;
`;

const Index = () => {
  const data = useSelector((state) => state.dataReducer.data);

  const mapName = useSelector((state) => state.mapSettingsReducer.mapName);

  return (
    <Container>
      <TitleContainer>{mapName}</TitleContainer>
      <JourneyTime />
    </Container>
  );
};

export default Index;
