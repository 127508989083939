import styled from "styled-components";
import {
  WHITE,
  SPECSAVERS_GREEN,
  SPECSAVERS_DARK_GREEN,
} from "constants/index";
import { FiPlusSquare } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { mapSettingsActions } from "redux/store/map-settings-slice";

const AddButton = styled.button`
  background-color: ${SPECSAVERS_GREEN};
  margin-top: 2px;
  width: 35px;
  height: 35px;
  border: none;
  border-radius: 3px;
  color: ${WHITE};
  cursor: pointer;

  &:hover {
    background-color: ${SPECSAVERS_DARK_GREEN};
  }
`;

const StyledPlusIcon = styled(FiPlusSquare)`
  font-size: 1.2rem;
`;

const Add = ({form}) => {
  const dispatch = useDispatch();

  const openFormHandler = () => {
    dispatch(mapSettingsActions.popupForm(form));
    dispatch(mapSettingsActions.popupToggle());
  };

  return (
    <AddButton onClick={openFormHandler}>
      <StyledPlusIcon />
    </AddButton>
  );
};

export default Add;
