import { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { dataActions } from "redux/store/data-slice";
import { mapSettingsActions } from "redux/store/map-settings-slice";
import { useSelector, useDispatch } from "react-redux";
import { FaRegSave, FaRegTrashAlt } from "react-icons/fa";
import PrimaryButton from "components/shared/Buttons/PrimaryButton";
import { BiColumns } from "react-icons/bi";

const Container = styled.div`
  position: relative;
  top: -35px;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
`;

const FormContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const FormHeading = styled.h1`
  font-size: 1.5rem;
  margin-left: 27px;
`;

const SelectBox = styled.select`
  margin-left: 20px;
  padding: 10px;
  font-size: 1rem;
  margin-top: 15px;
`;

const StyledP = styled.p`
  margin-top: 9px;
`;

const Input = styled.input`
  padding: 10px;
  color: #000000;
  background: #ffffff;
  border: 0.5px solid #000000;
  border-radius: 3px;
  width: 300px;
  height: 40px;
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 10px;
  font-size: 1.4em;
`;

const StyledLabel = styled.label`
  margin-top: 30px;
`;

const Form = () => {
  const textRef = useRef();
  const stageRef = useRef();
  const subStageRef = useRef();
  const positionRef = useRef();
  const [position, setPosition] = useState("before");
  const [stageChoice, setStageChoice] = useState();
  const [subStageChoice, setSubStageChoice] = useState();
  const [columnIndex, setColumnIndex] = useState();
  const [primaryTextInput, setPrimaryTextInput] = useState();
  const [secondaryTextInput, setSecondaryTextInput] = useState();

  const dispatch = useDispatch();
  const data = useSelector((state) => state.dataReducer.data);
  const headingData = data.row[0].column;
  const stageData = data.row[1].column;
  const subStageData = data.row[4].column;
  const initialSectionId = data.row[0].column[0].id;
  const [sectionId, setSectionId] = useState(initialSectionId);
  const primaryMapLanguage = useSelector(
    (state) => state.mapSettingsReducer.primaryMapLanguage
  );

  useEffect(() => {
    (async () => {
      setStageChoice(data.row[1].column[stageRef.current.value].id);
    })().then(() => {
      setSubStageChoice(subStageRef.current.value);
    });
  }, []);

  const saveHandler = () => {
    // Find column index
    const columnIndex = subStageData.findIndex(function (subStageId) {
      return subStageId.id === subStageChoice;
    });

    // Find Stage Index
    const selectedStageIndex = stageData.findIndex(function (getStageIndex) {
      return getStageIndex.id === stageChoice;
    });

    const stageSectionId = data.row[1].column[selectedStageIndex].section_id;

    //Find heading index
    const sectionIndex = headingData.findIndex(function (getHeadingId) {
      return getHeadingId.id === stageSectionId;
    });


    // Get heading column count and update
    const headingColumnCount = data.row[0].column[sectionIndex].columns;

    const newHeadingColumnCount = headingColumnCount + 1;

    // Get stage column count and upate

    const stageColumnCount = data.row[1].column[selectedStageIndex].columns;

    const newStageColumnCount = stageColumnCount + 1

    // Calculate new sub stage index
    let newSubStageIndex;
    if (position === "after") {
      newSubStageIndex = columnIndex + 1;
    } else {
      newSubStageIndex = columnIndex;
    }

    const prioritiesIndex = newSubStageIndex * 3;

    (async () => {
      dispatch(mapSettingsActions.toggleLoading());
      dispatch(
        dataActions.createSubStage({
          headingIndex: sectionIndex,
          newHeadingColumnCount: newHeadingColumnCount,
          newStageColumnCount: newStageColumnCount,
          newSectionId: stageSectionId,
          newStageId: stageChoice,
          newStagePrimaryText: primaryTextInput,
          newStageSecondaryText: secondaryTextInput,
          newJourneyStageIndex: selectedStageIndex,
          newPrioritiesIndex: prioritiesIndex,
          newColumnIndex: newSubStageIndex,
        })
      );
    })().then(() => {
      dispatch(mapSettingsActions.popupToggle());
      dispatch(mapSettingsActions.toggleLoading());
    });
  };

  const handlePositionChange = (event) => {
    setPosition(event.target.value);
  };

  const HandleStageChange = (event) => {
    (async () => {
      setStageChoice(data.row[1].column[event.target.value].id);
    })().then(() => {
      setSubStageChoice(subStageRef.current.value);
    });
  };

  const HandleSubStageChange = (event) => {
    setSubStageChoice(event.target.value);
  };

  return (
    <Container>
      <FormHeading>Add a new sub stage</FormHeading>
      <FormContainer>
        <StyledP>Add a new sub stage within...</StyledP>
        <SelectBox ref={stageRef} onChange={HandleStageChange}>
          {data.row[1].column.map((stageData, index) => (
            <>
              <option value={index}>
                {" "}
                {primaryMapLanguage
                  ? stageData.primary_text
                  : stageData.secondary_text}
              </option>
            </>
          ))}
        </SelectBox>
        <SelectBox onChange={handlePositionChange}>
          <option value="before" selected>
            before
          </option>
          <option value="after">after</option>
        </SelectBox>
        <SelectBox ref={subStageRef} onChange={HandleSubStageChange}>
          {data.row[4].column.map((subStageData, index) => (
            <>
              {subStageData.stage_id === stageChoice && (
                <option value={subStageData.id}>
                  {" "}
                  {primaryMapLanguage
                    ? subStageData.primary_text
                    : subStageData.secondary_text}
                </option>
              )}
            </>
          ))}
        </SelectBox>
        <StyledLabel>English:</StyledLabel>
        <Input
          id="text"
          ref={textRef}
          value={primaryTextInput}
          autocomplete="off"
          onChange={(t) => setPrimaryTextInput(t.target.value)}
        />
        <StyledLabel>Market Language:</StyledLabel>
        <Input
          id="text"
          ref={textRef}
          value={secondaryTextInput}
          autocomplete="off"
          onChange={(t) => setSecondaryTextInput(t.target.value)}
        />
      </FormContainer>
      <PrimaryButton width="160px" fontSize="1.2rem" onClick={saveHandler}>
        Add Stage
      </PrimaryButton>
    </Container>
  );
};

export default Form;
