import { useState, useRef } from "react";
import styled from "styled-components";
import { WHITE, BLACK } from "constants/index";
import { mapSettingsActions } from "redux/store/map-settings-slice";
import { dataActions } from "redux/store/data-slice";
import { useSelector, useDispatch } from "react-redux";
import { FaRegSave, FaRegTrashAlt } from "react-icons/fa";
import PrimaryButton from "components/shared/Buttons/PrimaryButton";

const Container = styled.div`
  position: relative;
  top: -35px;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
`;

const FormContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const FormHeading = styled.h1`
  font-size: 1.5rem;
  margin-bottom: 20px;
  margin-top: -10px;
`;

const Input = styled.input`
  padding: 10px;
  color: ${BLACK};
  background: ${WHITE};
  border: 0.5px solid ${BLACK};
  border-radius: 3px;
  width: 300px;
  height: 40px;
  margin-bottom: 20px;
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 10px;
  font-size: 1.4em;
`;

const SecondaryButton = styled.button`
  width: 160px;
  padding: 10px;
  background-color: #e30613;
  height: 60px;
  border: none;
  border-radius: 3px;
  margin-top: 15px;
  margin-left: 3px;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  font-size: 1.2em;

  &:hover {
    background-color: #00693b;
  }
`;

const ButtonContainer = styled.div`
  width: 400px;
  height: 100px;
  text-align: center;
`;

const StyledLabel = styled.label`
  position: relative;
  width: 320px;
  text-align: left;
`;

const Form = ({ rowNumber }) => {
  const primaryTextAreaRef = useRef();
  const secondaryTextAreaRef = useRef();

  const dispatch = useDispatch();
  const id = useSelector((state) => state.dataReducer.currentChildId);
  const data = useSelector((state) => state.dataReducer.data);
  const containerId = useSelector((state) => state.dataReducer.currentId);
  const text = data?.row[rowNumber].column[containerId].primary[id];
  const formHeading = data?.row[rowNumber].row_name;

  const saveHandler = () => {
    if (rowNumber === 7) {
      dispatch(mapSettingsActions.setIsBlockUpdated("varifocal"));
      dispatch(
        dataActions.updateVarifocalDifferences({
          id,
          updatedPrimaryText: primaryTextInput,
          updatedSecondaryText: secondaryTextInput,
        })
      );
    } else if (rowNumber === 8) {
      dispatch(mapSettingsActions.setIsBlockUpdated("b2b"));
      dispatch(
        dataActions.updateB2BDifferences({
          id,
          updatedPrimaryText: primaryTextInput,
          updatedSecondaryText: secondaryTextInput,
        })
      );
    } else if (rowNumber === 10) {
      dispatch(mapSettingsActions.setIsBlockUpdated("hygiene"));
      dispatch(
        dataActions.updateHygieneProcedures({
          id,
          updatedPrimaryText: primaryTextInput,
          updatedSecondaryText: secondaryTextInput,
        })
      );
    } else if (rowNumber === 12) {
      dispatch(mapSettingsActions.setIsBlockUpdated("technology"));
      dispatch(
        dataActions.updateTechnology({
          id,
          updatedPrimaryText: primaryTextInput,
          updatedSecondaryText: secondaryTextInput,
        })
      );
    }
    dispatch(mapSettingsActions.popupToggle());
  };
  const deleteHandler = () => {
    if (rowNumber === 7) {
      dispatch(mapSettingsActions.setIsBlockUpdated("varifocal"));
      dispatch(dataActions.deleteVarifocalDifferences({ id }));
    } else if (rowNumber === 8) {
      dispatch(mapSettingsActions.setIsBlockUpdated("b2b"));
      dispatch(dataActions.deleteB2BDifferences({ id }));
    } else if (rowNumber === 10) {
      dispatch(mapSettingsActions.setIsBlockUpdated("hygiene"));
      dispatch(dataActions.deleteHygieneProcedure({ id }));
    } else if (rowNumber === 12) {
      dispatch(mapSettingsActions.setIsBlockUpdated("technology"));
      dispatch(dataActions.deleteTechnology({ id }));
    }
    dispatch(mapSettingsActions.popupToggle());
  };

  const [primaryTextInput, setPrimaryTextInput] = useState(text.primary_text);
  const [secondaryTextInput, setSecondaryTextInput] = useState(
    text.secondary_text
  );

  return (
    <Container>
      <FormContainer>
        <FormHeading>{formHeading}</FormHeading>
        <StyledLabel>English:</StyledLabel>
        <Input
          id="Popuptext"
          ref={primaryTextAreaRef}
          value={primaryTextInput}
          autocomplete="off"
          onChange={(e) => setPrimaryTextInput(e.target.value)}
        ></Input>
        <StyledLabel>Market Language:</StyledLabel>
        <Input
          id="Popuptext"
          ref={secondaryTextAreaRef}
          value={secondaryTextInput}
          autocomplete="off"
          onChange={(e) => setSecondaryTextInput(e.target.value)}
        />
      </FormContainer>
      <ButtonContainer>
        <PrimaryButton width="160px" fontSize="1.2rem" onClick={saveHandler}>
          Save <FaRegSave id="icon" style={{ verticalAlign: "middle" }} />
        </PrimaryButton>
        <SecondaryButton onClick={deleteHandler}>
          Delete{" "}
          <FaRegTrashAlt size="1.2rem" style={{ verticalAlign: "middle" }} />
        </SecondaryButton>
      </ButtonContainer>
    </Container>
  );
};

export default Form;
