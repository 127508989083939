import { useSelector } from "react-redux";
import Row from "components/DynamicMappingTool/common/Row";
import Block from "components/DynamicMappingTool/common/Shapes/Block";
import { backgroundHandler } from "utils/backgroundHandler";
import RowButtons from "./RowButtons";

const Heading = () => {
  const primaryMapLanguage = useSelector(
    (state) => state.mapSettingsReducer.primaryMapLanguage
  );
  const data = useSelector((state) => state.dataReducer.data);
  const lockMap = useSelector((state) => state.mapSettingsReducer.lockMap);

  const widthHandler = (id) => {
    let widthCalculator = 0;
    // gapCounter counts how many columns/gaps there is in each section and adds it to the total width
    let gapCounter = 0;
    data.row[1].column.map((columnData, index) => {
      if (columnData.section_id === id) {
        widthCalculator = columnData.columns + widthCalculator;
        gapCounter++;
      }
    });
    gapCounter = gapCounter * 2.7;
    widthCalculator = widthCalculator * 424 + gapCounter;
    widthCalculator = widthCalculator + "px";
    return widthCalculator;
  };

  return (
    <Row display="flex">
      {!lockMap && <RowButtons form="createDeleteHeading" />}
      {data.row[0].column.map((columnData, index) => (
        <Block
          key={columnData.id}
          index={index}
          row="heading"
          text={
            primaryMapLanguage
              ? columnData.primary_text
              : columnData.secondary_text
          }
          backgroundColour={backgroundHandler(columnData.background_colour)}
          height="8px"
          columnWidth={widthHandler(columnData.id)}
        ></Block>
      ))}
    </Row>
  );
};

export default Heading;
