import { useSelector } from "react-redux";
import styled from "styled-components";
import Menu from "./index";
import { FiCheck } from "react-icons/fi";
import { useParams, Link } from "react-router-dom";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;

  a:link {
    color: white;
    text-decoration: none;
  }

  a:visited {
    color: white;
    text-decoration: none;
  }

  a:active {
    color: white;
    text-decoration: none;
  }
`;

const ProductName = styled.p`
  cursor: pointer;
  width: 200px;
  &:hover {
    cursor: pointer;
    filter: drop-shadow(0px 0px 8px #fff);
  }
`;

const DisabledProductName = styled.p`
  opacity: 0.4;
`;

const TickIcon = styled(FiCheck)`
  margin-left: 45px;
  font-size: 1.1rem;
  position: relative;
  top: 3px;
`;

const Products = () => {
  const data = useSelector((state) => state.dataReducer.data);
  const productsArr = data.header[0].product_maps;

  let { marketTitle } = useParams();
  let marketTitleLower = marketTitle.toLowerCase();

  const changeMapHandler = (market, mapId) => {
    // Load new map here, as 'Link' doesn't redirect because only param/prop changes
    // Short cut...
    // Could do this another way by updating the id in redux and running the axios call again
    window.location.href = `/map/${market}/${mapId}`;
  };

  return (
    <Menu width="190px">
      <Container>
        {productsArr.map((productData, index) => {
          return (
            <>
              {productsArr[index].map_id === null ? (
                <DisabledProductName>
                  {productsArr[index].product_type}
                </DisabledProductName>
              ) : (
                <Link
                  to={`/map/${marketTitleLower}/${productsArr[index].map_id}`}
                  onClick={() => {
                    changeMapHandler(
                      marketTitleLower,
                      productsArr[index].map_id
                    );
                  }}
                >
                  <ProductName>
                    {productsArr[index].product_type}
                    {productsArr[index].selected && <TickIcon />}
                  </ProductName>
                </Link>
              )}
            </>
          );
        })}
      </Container>
    </Menu>
  );
};

export default Products;
