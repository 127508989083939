import { IoStopwatch } from "react-icons/io5";
import { WHITE } from "constants/index";
import styled from "styled-components";
import moment from "moment";
import { useSelector } from "react-redux";

const Container = styled.div`
  width: 300px;
  text-align: center;
  font-size: 0.9vw;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ClockIcon = styled(IoStopwatch)`
  font-size: 1.5vw;
  margin-bottom: 10px;
  color: ${WHITE};
`;

const JourneyTime = () => {
  const data = useSelector((state) => state.dataReducer.data);

  let totalTime = 0;
  let totalTimeString;

  data.row[5].column.map(
    (activityData, i) => (totalTime = totalTime + activityData.total_time)
  );

  if (totalTime > 3599 && totalTime < 7198) {
    totalTimeString = moment
      .utc(totalTime * 1000)
      .format("h [hour] m [minutes] s [seconds]");
  } else if (totalTime > 7198) {
    totalTimeString = moment
      .utc(totalTime * 1000)
      .format("h [hours] m [minutes] s [seconds]");
  } else if (totalTime < 120) {
    totalTimeString = moment
      .utc(totalTime * 1000)
      .format("m [minute] s [seconds]");
  } else {
    totalTimeString = moment
      .utc(totalTime * 1000)
      .format("m [minutes] s [seconds]");
  }

  // Makes 'seconds' 'second' if 1 second
  if (totalTimeString.includes(" 1 sec")) {
    totalTimeString = totalTimeString.substring(0, totalTimeString.length - 1);
  }

  return (
    <Container>
      <ClockIcon />
      {totalTimeString}
    </Container>
  );
};

export default JourneyTime;
