import MapIcon from "assets/images/icon_map.png";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { FaRegTrashAlt } from "react-icons/fa";
import { useSelector } from "react-redux";

const Container = styled.div`
  margin-top: 10px;
  margin-left: 50px;
  line-height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 20px;
  border: none;
  border-radius: 3px;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: pink;
  text-align: center;
  padding: 20px;
  background-color: #ddd;
  border: none;
  border-radius: 3px;
`;

const ButtonContainer = styled.div`
  width: 120%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

const StyledImage = styled.img`
  max-height: 200px;
  max-width: 200px;
  border: 2px white solid;
  margin-bottom: 5px;
  border-radius: 3px;
`;

const DeleteIcon = styled(FaRegTrashAlt)`
  font-size: 1.3rem;
  position: relative;
  top: -8px;
  left: 50%;
  margin-bottom: -20px;

  &:hover {
    color: #c70512;
    cursor: pointer;
  }
`;

const MapDetails = ({
  mapName,
  mapMarket,
  mapTime,
  mapId,
  mapOwner,
  userGenerated,
  deleteMapHandler,
}) => {
  const rndDeg = Math.floor(Math.random() * 120);
  const currUserId = useSelector((state) => state.authReducer.userId);

  const canDeleteHandler = () => {
    if (userGenerated && mapOwner === currUserId) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Container>
      <IconContainer>
        {canDeleteHandler() && (
          <DeleteIcon
            onClick={() => {
              deleteMapHandler(mapId);
            }}
          />
        )}
        <Link to={`../map/${mapMarket}/${mapId}`}>
          {!userGenerated ? (
            <StyledImage src={MapIcon} alt="Map Icon" />
          ) : (
            <StyledImage
              style={{ filter: `hue-rotate(${rndDeg}deg)` }}
              src={MapIcon}
              alt="Map Icon"
            />
          )}
        </Link>
        <h3>{mapName}</h3>
      </IconContainer>
    </Container>
  );
};

export default MapDetails;
