import React, { useState, useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import styled from "styled-components";
import { WHITE, BLACK } from "constants/index";
import { useSelector, useDispatch } from "react-redux";
import { mapSettingsActions } from "redux/store/map-settings-slice";
import { dataActions } from "redux/store/data-slice";
import TimeBlock from "./TimeBlock";
import { backgroundHandler } from "utils/backgroundHandler";

const StyledActivitiesContainer = styled.div`
  color: ${WHITE};
  min-width: 396px;
  max-width: 396px;
  padding: 2px 10px 10px 10px;
  padding-top: 12px;
  padding-left: 14px;
  padding-bottom: 10px;
  background: ${(props) => props.backgroundColour};
  border-radius: 5px;
  margin-left: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10px;

  &:nth-child(1) {
    margin-left: 0px;
  }
`;

const ActivityBlock = styled.div`
  display: flex;
  align-items: center;
  margin-left: -40px;
  justify-content: center;
  color: ${BLACK};
  text-align: center;
  background-color: ${WHITE};
  padding: 16px;
  text-decoration: none;
  min-height: 80px;
  height: auto;
  font-size: 1rem;
  width: 300px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
`;

const TextSection = styled.div`
  width: 100;
  padding-right: 10px;
  margin-right: 10px;
  max-width: 210px;
  margin-left: 15px;
  line-height: 20px;
`;

const AddButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${BLACK};
  text-align: center;
  padding: 16px;
  text-decoration: none;
  height: 75px;
  font-size: 1.8rem;
  width: 300px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(30px);
  box-shadow: 0 0 2px wheat;
  border-radius: 5px;
  cursor: pointer;
`;

const ActivitiesContainer = ({ index }) => {
  const containerId = index;
  const primaryMapLanguage = useSelector(
    (state) => state.mapSettingsReducer.primaryMapLanguage
  );
  const data = useSelector((state) => state.dataReducer.data);
  const lockMap = useSelector((state) => state.mapSettingsReducer.lockMap);
  const sectionId = data?.row[5].column[index].section_id;
  const headingData = data.row[0].column

  const sectionIndex = headingData.findIndex(function (getHeadingId) {
    return getHeadingId.id === sectionId;
  });


  const backgroundColour = useSelector(
    (state) => state.dataReducer.data.row[0].column[sectionIndex].background_colour
  );
  
  const defaultState = data.row[5].column[index].primary;
  const [splitPriority, setSplitPriority] = useState(defaultState);

  const columnsFromBackend = {
    activityBlocks: {
      name: "Primary",
      items: splitPriority,
    },
  };

  const [columns, setColumns] = useState(columnsFromBackend);

  useEffect(() => {
    const newObj = {
      activityBlocks: {
        name: "Primary",
        items: defaultState,
      },
    };
    setColumns(newObj);
  }, [defaultState]);

  const onDragEnd = (result, columns, setColumns) => {
    dispatch(dataActions.updateCurrentId(index));

    if (!result.destination) return;
    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
      dispatch(
        dataActions.updateColleagueActivitesOrder({ newData: copiedItems })
      );
    }
  };

  const dispatch = useDispatch();

  const blockDataHandler = (id) => {
    dispatch(dataActions.updateCurrentId(containerId));
    dispatch(mapSettingsActions.popupForm("activities"));
    dispatch(dataActions.updateCurrentChildId(id));
    dispatch(mapSettingsActions.popupToggle());
  };

  const newBlockHandler = () => {
    dispatch(dataActions.updateCurrentId(containerId));
    dispatch(dataActions.createColleagueActivity());
    dispatch(mapSettingsActions.popupForm("activities"));
    dispatch(mapSettingsActions.popupToggle());
  };

  return (
    <StyledActivitiesContainer
      backgroundColour={backgroundHandler(backgroundColour)}
    >
      <DragDropContext
        onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
      >
        {Object.entries(columns).map(([columnId, column], i) => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              key={columnId}
            >
              <TimeBlock containerId={index} />
              <div style={{ margin: 8 }}>
                <Droppable droppableId={columnId} key={columnId}>
                  {(provided, snapshot) => {
                    return (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                          background: snapshot.isDraggingOver
                            ? "#f8f9f9"
                            : "#eff0f2",
                          padding: 4,
                          width: 250,
                        }}
                      >
                        {column.items.map((item, index) => {
                          return (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}
                              isDragDisabled={lockMap}
                            >
                              {(provided, snapshot) => {
                                return (
                                  <ActivityBlock
                                    id="eventblock"
                                    backgroundColour={backgroundColour}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    {...(!lockMap && {
                                      onClick: () => blockDataHandler(index),
                                    })}
                                  >
                                    <TextSection>
                                      {primaryMapLanguage
                                        ? item.primary_text
                                        : item.secondary_text}
                                    </TextSection>
                                  </ActivityBlock>
                                );
                              }}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    );
                  }}
                </Droppable>
              </div>
            </div>
          );
        })}
      </DragDropContext>

      {!lockMap && <AddButton onClick={newBlockHandler}>+</AddButton>}
    </StyledActivitiesContainer>
  );
};

export default ActivitiesContainer;
