import { useSelector } from "react-redux";
import ScrollContainer from "react-indiana-drag-scroll";
import styled from "styled-components";

const StyledScrollContainer = styled(ScrollContainer)`
  display: flex;
  display: -webkit-flex;
  position: relative;
  width: 100vw;
  min-height: 737px;
  max-height: 600px;
  margin-top: 12px;
  background-size: 40px 40px;
  background-image: linear-gradient(to right, #fafafa 1px, transparent 1px),
    linear-gradient(to bottom, #fafafa 1px, transparent 1px);
  -webkit-box-shadow: 3px 2px 8px -6px rgba(0, 0, 0, 0.52);
  box-shadow: 3px 2px 8px -6px rgba(0, 0, 0, 0.52);
`;

const ZoomContainer = styled.div`
  zoom: ${(props) => props.zoomPercentage};
`;

const Grid = ({ children }) => {
  document.body.style.overflow = "hidden";
  const sliceZoom = useSelector((state) => state.mapSettingsReducer.mapZoom);

  return (
    <StyledScrollContainer
      hideScrollbars={true}
      horizontal={true}
      ignoreElements="#eventblock, #Popuptext, #unscrollable"
    >
      <ZoomContainer zoomPercentage={`${sliceZoom}%`}>{children}</ZoomContainer>
    </StyledScrollContainer>
  );
};

export default Grid;
