import { useState, useEffect } from "react";
import styled from "styled-components";
import StyledTable from "containers/Pages/Admin/Table";
import axiosConfig from "services/api/axiosConfiig";
import { v4 as uuidv4 } from "uuid";
import { FaRegTrashAlt } from "react-icons/fa";

const Container = styled.div`
  margin-left: 10vw;
  width: 80vw;
  height: auto;
  margin-top: 84px;
  margin-bottom: 40px;
`;

const Header = styled.div`
  width: 100%;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #d1d7dc;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 1.4rem;
  margin-bottom: 10px;
`;

const UsersContainer = styled.div`
  width: 70%;
  margin-left: 15%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 28px;
  padding-bottom: 30px;
  border-bottom: 1px solid #d1d7dc;
  margin-bottom: 40px;
`;

const SortFilterLabel = styled.p`
  margin-top: 12px;
`;

const OrderLabel = styled.p`
  margin-top: 12px;
  margin-left: 15px;
`;

const StyledSortFilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 70%;
  height: auto;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  margin-bottom: 15px;

  &:nth-child(2) {
    border-bottom: 1px solid #d1d7dc;
    padding-bottom: 45px;
  }
`;

const Input = styled.input`
  padding: 2px 10px 2px 10px;
  color: #000000;
  background: #ffffff;
  border: 0.5px solid #000000;
  border-radius: 3px;
  width: 460px;
  height: 40px;
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 10px;
  font-size: 1em;
`;

const UnlockButton = styled.button`
  width: 120px;
  padding: 5px;
  background-color: #009b4f;
  height: 30px;
  border: none;
  border-radius: 3px;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  &:hover {
    background-color: #00693b;
  }
`;

const DeleteIcon = styled(FaRegTrashAlt)`
  color: white;
  font-size: 1.1rem;
  margin-left: 9px;
  margin-bottom: -2px;
`;

const RedDeleteIcon = styled(FaRegTrashAlt)`
  color: red;

  &:hover {
    cursor: pointer;
  }
`;

const SelectBox = styled.select`
  margin-left: 20px;
  padding: 10px;
  font-size: 1rem;
  margin-top: 15px;
`;

const Index = () => {
  const [rendered, setRendered] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [sortedUserData, setSortedUserData] = useState({});
  const accessToken = localStorage.getItem("token");
  const [sortKey, setSortKey] = useState("accountType");
  const [sortOrder, setSortOrder] = useState("ascending");
  const [searchQuery, setSearchQuery] = useState("");

  const getDataHandler = async () => {
    const config = {
      method: "get",
      headers: {
        Authorization: accessToken,
      },
    };

    await axiosConfig(`/users`, config)
      .then(function (response) {
        // Changing to string so that this column can be sorted using the same logic as the other columns
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].isLockedOut === true) {
            response.data[i].isLockedOut = "true";
          } else if (response.data[i].isLockedOut === false) {
            response.data[i].isLockedOut = "false";
          }
        }

        setSortedUserData(response.data);
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const unlockUserHandler = async (userId) => {
    const config = {
      method: "put",
      headers: {
        Authorization: accessToken,
      },
    };

    await axiosConfig(`/users/${userId}/unlock`, config)
      .then(function () {
        setRendered(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const deleteUserHandler = async (userId) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      const config = {
        method: "delete",
        headers: {
          Authorization: accessToken,
        },
      };

      await axiosConfig(`/users/${userId}`, config)
        .then(function () {
          alert("User has been deleted");
          setRendered(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    getDataHandler();
  }, [rendered]);

  const orderChangeHandler = (event) => {
    const newSortOrder = event.target.value;
    setSortOrder(newSortOrder);

    if (newSortOrder === "ascending") {
      const newSortedData = sortedUserData.sort((a, b) =>
        a[sortKey].toLowerCase().localeCompare(b[sortKey].toLowerCase())
      );
      setSortedUserData(newSortedData);
    } else if (newSortOrder === "descending") {
      const newSortedData = sortedUserData.sort((a, b) =>
        b[sortKey].toLowerCase().localeCompare(a[sortKey].toLowerCase())
      );
      setSortedUserData(newSortedData);
    }
  };

  const sortChangeHandler = (event) => {
    const sortByValue = event.target.value;

    setSortKey(sortByValue);

    if (sortOrder === "ascending") {
      const newSortedData = sortedUserData.sort((a, b) =>
        a[sortByValue].toLowerCase().localeCompare(b[sortByValue].toLowerCase())
      );
      setSortedUserData(newSortedData);
    } else if (sortOrder === "descending") {
      const newSortedData = sortedUserData.sort((a, b) =>
        b[sortByValue].toLowerCase().localeCompare(a[sortByValue].toLowerCase())
      );
      setSortedUserData(newSortedData);
    }
  };

  const searchKeys = [
    "firstName",
    "lastName",
    "email",
    "marketType",
    "accountType",
  ];

  return (
    <Container>
      <Header>
        <Title>Users</Title>Manage all the users on the platform.
      </Header>
      <UsersContainer>
        {!isLoading ? (
          <>
            <StyledSortFilterContainer>
              <SortFilterLabel>Sort By:</SortFilterLabel>
              <SelectBox onChange={sortChangeHandler}>
                <option value="firstName">Name</option>
                <option value="email">Email Address</option>
                <option value="marketType">Market</option>
                <option value="accountType">Role</option>
                <option value="isLockedOut">Status</option>
              </SelectBox>
              <OrderLabel>Order:</OrderLabel>
              <SelectBox onChange={orderChangeHandler}>
                <option value="ascending" selected>
                  Ascending
                </option>
                <option value="descending">Descending</option>
              </SelectBox>
            </StyledSortFilterContainer>
            <StyledSortFilterContainer>
              <Input
                id="text"
                placeholder="Search..."
                // ref={textRef}
                // value={secondaryTextInput}
                autocomplete="off"
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </StyledSortFilterContainer>
            <StyledTable>
              <colgroup>
                <col />
                <col />
                <col />
              </colgroup>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email Address</th>
                  <th>Market</th>
                  <th>Role</th>
                  <th>Status</th>
                  <th>Action</th>
                  <th>
                    <DeleteIcon />
                  </th>
                </tr>
              </thead>
              <tbody>
                {sortedUserData
                  .filter((user) =>
                    searchKeys.some((key) =>
                      user[key].toLowerCase().includes(searchQuery)
                    )
                  )
                  .map((userItem, index) => (
                    <tr key={index}>
                      <td key={index}>
                        {userItem.firstName} {userItem.lastName}
                      </td>
                      <td key={uuidv4()}>{userItem.email}</td>
                      <td key={uuidv4()}>{userItem.marketType}</td>
                      <td key={uuidv4()}>
                      {userItem.accountType === "SuperAdmin" ? (
                          <>Super Admin</>
                        ) : (
                          userItem.accountType
                        )}
                      </td>
                      <td key={uuidv4()}>
                        {userItem.isLockedOut === "true" ? (
                          <>Locked</>
                        ) : (
                          <>Active</>
                        )}
                      </td>
                      <td key={uuidv4()}>
                        {userItem.isLockedOut === "true" && (
                          <UnlockButton
                            width="160px"
                            fontSize="1.2rem"
                            onClick={() => unlockUserHandler(userItem.id)}
                          >
                            Unlock
                          </UnlockButton>
                        )}
                      </td>
                      <td key={uuidv4()}>
                        <RedDeleteIcon
                          onClick={() => deleteUserHandler(userItem.id)}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </StyledTable>
          </>
        ) : (
          <>Loading...</>
        )}
      </UsersContainer>
    </Container>
  );
};

export default Index;
