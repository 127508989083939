import { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import axiosConfig from "services/api/axiosConfiig";
import { dataActions } from "redux/store/data-slice";
import { mapSettingsActions } from "redux/store/map-settings-slice";
import { useSelector, useDispatch } from "react-redux";
import { BsFillInfoCircleFill } from "react-icons/bs";
import PrimaryButton from "components/shared/Buttons/PrimaryButton";
import { FaRegSave } from "react-icons/fa";
import { useParams } from "react-router-dom";

const Container = styled.div`
  position: relative;
  top: -35px;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
`;

const FormContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const FormHeading = styled.h1`
  font-size: 1.5rem;
  margin-left: 27px;
`;

const SelectBox = styled.select`
  margin-left: 20px;
  padding: 10px;
  font-size: 1rem;
  margin-top: 15px;
`;

const StyledP = styled.p`
  margin-top: 9px;
`;

const SecondaryButton = styled.button`
  width: 160px;
  padding: 10px;
  background-color: #e30613;
  height: 60px;
  border: none;
  border-radius: 3px;
  margin-top: 15px;
  margin-left: 3px;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  font-size: 1.2em;

  &:hover {
    background-color: #00693b;
  }
`;

const StyledLabel = styled.label`
  margin-top: 30px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  color: #000;
  background: rgba(107, 188, 69, 0.3);
  border: 2px solid #39b449;
  text-align: center;
  padding: 16px;
  padding-bottom: 30px;
  height: 149px;
  font-size: 1rem;
  width: 550px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
`;

const InfoText = styled.div`
  position: relative;
  line-height: 20px;
`;

const OriginalWarning = styled.div`
  text-align: center;
  margin-top: 12px;
  margin-bottom: 12px;
  color: #e30613;
`;

const InfoIcon = styled(BsFillInfoCircleFill)`
  font-size: 1.5rem;
  color: #39b449;
  margin-top: -5px;
  margin-bottom: 5px;
`;

const Form = () => {
  const [columnChoice, setColumnChoice] = useState();

  let { marketTitle, mapId } = useParams();
  let marketTitleLower = marketTitle.toLowerCase();

  const dispatch = useDispatch(0);
  const data = useSelector((state) => state.dataReducer.data);

  const mapType = useSelector((state) => state.mapSettingsReducer.mapType);
  const currentMapData = useSelector((state) => state.dataReducer.data);
  const currentMapDataStringify = JSON.stringify(currentMapData);
  const mapName = useSelector((state) => state.mapSettingsReducer.mapName);
  const accessToken = localStorage.getItem("token");

  const marketIDSwitch = (marketTitleLower) => {
    switch (marketTitleLower) {
      case "norway":
        return 1;
      case "sweden":
        return 2;
      case "finland":
        return 3;
      case "denmark":
        return 4;
      case "netherlands":
        return 5;

      default:
        return 1;
    }
  };

  const marketID = marketIDSwitch(marketTitleLower);

  const handleColumnChange = (event) => {
    setColumnChoice(event.target.value);
  };

  const saveHandler = async () => {
    try {
      // setIsLoading(true);
      const { data } = await axiosConfig.patch(
        `/markets/${marketID}/maps/${mapId}`,
        {
          data: currentMapDataStringify,
          name: mapName,
          mapType: columnChoice,
        },
        {
          headers: {
            Authorization: accessToken,
          },
        }
      );
      // Success
      dispatch(mapSettingsActions.setMapType(columnChoice));
      alert("Changes saved!");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Container>
      <FormHeading>Map Privacy Settings</FormHeading>
      <FormContainer>
        <Info>
          <InfoText>
            <p>
              <InfoIcon />
            </p>
            <p>
              Maps can either be public or private.
              <br />
              Change the privacy settings of this map below.
            </p>
            <br />
            <p>
              <b>Public Maps</b> can be seen by everyone.
            </p>
            <br />
            <p>
              <b>Private Maps</b> can only be seen by you.
            </p>
          </InfoText>
        </Info>
        {mapType === "Original" && (
          <OriginalWarning>
            This is an <b>Original</b> map, are you sure you want to change the
            type?
          </OriginalWarning>
        )}
        <StyledP>Map Type:</StyledP>
        <SelectBox onChange={handleColumnChange}>
          {mapType === "Original" && (
            <option value="Original">Original Map</option>
          )}
          {mapType === "Public" && (
            <>
              <option value="Public" selected>
                Public Map
              </option>
              <option value="Private">Private Map</option>
            </>
          )}
          {mapType === "Private" && (
            <>
              <option value="Public">Public Map</option>
              <option value="Private" selected>
                Private Map
              </option>
            </>
          )}
        </SelectBox>
      </FormContainer>
      <PrimaryButton width="160px" fontSize="1.2rem" onClick={saveHandler}>
        Save <FaRegSave id="icon" style={{ verticalAlign: "middle" }} />
      </PrimaryButton>
    </Container>
  );
};

export default Form;
