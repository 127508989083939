import { useState, useRef } from "react";
import styled from "styled-components";
import { dataActions } from "redux/store/data-slice";
import { mapSettingsActions } from "redux/store/map-settings-slice";
import { useSelector, useDispatch } from "react-redux";
import { FaRegSave, FaRegTrashAlt } from "react-icons/fa";
import PrimaryButton from "components/shared/Buttons/PrimaryButton";
import { BiColumns } from "react-icons/bi";
import ColourPicker from "components/DynamicMappingTool/ColourPicker";

const Container = styled.div`
  position: relative;
  top: -35px;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
`;

const FormContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const FormHeading = styled.h1`
  font-size: 1.5rem;
  margin-left: 27px;
`;

const SelectBox = styled.select`
  margin-left: 20px;
  padding: 10px;
  font-size: 1rem;
  margin-top: 15px;
`;

const StyledP = styled.p`
  margin-top: 9px;
`;

const Input = styled.input`
  padding: 10px;
  color: #000000;
  background: #ffffff;
  border: 0.5px solid #000000;
  border-radius: 3px;
  width: 300px;
  height: 40px;
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 10px;
  font-size: 1.4em;
`;

const StyledLabel = styled.label`
  margin-top: 30px;
`;

const Form = () => {
  const textRef = useRef();
  const hexRef = useRef();

  const [position, setPosition] = useState("before");
  const [columnChoice, setColumnChoice] = useState(0);
  const [hexInput, setHexInput] = useState();
  const updateHex = (newHex) => {
    setHexInput(newHex);
  };
  const [primaryTextInput, setPrimaryTextInput] = useState();
  const [secondaryTextInput, setSecondaryTextInput] = useState();

  const dispatch = useDispatch(0);
  const data = useSelector((state) => state.dataReducer.data);
  const primaryMapLanguage = useSelector(
    (state) => state.mapSettingsReducer.primaryMapLanguage
  );
  const saveHandler = () => {
    // Default colour if no hex is selected
    let newHex = hexInput;
    if (hexInput === undefined) {
      alert("Please pick a colour!");
      return;
    }
    const selectedColumn = parseInt(columnChoice);

    // Calculate new column index
    let newColumnIndex;
    if (position === "after") {
      newColumnIndex = selectedColumn + 1;
    } else {
      newColumnIndex = selectedColumn;
    }

    const journeyStageIndex = stageCountHandler(newColumnIndex);
    const columnCount = columnCountHandler(journeyStageIndex);
    const columnIndex = columnCount;
    const prioritiesIndex = columnCount * 3;

    (async () => {
      dispatch(mapSettingsActions.toggleLoading());
      dispatch(
        dataActions.createColumn({
          newHeadingIndex: newColumnIndex,
          newHeadingPrimaryText: primaryTextInput,
          newHeadingSecondaryText: secondaryTextInput,
          newHeadingHex: newHex,
          newJourneyStageIndex: journeyStageIndex,
          newPrioritiesIndex: prioritiesIndex,
          newColumnIndex: columnIndex,
        })
      );
    })().then(() => {
      dispatch(mapSettingsActions.popupToggle());
      dispatch(mapSettingsActions.toggleLoading());
    });
  };

  const stageCountHandler = (headingIndex) => {
    let i = 0;
    let stages = 0;
    for (const headingBlock of data.row[0].column) {
      if (i < headingIndex) {
        stages = stages + headingBlock.stages;
        i++;
      }
    }
    return stages;
  };

  const columnCountHandler = (headingIndex) => {
    let i = 0;
    let columns = 0;
    for (const headingBlock of data.row[1].column) {
      if (i < headingIndex) {
        columns = columns + headingBlock.columns;
        i++;
      }
    }
    return columns;
  };

  const handlePositionChange = (event) => {
    setPosition(event.target.value);
  };

  const handleColumnChange = (event) => {
    setColumnChoice(event.target.value);
  };

  return (
    <Container>
      <FormHeading>Add a new column</FormHeading>
      <FormContainer>
        <StyledP>Add a new column...</StyledP>
        <SelectBox onChange={handlePositionChange}>
          <option value="before" selected>
            before
          </option>
          <option value="after">after</option>
        </SelectBox>
        <SelectBox onChange={handleColumnChange}>
          {data.row[0].column.map((columnData, index) => (
            <option value={index}>
              {" "}
              {primaryMapLanguage
                ? columnData.primary_text
                : columnData.secondary_text}
            </option>
          ))}
        </SelectBox>
        <StyledLabel>English:</StyledLabel>
        <Input
          id="text"
          ref={textRef}
          value={primaryTextInput}
          autocomplete="off"
          onChange={(t) => setPrimaryTextInput(t.target.value)}
        />
        <StyledLabel>Market Language:</StyledLabel>
        <Input
          id="text"
          ref={textRef}
          value={secondaryTextInput}
          autocomplete="off"
          onChange={(t) => setSecondaryTextInput(t.target.value)}
        />
        <StyledLabel>Column colour: </StyledLabel>

        <ColourPicker updateHex={updateHex} />
        <Input
          hidden
          id="hex"
          ref={hexRef}
          value={hexInput}
          autocomplete="off"
          onChange={(h) => setHexInput(h.target.value)}
        />
      </FormContainer>
      <PrimaryButton width="160px" fontSize="1.2rem" onClick={saveHandler}>
        Add Column
      </PrimaryButton>
    </Container>
  );
};

export default Form;
