import { Line } from "react-chartjs-2";
import styled from "styled-components";
import Row from "components/DynamicMappingTool/common/Row";
import RowBackground from "components/DynamicMappingTool/common/RowBackground";
import RowContainer from "components/DynamicMappingTool/common/RowContainer";
import { mapSettingsActions } from "redux/store/map-settings-slice";
import { useSelector, useDispatch } from "react-redux";
import { BiLineChart } from "react-icons/bi";
import { MdShowChart } from "react-icons/md";
import { dataActions } from "redux/store/data-slice";


const ChartCanvas = styled.div`
  min-width: ${(props) => props.lineWidth};
  max-width: ${(props) => props.lineWidth};
  max-height: 200px;
  position: relative;
  top: -220px;
  padding-left: 200px;
`;

const RowButtons = styled.div`
  width: 100px;
  height: 50px;
  font-size: 2rem;
  position: absolute;
  left: -130px;
  top: 100px;
`;

const CustomerSatisfaction = ({ backgroundTop, rowTop, width }) => {
  const dispatch = useDispatch();


  let mapData = useSelector((state) => state.dataReducer.data);
  const showSatisafactionXScale = useSelector(
    (state) => state.mapSettingsReducer.showSatisafactionXScale
  );

  const rowCount = mapData.row[4].column.length
  const rowWidth = rowCount * 410 +'px'

  const scaleToggleHandler = () => {
    dispatch(mapSettingsActions.toggleSatisfactionXScale());
  };

  let chartData = [];
  let labelsArr = [];

  const satisfactionValuesHandler = () => {
    mapData.row[6].column.map((columnData, index) =>
      columnData.type === "auto"
        ? (chartData.push(columnData.auto_value), labelsArr.push(index))
        : (chartData.push(columnData.manual_value), labelsArr.push(index))
    );
  };

  satisfactionValuesHandler();

  const data = {
    labels: labelsArr,
    datasets: [
      {
        label: "Customer Satisfaction",
        width: 3,
        fill: false,
        lineTension: 0.1,
        animation: true,
        backgroundColor: "red",
        borderColor: "#3ab54a",
        borderWidth: 10,
        borderCapStyle: "butt",
        borderDash: [],
        aspectRatio: 9,
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "#3ab54a",
        pointBackgroundColor: "#3ab54a",
        pointBorderWidth: 1,
        pointRadius: 20,
        pointHoverRadius: 20,
        scaleGridLineColor: "#3ab54a",
        pointHoverBackgroundColor: "#3ab54a",
        pointHoverBorderColor: "#3ab54a",
        pointHoverBorderWidth: 1,
        pointHitRadius: 10,
        showTooltips: false,
        data: chartData,
      },
    ],
  };

  const myScale = {
    x: {
      display: false,
    },
    y: {
      display: showSatisafactionXScale,
    },
  };

  const lineOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: myScale,
    plugins: {
      legend: {
        display: false,
      },
    },
    tooltips: {
      enabled: false,
    },
  };

  return (
    <RowContainer height="222px">
      <RowBackground rowName="Customer Satisfaction" />

      <Row display="flex" top="128px">
        <ChartCanvas lineWidth={rowWidth}>
          <RowButtons>
            {showSatisafactionXScale ? (
              <MdShowChart onClick={() => scaleToggleHandler()} />
            ) : (
              <BiLineChart onClick={() => scaleToggleHandler()} />
            )}
          </RowButtons>
          <Line data={data} options={lineOptions} />
        </ChartCanvas>
      </Row>
    </RowContainer>
  );
};

export default CustomerSatisfaction;
