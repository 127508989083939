import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { useHistory } from "react-router-dom";
import { authActions } from "redux/store/auth-slice";
import axiosConfig from "services/api/axiosConfiig";
import GlobalStyles from "./globalStyles";
import NavBar from "containers/Layout/NavBar";
import Footer from "containers/Layout/Footer/Footer";
import HomePage from "containers/Pages/HomePage";
import MarketLanding from "containers/Pages/MarketPage";
import AllMarkets from "containers/Pages/AllMarkets";
import DynamicMappingTool from "containers/Map";
import Login from "containers/Pages/Login";
import ForgotPassword from "containers/Pages/ForgotPassword";
import UserSettings from "containers/Pages/UserSettings";
import AdminPanel from "containers/Pages/Admin/AdminPanel";
import InviteUser from "containers/Pages/Admin/InviteUser";
import PendingInvites from "containers/Pages/Admin/PendingInvites";
import Users from "containers/Pages/Admin/Users";
import SetUpUser from "containers/Pages/SetUpUser";
import ResetPassword from "containers/Pages/ResetPassword";
import FileManager from "containers/Pages/FileManager";
import DuplicateMap from "containers/Pages/DuplicateMap";
import { RefreshAuthToken } from "utils/refreshAuthToken";
import { min } from "moment";

function App() {
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoggedIn = useSelector((state) => state.authReducer.isLoggedIn);
  const accountType = useSelector((state) => state.authReducer.accountType);

  let isAdmin = false;
  if (
    (isLoggedIn && accountType === "Admin") ||
    (isLoggedIn && accountType === "SuperAdmin")
  ) {
    isAdmin = true;
  }

  const updateToken = async (accessToken, refreshToken) => {
    try {
      const { data } = await axiosConfig.post(
        `/authentication/refresh`,
        {
          refreshToken: refreshToken,
        },
        {
          headers: {
            Authorization: accessToken,
          },
        }
      );
      // Success
      localStorage.setItem("token", data.accessToken);
      localStorage.setItem("refresh", data.refreshToken);
    } catch (err) {
      console.log(err);
      dispatch(authActions.logoutHandler());
      history.replace("/login");
    }
  };

  // Update auth token every 10 minutes, restarts every time it changes either by this useEffect or user refreshing page
  useEffect(() => {
    // TODO CHANGE ME
    const fiveMinutes = 1000 * 60 * 50;

    setInterval(() => {
      if (localStorage.getItem("token")) {
        updateToken(
          localStorage.getItem("token"),
          localStorage.getItem("refresh")
        );
      }
    }, fiveMinutes);
  }, [localStorage.getItem("token")]);

  const getAccountData = async (userId, accessToken) => {
    const config = {
      method: "get",
      headers: {
        Authorization: accessToken,
      },
    };

    await axiosConfig(`/users/${userId}`, config)
      .then(function (response) {
        dispatch(
          authActions.loginHandler({
            accessToken: accessToken,
            userId: userId,
            accountType: response.data.accountType,
            market: response.data.marketType,
          })
        );
        updateToken(
          localStorage.getItem("token"),
          localStorage.getItem("refresh")
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // Prevents losing account/market data if user refreshers
  if (isLoggedIn && !accountType) {
    getAccountData(
      localStorage.getItem("userId"),
      localStorage.getItem("token")
    );
  }

  return (
    <Router>
      <GlobalStyles />
      <NavBar />
      <Switch>
        {isLoggedIn && (
          <Route
            exact
            path="/marketlanding/:country(norway|sweden|finland|denmark|netherlands)"
          >
            <MarketLanding />
          </Route>
        )}
        {isLoggedIn && (
          <Route exact path="/marketlanding/:country(allmarkets)">
            <AllMarkets />
          </Route>
        )}
        {!isLoggedIn && (
          <Route path="/login">
            <Login />
          </Route>
        )}
        {!isLoggedIn && (
          <Route path="/setupuser/:token">
            <SetUpUser />
          </Route>
        )}
        {!isLoggedIn && (
          <Route path="/resetpassword/:token">
            <ResetPassword />
          </Route>
        )}
        {!isLoggedIn && (
          <Route path="/forgotpassword">
            <ForgotPassword />
          </Route>
        )}
        {!isLoggedIn && (
          <Route path="/forgotpassword">
            <ForgotPassword />
          </Route>
        )}
        {isLoggedIn && (
          <Route path="/map/:marketTitle/:mapId">
            <DynamicMappingTool />
          </Route>
        )}
        {isLoggedIn && (
          <Route path="/duplicatemap/:marketTitle/:mapId">
            <DuplicateMap />
          </Route>
        )}

        {isLoggedIn && (
          <Route path="/settings">
            <UserSettings />
          </Route>
        )}
        {isAdmin && (
          <Route path="/admin/adminpanel">
            <AdminPanel />
          </Route>
        )}
        {isAdmin && (
          <Route path="/admin/invite">
            <InviteUser />
          </Route>
        )}
        {isAdmin && (
          <Route path="/admin/pendinginvites">
            <PendingInvites />
          </Route>
        )}
        {isAdmin && (
          <Route path="/admin/users">
            <Users />
          </Route>
        )}
        {isAdmin && (
          <Route
            exact
            path="/filemanager/:country(norway|sweden|finland|denmark|netherlands)"
          >
            <FileManager />
          </Route>
        )}
        {isLoggedIn && (
          <Route path="/">
            <HomePage />
          </Route>
        )}

        {!isLoggedIn && (
          <Route path="*">
            <Redirect to="/login" />
          </Route>
        )}
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
