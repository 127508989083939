import styled from "styled-components";
import {
  WHITE,
  SPECSAVERS_GREEN,
  SPECSAVERS_DARK_GREEN,
} from "constants/index";

const PrimaryButton = styled.button`
  width: ${(props) => props.width};
  padding: 10px;
  background-color: ${SPECSAVERS_GREEN};
  height: 60px;
  border: none;
  border-radius: 3px;
  margin-top: 15px;
  margin-left: 3px;
  color: ${WHITE};
  font-size: ${(props) => props.fontSize};
  cursor: pointer;
  text-decoration: underline ${SPECSAVERS_GREEN};
  padding-bottom: ${(props) => props.paddingBottom};


  &:hover {
    background-color: ${SPECSAVERS_DARK_GREEN};
  }
`;

const Button = ({ width, fontSize, onClick, paddingBottom, children }) => {
  return (
    <PrimaryButton width={width} fontSize={fontSize} paddingBottom={paddingBottom} onClick={onClick}>
      {children}
    </PrimaryButton>
  );
};

export default Button;
