import styled from "styled-components";
import { Link } from "react-router-dom";
import Logo from "assets/images/logo.png";
import { SPECSAVERS_GREEN } from "constants/index";

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "SpecsaversRegular";
`;

const LogoImage = styled.img`
  height: 5.5vh;
`;

const ImageTextContainer = styled.div`
  width: 250px;
`;

const LogoText = styled.p`
  color: ${SPECSAVERS_GREEN};
  margin-top: 5px;
  font-size: 1.5vh;
  font-weight: bold;
`;

const NavLogo = () => {
  return (
    <LogoContainer>
      <ImageTextContainer>
        <Link to="/">
          <LogoImage src={Logo} alt="SpecSavers Logo" />
        </Link>

        <LogoText>CUSTOMER JOURNEY PORTAL</LogoText>
      </ImageTextContainer>
    </LogoContainer>
  );
};

export default NavLogo;
