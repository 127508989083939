import { useState, useRef } from "react";
import styled from "styled-components";
import { dataActions } from "redux/store/data-slice";
import { mapSettingsActions } from "redux/store/map-settings-slice";
import { useSelector, useDispatch } from "react-redux";
import { FaRegSave, FaRegTrashAlt } from "react-icons/fa";
import PrimaryButton from "components/shared/Buttons/PrimaryButton";

const Container = styled.div`
  position: relative;
  top: -35px;
`;

const FormHeading = styled.h1`
  font-size: 1.5rem;
`;

const FormContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Input = styled.input`
  padding: 10px;
  color: #000000;
  background: #ffffff;
  border: 0.5px solid #000000;
  border-radius: 3px;
  width: 100%;
  height: 40px;
  margin-bottom: 20px;
  margin-top: 30px;
  font-size: 1.4em;
`;

const SecondaryButton = styled.button`
  width: 160px;
  padding: 10px;
  background-color: #e30613;
  height: 60px;
  border: none;
  border-radius: 3px;
  margin-top: 15px;
  margin-left: 3px;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  font-size: 1.2em;

  &:hover {
    background-color: #00693b;
  }
`;

const StyledLabel = styled.label``;

const Form = () => {
  const primaryTextAreaRef = useRef();
  const secondaryTextAreaRef = useRef();
  const dispatch = useDispatch();
  const id = useSelector((state) => state.dataReducer.currentId);
  const data = useSelector((state) => state.dataReducer.data);
  const primaryTextValue = data?.row[3].column[id].primary_text;
  const secondaryTextValue = data?.row[3].column[id].secondary_text;

  const saveHandler = () => {
    let newPrimaryText = primaryTextAreaRef.current.value;
    let newSecondaryText = secondaryTextAreaRef.current.value;

    if (newPrimaryText === "") {
      newPrimaryText = newSecondaryText;
    }

    if (newSecondaryText === "") {
      newSecondaryText = newPrimaryText;
    }

    (async () => {
      dispatch(mapSettingsActions.toggleLoading());
      dispatch(
        dataActions.updateColleaguePriorities({
          id,
          updatePrimaryText: newPrimaryText,
          updateSecondaryText: newSecondaryText,
        })
      );
    })().then(() => {
      dispatch(mapSettingsActions.toggleLoading());
      dispatch(mapSettingsActions.popupToggle());
    });
  };

  const deleteHandler = () => {
    let newPrimaryText = "";
    let newSecondaryText = "";

    dispatch(mapSettingsActions.toggleLoading());

    dispatch(
      dataActions.updateColleaguePriorities({
        id,
        updatePrimaryText: newPrimaryText,
        updateSecondaryText: newSecondaryText,
      })
    );

    setTimeout(function () {
      dispatch(mapSettingsActions.toggleLoading());
    }, 1);

    dispatch(mapSettingsActions.popupToggle());
  };

  const [primaryTextInput, setPrimaryTextInput] = useState(primaryTextValue);
  const [secondaryTextInput, setSecondaryTextInput] =
    useState(secondaryTextValue);

  return (
    <Container>
      <FormHeading>Store Staff Priorities</FormHeading>
      <FormContainer>
        <StyledLabel>English:</StyledLabel>
        <Input
          id="text"
          ref={primaryTextAreaRef}
          value={primaryTextInput}
          autocomplete="off"
          onChange={(t) => setPrimaryTextInput(t.target.value)}
        />
        <StyledLabel>Market Language:</StyledLabel>
        <Input
          id="text"
          ref={secondaryTextAreaRef}
          value={secondaryTextInput}
          autocomplete="off"
          onChange={(t) => setSecondaryTextInput(t.target.value)}
        />
      </FormContainer>
      <PrimaryButton width="160px" fontSize="1.2rem" onClick={saveHandler}>
        Save <FaRegSave id="icon" style={{ verticalAlign: "middle" }} />
      </PrimaryButton>
      <SecondaryButton onClick={deleteHandler}>
        Delete
        <FaRegTrashAlt
          size="1.2rem"
          style={{ verticalAlign: "middle", marginLeft: "10px" }}
        />
      </SecondaryButton>
    </Container>
  );
};

export default Form;
