import styled from "styled-components";

const StyledRowContainer = styled.div`
  height: ${(props) => props.height};
  margin-top: 15px;
  margin-bottom: ${(props) => props.marginBottom};
`;

const RowContainer = ({ children, height, marginBottom }) => {
  return (
    <StyledRowContainer height={height} marginBottom={marginBottom}>
      {children}
    </StyledRowContainer>
  );
};

export default RowContainer;
