import { useState } from "react";
import styled from "styled-components";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { SPECSAVERS_GREEN } from "constants/index";

const Container = styled.div`
  position: relative;
  left: 310px;
`;

const StyledToolTip = styled(BsFillInfoCircleFill)`
  font-size: 1.3em;
  color: ${SPECSAVERS_GREEN};
  position: absolute;
`;

const TooltipText = styled.div`
  width: 200px;
  text-align: center;
  background-color: #eeeeee;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 7px;
  position: absolute;
  top: -95px;
  left: -100px;
`;

const Tooltip = ({ text }) => {
  const [textVisible, setTextVisible] = useState(false);

  const toggleHandler = () => {
    setTextVisible(!textVisible);
  };

  return (
    <Container>
      {textVisible && <TooltipText onClick={toggleHandler}>{text}</TooltipText>}
      <StyledToolTip onClick={toggleHandler} />
    </Container>
  );
};

export default Tooltip;
