import { configureStore } from "@reduxjs/toolkit";
import mapSettings from "./map-settings-slice";
import Data from "./data-slice";
import Auth from "./auth-slice";

const store = configureStore({
  reducer: {
    dataReducer: Data,
    mapSettingsReducer: mapSettings,
    authReducer: Auth,
  },
});

export default store;
