import { useEffect } from "react";
import { useSelector } from "react-redux";
import Row from "components/DynamicMappingTool/common/Row";
import RowBackground from "components/DynamicMappingTool/common/RowBackground";
import RowContainer from "components/DynamicMappingTool/common/RowContainer";
import JourneyBlock from "components/DynamicMappingTool/common/Shapes/JourneyBlock";
import { backgroundHandler } from "utils/backgroundHandler";
import RowButtons from "./RowButtons";

const TimedSubStages = () => {
  const lockMap = useSelector((state) => state.mapSettingsReducer.lockMap);
  const data = useSelector((state) => state.dataReducer.data);
  const primaryMapLanguage = useSelector(
    (state) => state.mapSettingsReducer.primaryMapLanguage
  );
  const headingData = data.row[0].column;

  const GetBackgroundGradient = (sectionId) => {
    const sectionIndex = headingData.findIndex(function (getHeadingId) {
      return getHeadingId.id === sectionId;
    });

    const backgroundColour = useSelector(
      (state) =>
        state.dataReducer.data.row[0].column[sectionIndex].background_colour
    );

    return backgroundHandler(backgroundColour);
  };

  return (
    <RowContainer height="210px" marginBottom="-51px">
      <RowBackground rowName="Timed Sub Stages" />
      {!lockMap && <RowButtons form="createDeleteSubStage" />}

      <Row display="flex" top="-80px">
        {data.row[4].column.map((columnData, i) => (
          <JourneyBlock
            key={columnData.id}
            index={i}
            row="substage"
            text={
              primaryMapLanguage
                ? columnData.primary_text
                : columnData.secondary_text
            }
            backgroundColour={GetBackgroundGradient(columnData.section_id)}
            height="125px"
            columnWidth="420px"
            minWidth="392px"
          ></JourneyBlock>
        ))}
      </Row>
    </RowContainer>
  );
};

export default TimedSubStages;
