import { useState, useRef } from "react";
import styled from "styled-components";
import { dataActions } from "redux/store/data-slice";
import { mapSettingsActions } from "redux/store/map-settings-slice";
import { useSelector, useDispatch } from "react-redux";
import { FaRegSave, FaRegTrashAlt } from "react-icons/fa";
import PrimaryButton from "components/shared/Buttons/PrimaryButton";
import { BiColumns } from "react-icons/bi";

const Container = styled.div`
  position: relative;
  top: -35px;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
`;

const FormContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const FormHeading = styled.h1`
  font-size: 1.5rem;
  margin-left: 27px;
`;

const SelectBox = styled.select`
  margin-left: 20px;
  padding: 10px;
  font-size: 1rem;
  margin-top: 15px;
`;

const StyledP = styled.p`
  margin-top: 9px;
`;

const Input = styled.input`
  padding: 10px;
  color: #000000;
  background: #ffffff;
  border: 0.5px solid #000000;
  border-radius: 3px;
  width: 300px;
  height: 40px;
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 10px;
  font-size: 1.4em;
`;

const StyledLabel = styled.label`
  margin-top: 30px;
`;

const Form = () => {
  const textRef = useRef();
  const stageRef = useRef();
  const positionRef = useRef();
  const [position, setPosition] = useState("before");
  const [columnChoice, setColumnChoice] = useState(0);
  const [primaryTextInput, setPrimaryTextInput] = useState();
  const [secondaryTextInput, setSecondaryTextInput] = useState();

  const dispatch = useDispatch(0);
  const data = useSelector((state) => state.dataReducer.data);
  const headingData = data.row[0].column;

  const primaryMapLanguage = useSelector(
    (state) => state.mapSettingsReducer.primaryMapLanguage
  );

  const initialSectionId = data.row[0].column[0].id;

  const [sectionId, setSectionId] = useState(initialSectionId);

  const saveHandler = () => {
    const selectedStage = parseInt(stageRef.current.value);

    const newStageSectionId = data.row[1].column[selectedStage].section_id;

    const sectionIndex = headingData.findIndex(function (getHeadingId) {
      return getHeadingId.id === sectionId;
    });

    let headingColumnCount = data.row[0].column[sectionIndex].columns;
    let headingStageCount = data.row[0].column[sectionIndex].stages;

    const newHeadingColumnCount = headingColumnCount + 1;
    const newHeadingStageCount = headingStageCount + 1;

    // Calculate new stage index
    let newStageIndex;
    if (position === "after") {
      newStageIndex = selectedStage + 1;
    } else {
      newStageIndex = selectedStage;
    }

    const columnCount = columnCountHandler(newStageIndex);
    const columnIndex = columnCount;
    const prioritiesIndex = newStageIndex * 3;

    (async () => {
      dispatch(mapSettingsActions.toggleLoading());
      dispatch(
        dataActions.createStage({
          headingIndex: sectionIndex,
          newHeadingColumnCount: newHeadingColumnCount,
          newHeadingStageCount: newHeadingStageCount,
          newSectionId: newStageSectionId,
          newStagePrimaryText: primaryTextInput,
          newStageSecondaryText: secondaryTextInput,
          newJourneyStageIndex: newStageIndex,
          newPrioritiesIndex: prioritiesIndex,
          
          newColumnIndex: columnIndex,
        })
      );
    })().then(() => {
      dispatch(mapSettingsActions.popupToggle());
      dispatch(mapSettingsActions.toggleLoading());
    });
  };

  const columnCountHandler = (headingIndex) => {
    let i = 0;
    let columns = 0;
    for (const headingBlock of data.row[1].column) {
      if (i < headingIndex) {
        columns = columns + headingBlock.columns;
        i++;
      }
    }
    return columns;
  };

  const handlePositionChange = (event) => {
    setPosition(event.target.value);
  };

  const HandleColumnChange = (event) => {
    setColumnChoice(event.target.value);
    const currentSectionId = data.row[0].column[event.target.value].id;
    setSectionId(currentSectionId);
  };

  return (
    <Container>
      <FormHeading>Add a new stage</FormHeading>
      <FormContainer>
        <StyledP>Add a new stage within...</StyledP>

        <SelectBox onChange={HandleColumnChange}>
          {data.row[0].column.map((columnData, index) => (
            <option value={index}>
              {" "}
              {primaryMapLanguage
                ? columnData.primary_text
                : columnData.secondary_text}
            </option>
          ))}
        </SelectBox>

        <SelectBox onChange={handlePositionChange}>
          <option value="before" selected>
            before
          </option>
          <option value="after">after</option>
        </SelectBox>

        <SelectBox ref={stageRef}>
          {data.row[1].column.map((stageData, index) => (
            <>
              {stageData.section_id === sectionId && (
                <option value={index}>
                  {" "}
                  {primaryMapLanguage
                    ? stageData.primary_text
                    : stageData.secondary_text}
                </option>
              )}
            </>
          ))}
        </SelectBox>
        <StyledLabel>English:</StyledLabel>
        <Input
          id="text"
          ref={textRef}
          value={primaryTextInput}
          autocomplete="off"
          onChange={(t) => setPrimaryTextInput(t.target.value)}
        />
        <StyledLabel>Market Language:</StyledLabel>
        <Input
          id="text"
          ref={textRef}
          value={secondaryTextInput}
          autocomplete="off"
          onChange={(t) => setSecondaryTextInput(t.target.value)}
        />
      </FormContainer>
      <PrimaryButton width="160px" fontSize="1.2rem" onClick={saveHandler}>
        Add Stage
      </PrimaryButton>
    </Container>
  );
};

export default Form;
