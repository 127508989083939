import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { mapSettingsActions } from "redux/store/map-settings-slice";
import RowBackground from "components/DynamicMappingTool/common/RowBackground";
import Row from "components/DynamicMappingTool/common/Row";
import RowContainer from "components/DynamicMappingTool/common/RowContainer";
import FolderIcon from "assets/images/icon_folder.png";

const StyledFolderIcon = styled.img`
  height: 160px;
  position: relative;
  top: -245px;
  margin-left: 190px;
  cursor: pointer;

  &:nth-child(1) {
    margin-left: 120px;
  }
`;

const DocumentCountCircle = styled.div`
  background-color: beige;
  color: #000000;
  border-radius: 70px;
  min-width: 40px;
  height: 40px;
  position: relative;
  top: -160px;
  left: -80px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
`;

const FolderContainer = styled.div`
  display: flex;
  margin-right: 70px;
`;

const DocumentsRow = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.dataReducer.data);

  const clickHandler = () => {
    dispatch(mapSettingsActions.popupToggle());
    dispatch(mapSettingsActions.popupForm("documents"));
  };

  const rowVisibilityData = useSelector(
    (state) => state.mapSettingsReducer.rowVisibilityData
  );

  const rowMarginHandler = () => {
    if (rowVisibilityData.row[8].visible) {
      return "-106px";
    } else {
      return "85px";
    }
  };

  return (
    <RowContainer height="auto" marginBottom={rowMarginHandler}>
      <RowBackground rowName="Documents" />
      <Row display="flex" top="117px">
        {data.row[14].column.map((columnData, index) => (
          <FolderContainer key={index}>
            <StyledFolderIcon
              src={FolderIcon}
              alt="Folder Icon"
              onClick={clickHandler}
            />
            <DocumentCountCircle> 0 </DocumentCountCircle>
          </FolderContainer>
        ))}
      </Row>
    </RowContainer>
  );
};

export default DocumentsRow;
