import { useSelector } from "react-redux";
import Row from "components/DynamicMappingTool/common/Row";
import SatisfactionToggleButton from "./SatisfactionToggleButton";

const Index = () => {
  const data = useSelector((state) => state.dataReducer.data);

  return (
    <div>
      <Row display="flex" top="-400px" marginTop="12px" marginBottom="-135px">
        {data.row[6].column.map((columnData, index) => (
          <SatisfactionToggleButton
            index={index}
            autoValue={columnData.auto_value}
            manualValue={columnData.manual_value}
            customColumn={columnData.custom_column}
          />
        ))}
      </Row>
    </div>
  );
};

export default Index;
