import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationsEN from "translations/en.json";
import translationsNO from "translations/no.json";
import translationsSV from "translations/sv.json";
import translationsFI from "translations/fi.json";
import translationsDA from "translations/da.json";
import translationsNL from "translations/nl.json";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: translationsEN },
    no: { translation: translationsNO },
    sv: { translation: translationsSV },
    fi: { translation: translationsFI },
    da: { translation: translationsDA },
    nl: { translation: translationsNL },
  },
  lng: "en",
  fallbackLng: "en",
  interpolation: { escapeValue: false },
});
