import styled from "styled-components";
import axiosConfig from "services/api/axiosConfiig";
import { useRef, useState } from "react";
import { IoIosMail } from "react-icons/io";
import PrimaryButton from "components/shared/Buttons/PrimaryButton";

const Container = styled.div`
  margin-top: 144px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LoginForm = styled.form`
  background-color: #eeeeee;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`;

const InputContainer = styled.div`
  position: relative;
`;

const InputBox = styled.input`
  height: 40px;
  width: 275px;
  padding: 5px 5px 5px 30px;
  margin-bottom: 12px;
  font-size: 0.9rem;
  font-weight: 400;
  color: #000;
`;

const EmailIcon = styled(IoIosMail)`
  position: absolute;
  font-size: 1.1rem;
  color: #6a6f73;
  left: 7.5px;
  top: 17px;
`;

const ResetMessage = styled.p`
  width: 70vw;
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  line-height: 20px;
`;

const IntroText = styled.p`
  font-weight: 600;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #eee;
`;

const Index = () => {
  const emailInputRef = useRef();
  const [resetProcessed, setResetProcessed] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [warningMessage, setWarningMessage] = useState();

  const submitHandler = async (event) => {
    event.preventDefault();
    const enteredEmail = emailInputRef.current.value;

    try {
      setIsLoading(true);
      const { data } = await axiosConfig.put(`/users/password`, {
        email: enteredEmail,
      });
      // Success
      setIsLoading(false);
      setResetProcessed(true);
    } catch (err) {
      setWarningMessage("An unknown error occurred. Please try again.");
      setShowWarning(true);
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <IntroText>Request a link to change your password</IntroText>
      {!resetProcessed && (
        <LoginForm>
          <InputContainer>
            <EmailIcon />
            <InputBox placeholder="Email" required ref={emailInputRef} />
          </InputContainer>
          <PrimaryButton width="314px" fontSize="1rem" onClick={submitHandler}>
            {isLoading ? (
              <p>Requesting Password Reset...</p>
            ) : (
              <p>Request Password Reset</p>
            )}
          </PrimaryButton>
        </LoginForm>
      )}
      {resetProcessed && (
        <ResetMessage>
          You should soon receive an email allowing you to reset your password.
          Please make sure to check your spam and trash if you can't find the
          email.
        </ResetMessage>
      )}
    </Container>
  );
};

export default Index;
