import styled from "styled-components";
import img from "assets/images/curves-bg.png";
import Markets from "./Markets";
import { useTranslation } from "react-i18next";
import { WHITE } from "constants/index";

const BackgroundImage = styled.div`
  background-image: url(${img});
  background-repeat: "no-repeat";
  background-position: center top;
  background-size: cover;
  width: 100%;
  position: relative;
  height: 540px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;

  @media (max-width: 686px) {
    height: 850px;
  }
`;

const Intro = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-top: 68px;
  font-family: SpecsaversRegular;

  p {
    display: flex;
    margin-left: 30%;
    margin-top: 20px;
    margin-bottom: 10px;
    width: 40%;
    line-height: 25px;
  }
`;

const MarketTitle = styled.h2`
  position: absolute;
  top: 70px;
  color: ${WHITE};
  font-size: 1.8rem;
`;

const HomePage = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Intro>
        <h1>{t("home_title")}</h1>
        <p>{t("home_intro")}</p>
      </Intro>
      <BackgroundImage>
        <MarketTitle>Select a Specsavers market</MarketTitle>
        <Markets />
      </BackgroundImage>
    </>
  );
};

export default HomePage;
