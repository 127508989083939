import styled from "styled-components";

const StyledTimeline = styled.div`
  height: 40px;
  margin-left: -4px;
  background-color: #f2f2f2;
  min-width: 3500px;
  position: absolute;
  top: 0px;
  padding: 0px 0px 0px 20px;
  border-bottom: 1px solid #c4c4c4;
  display: flex;
  flex-direction: columns;
`;

const Minute = styled.div`
  height: 27px;
  padding: 13px 0px 0px 0px;
  width: 149.2px;
  border-left: 1px solid #c4c4c4;
  text-align: center;
  cursor: default;

  &:nth-child(1) {
    width: 99px;
    border-left: none;
    margin-left: 22.5px;
    margin-right: 22.5px;
  }
`;

const Timeline = () => {
  return (
    <StyledTimeline>
      <Minute>Minutes</Minute>
      {/** This will render x amount of minutes depending on the amount set */}
      {[...Array(120)].map((value, i) => (
        <Minute key={i}>{i}</Minute>
      ))}
    </StyledTimeline>
  );
};

export default Timeline;
