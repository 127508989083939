// Text sizes
export const TEXT_SMALL = "18px";
export const TEXT_MEDIUM = "20px";
export const TEXT_BIG = "22px";

// Colours
export const WHITE = "#FFFFFF";
export const BLACK = "#000000";
export const SPECSAVERS_GREEN = "#009b4f";
export const SPECSAVERS_DARK_GREEN = "#00693b";
export const LIGHT_GREY = "#F4F4F4";
export const GREY = "#EAE8E8";
export const NAV_GREY = "#EBEBEB";
export const MEDIUM_GREY = "#DDDDDD";
