import { useState, useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import styled from "styled-components";
import { WHITE, BLACK } from "constants/index";
import RowBackground from "components/DynamicMappingTool/common/RowBackground";
import RowContainer from "components/DynamicMappingTool/common/RowContainer";
import { useSelector, useDispatch } from "react-redux";
import { mapSettingsActions } from "redux/store/map-settings-slice";
import { dataActions } from "redux/store/data-slice";
import { backgroundHandler } from "utils/backgroundHandler";

const Row = styled.div`
  display: flex;
  align-content: center;
  width: 3000px;
  padding-left: 160px;
  margin-top: 12px;
  position: relative;
  top: -100px;
`;

const AddButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${BLACK};
  text-align: center;
  padding: 16px;
  text-decoration: none;
  height: 52px;
  font-size: 1.8rem;
  width: 336px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: ${WHITE};
  opacity: 0.75;
  border-radius: 5px;
  cursor: pointer;
`;

const B2BDifferences = () => {
  const lockMap = useSelector((state) => state.mapSettingsReducer.lockMap);
  const isBlockUpdated = useSelector(
    (state) => state.mapSettingsReducer.isBlockUpdated
  );
  const primaryMapLanguage = useSelector(
    (state) => state.mapSettingsReducer.primaryMapLanguage
  );
  const data = useSelector((state) => state.dataReducer.data);
  const headingData = useSelector((state) => state.dataReducer.data);
  const headingDataArr = data.row[0].column

  const b2bDifferencesData = data.row[8].column;

  let columnsFromData = {};

  for (var x = 0; x < b2bDifferencesData.length; x++) {
    columnsFromData[x] = { items: b2bDifferencesData[x].primary };
  }

  const GetBackgroundGradient = (index) => {
    const sectionId = data.row[4].column[index].section_id;

    const sectionIndex = headingDataArr.findIndex(function (getHeadingId) {
      return getHeadingId.id === sectionId;
    });

    const backgroundColour =
      headingData.row[0].column[sectionIndex].background_colour;
    return backgroundHandler(backgroundColour);
  };

  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });

      dispatch(
        dataActions.updateB2BDifferencesMultipleColumnOrder({
          sourceColumnId: source.droppableId,
          newSourceData: sourceItems,
          destinationColumnId: destination.droppableId,
          newDestinationData: destItems,
        })
      );
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
      dispatch(
        dataActions.updateB2BDifferencesSingleColumnOrder({
          columnId: source.droppableId,
          newData: copiedItems,
        })
      );
    }
  };

  const visibilityHandler = (itemCount) => {
    if (lockMap && itemCount === 0) {
      return "hidden";
    } else {
      return "visible";
    }
  };

  const [columns, setColumns] = useState(columnsFromData);

  useEffect(() => {
    if (isBlockUpdated === 'b2b') {
      setColumns(columnsFromData);
      dispatch(mapSettingsActions.setIsBlockUpdated(''));
    }
  }, [columnsFromData]);

  const dispatch = useDispatch();

  const blockDataHandler = (containerId, id) => {
    dispatch(dataActions.updateCurrentId(containerId));
    dispatch(mapSettingsActions.popupForm("b2b"));
    dispatch(dataActions.updateCurrentChildId(id));
    dispatch(mapSettingsActions.popupToggle());
  };

  const newBlockHandler = (containerId) => {
    dispatch(dataActions.updateCurrentId(containerId));
    dispatch(dataActions.createB2BDifferences());
    dispatch(mapSettingsActions.popupForm("b2b"));
    dispatch(mapSettingsActions.popupToggle());
  };

  const rowVisibilityData = useSelector(
    (state) => state.mapSettingsReducer.rowVisibilityData
  );

  const rowMarginHandler = () => {
    if (rowVisibilityData.row[8].visible) {
      return "-106px";
    } else {
      return "85px";
    }
  };

  return (
    <RowContainer height="auto" marginBottom={rowMarginHandler}>
      <RowBackground rowName="B2B Differences" />
      <Row>
        <div
          style={{ display: "flex", justifyContent: "center", height: "100%" }}
        >
          <DragDropContext
            onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
          >
            {Object.entries(columns).map(([columnId, column], index) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                  key={columnId}
                >
                  <div style={{ margin: 8 }}>
                    <Droppable droppableId={columnId} key={columnId}>
                      {(provided, snapshot) => {
                        return (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={{
                              background: snapshot.isDraggingOver
                                ? "gold"
                                : GetBackgroundGradient(index),
                              padding: 20,
                              width: 370,
                              marginLeft: 0,
                              borderRadius: 5,
                              visibility: visibilityHandler(
                                column.items.length
                              ),
                            }}
                          >
                            {column.items.map((item, index) => {
                              return (
                                <Draggable
                                  key={item.id}
                                  draggableId={item.id}
                                  index={index}
                                  isDragDisabled={lockMap}
                                >
                                  {(provided, snapshot) => {
                                    return (
                                      <div
                                        id="eventblock"
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={{
                                          userSelect: "none",
                                          padding: 16,
                                          margin: "0 0 8px 0",
                                          minHeight: "50px",
                                          backgroundColor: snapshot.isDragging
                                            ? "#EEE"
                                            : "#FFFFFF",
                                          color: "#000000",
                                          borderRadius: "5px",
                                          ...provided.draggableProps.style,
                                        }}
                                        {...(!lockMap && {
                                          onClick: () =>
                                            blockDataHandler(columnId, index),
                                        })}
                                      >
                                        {primaryMapLanguage
                                          ? item.primary_text
                                          : item.secondary_text}
                                      </div>
                                    );
                                  }}
                                </Draggable>
                              );
                            })}

                            {provided.placeholder}
                            {!lockMap && (
                              <AddButton
                                onClick={() => newBlockHandler(columnId)}
                              >
                                +
                              </AddButton>
                            )}
                          </div>
                        );
                      }}
                    </Droppable>
                  </div>
                </div>
              );
            })}
          </DragDropContext>
        </div>
      </Row>
    </RowContainer>
  );
};

export default B2BDifferences;
