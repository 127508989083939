import styled from "styled-components";
import { useSelector } from "react-redux";
import ResizableBlock from "components/DynamicMappingTool/common/Shapes/ResizableBlock";
import "react-resizable/css/styles.css";
import { backgroundHandler } from "utils/backgroundHandler";

const Container = styled.div`
  margin-left: 161px;
  width: 20000px;
  position: relative;
  top: -310px;
`;

const Index = () => {
  const data = useSelector((state) => state.dataReducer.data);
  const headingData = data.row[0].column;

  const primaryMapLanguage = useSelector(
    (state) => state.mapSettingsReducer.primaryMapLanguage
  );
  const BackgroundColourHandler = (sectionId) => {
    // Get section Index
    const sectionIndex = headingData.findIndex(function (getHeadingId) {
      return getHeadingId.id === sectionId;
    });

    const backgroundColour = data.row[0].column[sectionIndex].background_colour;
    return backgroundHandler(backgroundColour);
  };

  const BlockWidthHandler = (index) => {
    const totalTime =
      useSelector(
        (state) => state.dataReducer.data.row[5].column[index].total_time
      ) * 2.5;

    return totalTime;
  };

  const BlockTopHandler = (index) => {
    const top = index * +1 * 95 + "px";
    return top;
  };

  const moreLessHandler = (index) => {
    return data?.row[6].column[index].customer_time_preference;
  };

  const initialTimeHandler = (index) => {
    return data?.row[5].column[index].total_time;
  };

  const warningTimeHandler = (index) => {
    return data?.row[6].column[index].unsatisfactory;
  };

  return (
    <Container>
      {data.row[4].column.map((columnData, index) => (
        <ResizableBlock
          id="unscrollable"
          index={index}
          axis="x"
          className="box"
          backgroundColour={BackgroundColourHandler(columnData.section_id)}
          width={BlockWidthHandler(index)}
          top={BlockTopHandler(index)}
          text={
            primaryMapLanguage
              ? columnData.primary_text
              : columnData.secondary_text
          }
          warningTime={warningTimeHandler(index)}
          moreLess={moreLessHandler(index)}
          initialTime={initialTimeHandler(index)}
        ></ResizableBlock>
      ))}
    </Container>
  );
};

export default Index;
