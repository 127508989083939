import { useState, useEffect } from "react";
import styled from "styled-components";
import { WHITE } from "constants/index";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Hexagon from "components/DynamicMappingTool/common/Shapes/Hexagon";
import { useSelector, useDispatch } from "react-redux";
import { dataActions } from "redux/store/data-slice";
import { mapSettingsActions } from "redux/store/map-settings-slice";
import Row from "components/DynamicMappingTool/common/Row";
import RowBackground from "components/DynamicMappingTool/common/RowBackground";
import RowContainer from "components/DynamicMappingTool/common/RowContainer";

const PrioritiesList = styled.div`
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #333333;
  }
`;

const PrioritiesItem = styled.div`
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${WHITE};
  text-align: center;
  text-decoration: none;
  font-size: 0.8rem;
  width: 130px;
  height: 130px;
  margin-right: 12px;
`;

const HexagonPlaceholder = styled.div`
  width: 130px;
  height: 130px;
  transform: rotate(-30deg) skewX(30deg) scaleY(0.866);
`;

const AddHexagon = styled.div`
  overflow: hidden;
  background: transparent;
  width: 130px;
  height: 130px;
  transform: rotate(-30deg) skewX(30deg) scaleY(0.866);

  &:before {
    position: absolute;
    right: 6.7%;
    bottom: 0;
    left: 6.7%;
    top: 0;
    transform: scaleY(1.155) skewX(-30deg) rotate(30deg);
    background: #888888;
    background-color: rgba(136, 136, 136, 0.7);
    backdrop-filter: blur(150px);
    box-shadow: 0 0 5px wheat;
    content: "";
    opacity: 0.25;
  }
`;

const Text = styled.div`
  font-size: 0.8rem;
  position: absolute;
  cursor: default;
  max-width: 115px;
  color: ${WHITE};
  cursor: grab;
`;

const AddText = styled.div`
  color: ${WHITE};
  font-size: 1.8rem;
  position: absolute;
  cursor: grab;
`;

const ColleaguePriorities = () => {
  /** This section communicates with Redux */
  const primaryMapLanguage = useSelector(
    (state) => state.mapSettingsReducer.primaryMapLanguage
  );
  const data = useSelector((state) => state.dataReducer.data);
  const headingData = data.row[0].column;
  const lockMap = useSelector((state) => state.mapSettingsReducer.lockMap);
  localStorage.setItem(data, JSON.stringify(data));
  const dispatch = useDispatch();
  const prioritiesData = data?.row[3].column;
  const [events, updateEvents] = useState(prioritiesData);

  useEffect(() => {
    updateEvents(prioritiesData);
  }, [prioritiesData]);

  const blockDataHandler = (id) => {
    dispatch(mapSettingsActions.popupForm("priorities"));
    dispatch(dataActions.updateCurrentId(id));
    dispatch(mapSettingsActions.popupToggle());
    updateEvents(prioritiesData);
  };

  /** Executes when you drop an element after dragging it. */
  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(events);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    updateEvents(items);
    dispatch(dataActions.updateColleaguePrioritiesOrder({ newData: items }));
  };

  const BackgroundColourHandler = (index) => {
    let calcColumnId = Math.floor((index / 3));

    const sectionId = useSelector(
      (state) => state.dataReducer.data.row[4].column[calcColumnId].section_id
    );

    const sectionIndex = headingData.findIndex(function (getHeadingId) {
      return getHeadingId.id === sectionId;
    });

    let backgroundColour = useSelector(
      (state) =>
        state.dataReducer.data.row[0].column[sectionIndex].background_colour
    );

    return backgroundColour;
  };

  return (
    <RowContainer height="132px">
      <RowBackground rowName="Store Staff Priorities" top="24px" />
      <Row display="block" top="-80px">
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="events" direction="horizontal">
            {(provided) => (
              <PrioritiesList
                className="events"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {events.map(({ id, primary_text, secondary_text }, index) => {
                  return (
                    <Draggable
                      key={id}
                      draggableId={id}
                      index={index}
                      isDragDisabled={lockMap}
                    >
                      {(provided) => (
                        <div>
                          <PrioritiesItem
                            id="eventblock"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            {primary_text === "" ? (
                              <>
                                {lockMap ? (
                                  <HexagonPlaceholder />
                                ) : (
                                  <AddHexagon
                                    {...(!lockMap && {
                                      onClick: () => blockDataHandler(index),
                                    })}
                                  />
                                )}
                              </>
                            ) : (
                              <Hexagon
                                backgroundColour={BackgroundColourHandler(
                                  index
                                )}
                                {...(!lockMap && {
                                  onClick: () => blockDataHandler(index),
                                })}
                              />
                            )}

                            {primary_text === "" ? (
                              <AddText
                                {...(!lockMap && {
                                  onClick: () => blockDataHandler(index),
                                })}
                              >
                                {!lockMap && "+"}
                              </AddText>
                            ) : (
                              <Text
                                {...(!lockMap && {
                                  onClick: () => blockDataHandler(index),
                                })}
                              >
                                {primaryMapLanguage
                                  ? primary_text
                                  : secondary_text}
                              </Text>
                            )}
                          </PrioritiesItem>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </PrioritiesList>
            )}
          </Droppable>
        </DragDropContext>
      </Row>
    </RowContainer>
  );
};

export default ColleaguePriorities;
