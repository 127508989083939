import styled from "styled-components";
import { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { IoIosMail } from "react-icons/io";
import { RiLockPasswordFill } from "react-icons/ri";
import PrimaryButton from "components/shared/Buttons/PrimaryButton";
import axiosConfig from "services/api/axiosConfiig";

const Container = styled.div`
  margin-top: 144px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LoginForm = styled.form`
  background-color: #eeeeee;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`;

const InputContainer = styled.div`
  position: relative;
`;

const InputBox = styled.input`
  height: 40px;
  width: 275px;
  padding: 5px 5px 5px 30px;
  margin-bottom: 12px;
  font-size: 0.9rem;
  font-weight: 400;
  color: #000;
`;

const EmailIcon = styled(IoIosMail)`
  position: absolute;
  font-size: 1.1rem;
  color: #6a6f73;
  left: 7.5px;
  top: 17px;
`;

const PasswordIcon = styled(RiLockPasswordFill)`
  position: absolute;
  font-size: 1.1rem;
  color: #6a6f73;
  left: 7.5px;
  top: 17px;
`;

const WarningBox = styled.div`
  width: 270px;
  border: 1px #767676 solid;
  margin-bottom: 12px;
  background-color: #ff8282;
  padding: 20px;
  font-size: 0.9rem;
  text-align: center;
  line-height: 18px;
`;

const ResetMessage = styled.p`
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
`;

const IntroText = styled.p`
  font-weight: 600;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #eee;
`;

const Index = () => {
  const { token } = useParams();

  const axios = require("axios");
  const emailAdressRef = useRef();
  const newPasswordRef = useRef();
  const [resetProcessed, setResetProcessed] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [warningMessage, setWarningMessage] = useState();

  const submitHandler = async (event) => {
    event.preventDefault();
    const emailAddress = emailAdressRef.current.value;
    const newPassword = newPasswordRef.current.value;

    try {
      setIsLoading(true);
      const { data } = await axiosConfig.patch(`/users/password`, {
        email: emailAddress,
        password: newPassword,
        token: token,
      });
      // Success
      setIsLoading(false);
      setResetProcessed(true);
    } catch (err) {
      const errorMessage = err.response.data.message;
      if (errorMessage === "ResetPasswordInvalidToken") {
        setWarningMessage(
          "You have entered an incorrect email address, or are using an invalid token."
        );
        setShowWarning(true);
        setIsLoading(false);
      } else if (errorMessage === "WeakPassword") {
        setWarningMessage(
          "Password is too weak. Password must contain letters, numbers, a symbol and be 8 characters long."
        );
        setShowWarning(true);
        setIsLoading(false);
      } else {
        setWarningMessage("An unknown error occurred. Please try again.");
        setShowWarning(true);
        setIsLoading(false);
      }
    }
  };

  return (
    <Container>
      <IntroText>Reset Password</IntroText>
      {!resetProcessed && (
        <LoginForm>
          {showWarning && <WarningBox>{warningMessage}</WarningBox>}
          <InputContainer>
            <EmailIcon />
            <InputBox placeholder="Email" required ref={emailAdressRef} />
          </InputContainer>
          <InputContainer>
            <PasswordIcon />
            <InputBox
              placeholder="New Password"
              type="password"
              required
              ref={newPasswordRef}
            />
          </InputContainer>
          <PrimaryButton width="314px" fontSize="1rem" onClick={submitHandler}>
            {isLoading ? <p>Reseting Password...</p> : <p>Reset Password</p>}
          </PrimaryButton>
        </LoginForm>
      )}
      {resetProcessed && (
        <ResetMessage>
          Your password has been updated, you can now login.
        </ResetMessage>
      )}
    </Container>
  );
};

export default Index;
