import styled from "styled-components";
import { Link } from "react-router-dom";
import { FaUserPlus, FaUsers } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

const Container = styled.div`
  margin-left: 10vw;
  width: 80vw;
  height: auto;
  margin-top: 84px;
  margin-bottom: 40px;
`;

const Header = styled.div`
  width: 100%;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #d1d7dc;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 1.4rem;
  margin-bottom: 10px;
`;

const CreateUserIcon = styled(FaUserPlus)`
  margin-top: 28px;
  font-size: 4.8rem;
  color: #009b4f;
`;

const InvitesIcon = styled(MdEmail)`
  margin-top: 28px;
  font-size: 4.8rem;
  color: #009b4f;
  position: relative;
  top: 2px;
`;

const AllUsersIcon = styled(FaUsers)`
  margin-top: 28px;
  font-size: 4.8rem;
  color: #009b4f;
  position: relative;
  top: 2px;
`;

const AdminPrimaryButton = styled.button`
  width: 180px;
  padding: 5px;
  margin-top: 25px;
  background-color: #009b4f;
  height: 50px;
  border: none;
  border-radius: 3px;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  &:hover {
    background-color: #00693b;
  }
`;

const PanelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #d1d7dc;
  padding-bottom: 40px;
`;

const IconContainer = styled.div`
  display: flex;
  margin-top: 30px;
  margin-right: 20px;
  flex-direction: column;
  align-items: center;
  width: 220px;
  padding: 20px;
  background-color: #ddd;
  height: 220px;
  border: none;
  border-radius: 3px;
  color: white;
  font-size: 1rem;
`;

const index = () => {
  return (
    <Container>
      <Header>
        <Title>Admin Panel</Title>
      </Header>
      <PanelContainer>
        <IconContainer>
          <CreateUserIcon />
          <Link to="/admin/invite">
            <AdminPrimaryButton>Invite a new user</AdminPrimaryButton>
          </Link>
        </IconContainer>
        <IconContainer>
          <InvitesIcon />
          <Link to="/admin/pendinginvites">
            <AdminPrimaryButton>View pending invites</AdminPrimaryButton>
          </Link>
        </IconContainer>
      </PanelContainer>
      <PanelContainer>
        <IconContainer>
          <AllUsersIcon />
          <Link to="/admin/users">
            <AdminPrimaryButton>View all users</AdminPrimaryButton>
          </Link>
        </IconContainer>
      </PanelContainer>
    </Container>
  );
};

export default index;
