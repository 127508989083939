import { createGlobalStyle } from "styled-components";

/**
 * Some global styles are needed to resolve issues with margins, overflow etc.
 */
const GlobalStyle = createGlobalStyle`
* {
    margin: 0;
    padding: 0;
    font-family: Specsavers-Regular;
    letter-spacing: 0.8px;
    user-select: none;
}
html {
	overflow-y: scroll;
	height: 100%;
	-webkit-overflow-scrolling: touch;
}
body {
    margin-top: 100px;
    overflow-x:hidden;
    max-width: 100vw;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #ddd;
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #009b4f; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #00693b; 
}

//Overrides CSS in react-resize package node module folder
.react-resizable-handle {
  position: absolute;
  width: 8px;
  height: 92px;
  background-color: #000;
  opacity: 0.1;
  cursor: col-resize;
}
`;


export default GlobalStyle;
