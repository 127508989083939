import styled from "styled-components";
import { WHITE } from "constants/index";
import { FaRegTrashAlt } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { mapSettingsActions } from "redux/store/map-settings-slice";

const DeleteButton = styled.button`
  background-color: #e30613;
  margin-top: 2px;
  width: 35px;
  height: 35px;
  border: none;
  border-radius: 3px;
  color: ${WHITE};
  cursor: pointer;

  &:hover {
    background-color: maroon;
  }
`;

const StyledDeleteIcon = styled(FaRegTrashAlt)`
  font-size: 1.2rem;
`;

const Delete = ({form}) => {
  const dispatch = useDispatch();

  const openFormHandler = () => {
    dispatch(mapSettingsActions.popupForm(form));
    dispatch(mapSettingsActions.popupToggle());
  };

  return (
    <DeleteButton onClick={openFormHandler}>
      <StyledDeleteIcon />
    </DeleteButton>
  );
};

export default Delete;
