import { useState, useRef } from "react";
import styled from "styled-components";
import { dataActions } from "redux/store/data-slice";
import { mapSettingsActions } from "redux/store/map-settings-slice";
import { useSelector, useDispatch } from "react-redux";
import { FaRegSave } from "react-icons/fa";
import ColourPicker from "components/DynamicMappingTool/ColourPicker";
import Tooltip from "components/shared/Tooltip";
import PrimaryButton from "components/shared/Buttons/PrimaryButton";

const Container = styled.div`
  position: relative;
  top: -35px;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
`;

const FormContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const FormHeading = styled.h1`
  font-size: 1.5rem;
  margin-left: 27px;
`;

const Input = styled.input`
  padding: 10px;
  color: #000000;
  background: #ffffff;
  border: 0.5px solid #000000;
  border-radius: 3px;
  width: 300px;
  height: 40px;
  margin-bottom: 20px;
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 10px;
  font-size: 1.4em;
`;

const StyledLabel = styled.label``;

const Form = () => {
  const textRef = useRef();
  const hexRef = useRef();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.dataReducer.data);
  const currentId = useSelector((state) => state.dataReducer.currentId);
  const primaryText = data?.row[0].column[currentId].primary_text;
  const secondaryText = data?.row[0].column[currentId].secondary_text;
  const hex = data?.row[0].column[currentId].background_colour;
  const [primaryTextInput, setPrimaryTextInput] = useState(primaryText);
  const [secondaryTextInput, setSecondaryTextInput] = useState(secondaryText);
  const [hexInput, setHexInput] = useState(hex);
  const updateHex = (newHex) => {
    setHexInput(newHex);
  };
  const saveHandler = () => {
    let newHex = hexInput;
    if (hexInput === undefined) {
      newHex = hex;
    }

    dispatch(
      dataActions.updateHeading({
        updatedPrimaryText: primaryTextInput,
        updatedSecondaryText: secondaryTextInput,
        updatedHexCode: newHex,
      })
    );
    dispatch(mapSettingsActions.popupToggle());
  };

  return (
    <Container>
      <FormHeading>Heading</FormHeading>
      <FormContainer>
        <StyledLabel>English:</StyledLabel>
        <Input
          id="text"
          ref={textRef}
          value={primaryTextInput}
          autocomplete="off"
          onChange={(t) => setPrimaryTextInput(t.target.value)}
        />
        <StyledLabel>Market Language:</StyledLabel>
        <Input
          id="text"
          ref={textRef}
          value={secondaryTextInput}
          autocomplete="off"
          onChange={(t) => setSecondaryTextInput(t.target.value)}
        />
        <Tooltip text="Select a colour from the colour palette to change the colour of the entire stage!" />
        <StyledLabel>Column colour: </StyledLabel>

        <ColourPicker updateHex={updateHex} />
        <Input
          hidden
          id="hex"
          ref={hexRef}
          value={hexInput}
          autocomplete="off"
          onChange={(h) => setHexInput(h.target.value)}
        />
      </FormContainer>
      <PrimaryButton width="160px" fontSize="1.2rem" onClick={saveHandler}>
        Save <FaRegSave id="icon" style={{ verticalAlign: "middle" }} />
      </PrimaryButton>
    </Container>
  );
};

export default Form;
