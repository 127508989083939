import { useSelector } from "react-redux";
import ActivitiesContainer from "./ActivitiesContainer";
import RowContainer from "components/DynamicMappingTool/common/RowContainer";
import RowBackground from "components/DynamicMappingTool/common/RowBackground";
import Row from "components/DynamicMappingTool/common/Row";

const Index = () => {
  const data = useSelector((state) => state.dataReducer.data);

  return (
    <RowContainer height="auto" marginBottom="-78px">
      <RowBackground rowName="Store Activities" />
      <Row display="flex" top="-80px">
        {data.row[5].column.map((activityData, i) => (
          <ActivitiesContainer data={activityData} index={i} />
        ))}
      </Row>
    </RowContainer>
  );
};

export default Index;
