import styled from "styled-components";
import PrimaryButton from "components/shared/Buttons/PrimaryButton";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const Input = styled.input`
  padding: 10px;
  color: #000000;
  background: #ffffff;
  border: 0.5px solid #000000;
  border-radius: 3px;
  width: 100%;
  height: 40px;
  margin-bottom: 20px;
  margin-top: 30px;
  font-size: 1.4em;
`;

const ShareForm = () => {
  const mapId = useSelector((state) => state.mapSettingsReducer.currentMapId);

  let { marketTitle } = useParams();
  let marketTitleLower = marketTitle.toLowerCase();

  const mapURL = `https://specsavers-ui.summithosting.co.uk/map/${marketTitleLower}/${mapId}`;

  const copyHandler = () => {
    navigator.clipboard.writeText(mapURL);
  };

  return (
    <div>
      <Input id="Popuptext" value={mapURL} disabled></Input>
      <PrimaryButton width="160px" fontSize="1.2rem" onClick={copyHandler}>
        Copy
      </PrimaryButton>
    </div>
  );
};

export default ShareForm;
