import React from "react";
import moment from "moment";
import styled from "styled-components";
import { WHITE } from "constants/index";
import { IoStopwatch } from "react-icons/io5";
import { useSelector, useDispatch } from "react-redux";
import { mapSettingsActions } from "redux/store/map-settings-slice";
import { dataActions } from "redux/store/data-slice";

const StyledTimeBlockWarning = styled.div`
  background: linear-gradient(144deg, #e30613 0%, #f71a27 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${WHITE};
  text-align: center;
  padding: 16px;
  text-decoration: none;
  height: 55px;
  font-size: 1.2rem;
  width: 225px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
`;

const StyledTimeBlockApproved = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  padding: 16px;
  text-decoration: none;
  height: 55px;
  font-size: 1.2rem;
  width: 225px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
`;

const PreferenceContainer = styled.div`
  height: 55px;
  font-size: 1.2rem;
  width: 285px;
  border-top: 1px ${WHITE} solid;
  padding-top: 25px;
`;

const BoldWord = styled.span`
  font-weight: bold;
`;

const ClockIcon = styled(IoStopwatch)`
  font-size: 1.8em;
  margin-bottom: 10px;
  color: #fff;
`;

const TimeBlock = ({ containerId }) => {
  const data = useSelector((state) => state.dataReducer.data);
  const lockMap = useSelector((state) => state.mapSettingsReducer.lockMap);
  const dispatch = useDispatch();
  const warningTime = data?.row[6].column[containerId].unsatisfactory;
  const customerPreference =
    data?.row[6].column[containerId].customer_time_preference;

  const totalTime = data.row[5].column[containerId].total_time;
  const timeConverter = (seconds) => {
    let timeString;
    // Changes minutes' to 'minute' if 1 minute
    if (totalTime < 59.9) {
      timeString = moment.utc(totalTime * 1000).format("s [seconds]");
    } else if (totalTime > 59.9) {
      timeString = moment
        .utc(totalTime * 1000)
        .format("m [minute] s [seconds]");
    }
    if (totalTime > 119.9) {
      timeString = moment
        .utc(totalTime * 1000)
        .format("m [minutes] s [seconds]");
    }

    // Makes 'seconds' 'second' if 1 second
    if (timeString.includes(" 1 sec")) {
      timeString = timeString.substring(0, timeString.length - 1);
    }
    return timeString;
  };
  const moreLess = data?.row[6].column[containerId].customer_time_preference;
  const initialTime = data?.row[5].column[containerId].total_time;
  const initialTimeHandler = () => {
    if (moreLess === "less") {
      if (initialTime > warningTime) {
        return true;
      } else {
        return false;
      }
    }

    if (moreLess === "more") {
      if (initialTime < warningTime) {
        return true;
      } else {
        return false;
      }
    }
  };

  const changeTimeHandler = () => {
    dispatch(dataActions.updateCurrentId(containerId));
    dispatch(mapSettingsActions.popupForm("time"));
    dispatch(mapSettingsActions.popupToggle());
  };

  return (
    <>
      {initialTimeHandler() ? (
        <StyledTimeBlockWarning
          {...(!lockMap && { onClick: changeTimeHandler })}
        >
          <ClockIcon />
          {timeConverter(totalTime)}
        </StyledTimeBlockWarning>
      ) : (
        <StyledTimeBlockApproved
          {...(!lockMap && { onClick: changeTimeHandler })}
        >
          <ClockIcon />
          {timeConverter(totalTime)}
        </StyledTimeBlockApproved>
      )}
      <PreferenceContainer {...(!lockMap && { onClick: changeTimeHandler })}>
        Customer prefers <BoldWord>{customerPreference}</BoldWord> time
      </PreferenceContainer>
    </>
  );
};

export default TimeBlock;
