import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { mapSettingsActions } from "redux/store/map-settings-slice";
import { dataActions } from "redux/store/data-slice";
import styled from "styled-components";
import { WHITE } from "constants/index";
import { FaUserEdit, FaRobot } from "react-icons/fa";

const Container = styled.div`
  width: 350px;
  display: flex;
  flex-direction: row;
  margin-left: 40px;
  margin-right: 35px;
  position: relative;
  top: 347px;
  margin-top: 50px;
  margin-bottom: -50px;
`;

const NumberInput = styled.div`
  position: absolute;
  top: -45px;
  height: 45px;
  width: 353px;
  z-index: 1;
  border: 1px #3ab54a solid;
  background-color: ${WHITE};
  color: #3ab54a;
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 1.8rem;
  border-radius: 5px 5px 0 0;
  cursor: default;
`;

const LogicButtonActive = styled.div`
  background-color: #3ab54a;
  height: 33px;
  width: 200px;
  border-radius: 0 0 5px 5px;
  color: ${WHITE};
  padding: 10px;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -6px;
  z-index: 2;
`;

const ManualButton = styled.div`
  background-color: #3ab54a;
  height: 33px;
  min-width: 335px;
  border-radius: 0 0 5px 5px;
  color: ${WHITE};
  padding: 10px;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`;

const LogicButton = styled.div`
  border: 1px solid #3ab54a;
  width: 200px;
  height: 31px;
  border-radius: 0 0 5px 5px;
  background-color: #eee;
  color: #999999;
  padding: 10px;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -5px;
  z-index: 2;
  cursor: pointer;
`;

const AutoIcon = styled(FaRobot)`
  font-size: 2em;
  margin-right: 10px;
`;

const ManualIcon = styled(FaUserEdit)`
  font-size: 2em;
  margin-right: 10px;
`;

const PlusButton = styled.div`
  height: 25px;
  width: 25px;
  border-radius: 5px;
  background-color: #3ab54a;
  z-index: 2;
  font-size: 0.8em;
  line-height: 25px;
  color: ${WHITE};
  cursor: pointer;
`;

const MinusButton = styled.div`
  height: 25px;
  width: 25px;
  border-radius: 5px;
  background-color: #3ab54a;
  z-index: 2;
  font-size: 0.8em;
  line-height: 25px;
  color: ${WHITE};
  cursor: pointer;
`;

const SatisfactionToggleButton = ({
  index,
  autoValue,
  manualValue,
  customColumn,
}) => {
  const dispatch = useDispatch();

  const data = useSelector((state) => state.dataReducer.data);
  const lockMap = useSelector((state) => state.mapSettingsReducer.lockMap);

  const valueType = data.row[6].column[index].type;

  let isManual;
  let value;
  if (valueType === "auto") {
    isManual = false;
    value = autoValue;
  } else {
    isManual = true;
    value = manualValue;
  }

  const [manualToggle, setManualToggle] = useState(isManual);
  const [satisfactionValue, setSatisfactionValue] = useState(value);

  const buttonToggle = () => {
    if (manualToggle === false) {
      setSatisfactionValue(autoValue);
      dispatch(dataActions.updateCurrentId(index));
      dispatch(dataActions.updateSatisfactionColumnType("manual"));
    } else {
      setSatisfactionValue(manualValue);
      dispatch(dataActions.updateCurrentId(index));
      dispatch(dataActions.updateSatisfactionColumnType("auto"));
    }

    setManualToggle(!manualToggle);
  };

  const plusHandler = () => {
    if (satisfactionValue < 3) {
      setSatisfactionValue(satisfactionValue + 1);
      dispatch(dataActions.updateCurrentId(index));
      //Need to minus one to reflect on screen
      dispatch(
        dataActions.updateManualSatisfactionValue(satisfactionValue + 1)
      );
    }
  };

  const minusHandler = () => {
    if (satisfactionValue > -3) {
      setSatisfactionValue(satisfactionValue - 1);
      dispatch(dataActions.updateCurrentId(index));
      //Need to minus one to reflect on screen
      dispatch(
        dataActions.updateManualSatisfactionValue(satisfactionValue - 1)
      );
    }
  };

  const scoreToTextSwitch = (score) => {
    switch (score) {
      case -3:
        return "Very Bad";
      case -2:
        return "Bad";
      case -1:
        return "Unsatisfactory";
      case 0:
        return "Neutral";
      case 1:
        return "Satisfactory";
      case 2:
        return "Good";
      case 3:
        return "Very Good";

      default:
        return "Very Bad";
    }
  };

  return (
    <Container>
      {!manualToggle ? (
        <>
          <LogicButtonActive>
            <AutoIcon /> Auto ({autoValue})
          </LogicButtonActive>
          <LogicButton {...(!lockMap && { onClick: buttonToggle })}>
            <ManualIcon /> Manual ({manualValue})
          </LogicButton>
          <NumberInput>{scoreToTextSwitch(autoValue)}</NumberInput>
        </>
      ) : (
        <>
          {!customColumn && (
            <LogicButton {...(!lockMap && { onClick: buttonToggle })}>
              <AutoIcon /> Auto ({autoValue})
            </LogicButton>
          )}
          {!customColumn ? (
            <LogicButtonActive>
              <ManualIcon /> Manual ({manualValue})
            </LogicButtonActive>
          ) : (
            <ManualButton>
              <ManualIcon /> Manual ({manualValue})
            </ManualButton>
          )}
          <NumberInput>
            {" "}
            {!lockMap && <MinusButton onClick={minusHandler}>-</MinusButton>}
            {scoreToTextSwitch(manualValue)}
            {!lockMap && <PlusButton onClick={plusHandler}>+</PlusButton>}
          </NumberInput>
        </>
      )}
    </Container>
  );
};

export default SatisfactionToggleButton;
