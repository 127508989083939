import { useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import { ColorResult, GithubPicker } from "react-color";
import { getColorPalette } from "./Colours.js";

const Container = styled.div`
  position: relative;
  left: 70px;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Index = ({ updateHex }) => {
  const [color, setColor] = useState("#FFFFFF");
  const [palette, setPalette] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const { rgb } = color || {};
  const updateColor = useCallback((color: ColorResult) => setColor(color), []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const colors = await getColorPalette();
        setPalette(colors);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    updateHex(color.hex);
  });

  return (
    <Container>
      {isLoading ? (
        <span>Loading Colours...</span>
      ) : palette.length > 0 ? (
        <GithubPicker
          triangle="hide"
          colors={palette}
          width="212px"
          color={rgb}
          onChange={updateColor}
        />
      ) : (
        <span>Unable to load</span>
      )}
    </Container>
  );
};

export default Index;
