import styled from "styled-components";
import { useLocation } from "react-router-dom";

import { WHITE, NAV_GREY } from "constants/index";
import RedSummitLogo from "assets/images/summit-red.png";

const FooterBar = styled.div`
  width: 100vw;
  height: 59px;
  background-color: ${WHITE};
  bottom: 0;
  display: flex;
  align-items: center;
  border-top: 1px ${NAV_GREY} solid;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
`;

const SummitBranding = styled.div`
  position: absolute;
  right: 20px;
  display: flex;
  align-items: center;

  img {
    margin-right: 18px;
  }
`;

const SpecSaversBranding = styled.div`
  left: 0;
  display: flex;
  align-items: center;
  margin-left: 20px;
`;

const Footer = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const currentYear = new Date().getFullYear();

  let showFooter = true;

  if (pathname.substring(0, 4) === "/map") {
    showFooter = false;
  }

  return (
    <>
      {showFooter && (
        <FooterBar>
          <SpecSaversBranding>Specsavers {currentYear} ©</SpecSaversBranding>
          <SummitBranding>
            <img src={RedSummitLogo} alt="Red Summit Logo" />
            Summit | Changemakers in retail
          </SummitBranding>
        </FooterBar>
      )}
    </>
  );
};

export default Footer;
