import styled from "styled-components";
import { WHITE } from "constants/index";
import { useSelector } from "react-redux";
import ToolBarButton from "../ToolBarButton";
import MiddleText from "../MiddleText";

const Container = styled.div`
  width: 98%;
  color: ${WHITE};
  font-size: 1.5rem;
  margin-top: -2px;
  margin-left: -12px;
  display: flex;
  flex-direction: row;
`;

const LeftSection = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 14.5vw;
`;

const RightSection = styled.div`
  display: flex;
  flex-direction: row;
`;

const Index = () => {
  const showMap = useSelector((state) => state.mapSettingsReducer.showMap);

  return (
    <Container>
      <LeftSection>
        <ToolBarButton icon="globe" buttonText="Market" showArrow={true} />
        <ToolBarButton icon="product" buttonText="Product" showArrow={true} />
        <ToolBarButton icon="customer" buttonText="Customer" showArrow={true} />
        <ToolBarButton icon="language" buttonText="Language" showArrow={true} />
      </LeftSection>
      <MiddleText />

      <RightSection>
        <ToolBarButton icon="rows" buttonText="Rows" showArrow={true} />
        <ToolBarButton icon="download" buttonText="Download" showArrow={true} />
        <ToolBarButton icon="share" buttonText="Share" />
        {showMap && <ToolBarButton icon="gantt" buttonText="Gantt View" />}
        {!showMap && <ToolBarButton icon="map" buttonText="Map View" />}
      </RightSection>
    </Container>
  );
};

export default Index;
