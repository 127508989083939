import { useSelector } from "react-redux";
import Row from "components/DynamicMappingTool/common/Row";
import RowBackground from "components/DynamicMappingTool/common/RowBackground";
import RowContainer from "components/DynamicMappingTool/common/RowContainer";
import JourneyBlock from "components/DynamicMappingTool/common/Shapes/JourneyBlock";
import BookingIllustration from "assets/images/stage_icons/ill_booking.png";
import CheckInIllustration from "assets/images/stage_icons/ill_check_in.png";
import PreTestIllustration from "assets/images/stage_icons/ill_pre_test.png";
import SightTestIllustration from "assets/images/stage_icons/ill_exam.png";
import ThreeWayIllustration from "assets/images/stage_icons/ill_three_way.png";
import ProductSelectioIllustration from "assets/images/stage_icons/ill_product_selection.png";
import PurchaseIllustration from "assets/images/stage_icons/ill_purchase.png";
import CollectIllustration from "assets/images/stage_icons/ill_collection.png";
import { backgroundHandler } from "utils/backgroundHandler";
import RowButtons from "./RowButtons";

const JourneyStage = () => {
  const IllustrationSwitch = (illustration) => {
    switch (illustration) {
      case 0:
        return <></>;
      case 1:
        return (
          <img src={BookingIllustration} alt="Illustration" height="150" />
        );
      case 2:
        return (
          <img src={CheckInIllustration} alt="Illustration" height="150" />
        );
      case 3:
        return (
          <img src={PreTestIllustration} alt="Illustration" height="150" />
        );
      case 4:
        return (
          <img src={SightTestIllustration} alt="Illustration" height="150" />
        );
      case 5:
        return (
          <img src={ThreeWayIllustration} alt="Illustration" height="150" />
        );
      case 6:
        return (
          <img
            src={ProductSelectioIllustration}
            alt="Illustration"
            height="150"
          />
        );
      case 7:
        return (
          <img src={PurchaseIllustration} alt="Illustration" height="150" />
        );
      case 8:
        return (
          <img src={CollectIllustration} alt="Illustration" height="150" />
        );
      default:
        return (
          <img src={BookingIllustration} alt="Illustration" height="150" />
        );
    }
  };

  const data = useSelector((state) => state.dataReducer.data);
  const primaryMapLanguage = useSelector(
    (state) => state.mapSettingsReducer.primaryMapLanguage
  );
  const lockMap = useSelector((state) => state.mapSettingsReducer.lockMap);
  const headingData = data.row[0].column;

  const GetBackgroundGradient = (sectionId) => {
    const sectionIndex = headingData.findIndex(function (getHeadingId) {
      return getHeadingId.id === sectionId;
    });

    const backgroundColour = useSelector(
      (state) =>
        state.dataReducer.data.row[0].column[sectionIndex].background_colour
    );

    return backgroundHandler(backgroundColour);
  };

  const widthHandler = (columns) => {
    const blockWidth = columns * 424;
    return blockWidth + "px";
  };

  return (
    <RowContainer height="158px">

      <RowBackground rowName="Journey Stage" />
      {!lockMap && <RowButtons form="createDeleteStage" />}

      <Row display="flex" top="-80px">
        {data.row[1].column.map((columnData, i) => (
          <JourneyBlock
            key={columnData.id}
            index={i}
            row="stage"
            text={
              primaryMapLanguage
                ? columnData.primary_text
                : columnData.secondary_text
            }
            backgroundColour={GetBackgroundGradient(columnData.section_id)}
            columnWidth={widthHandler(columnData.columns)}
            height="125px"
            minWidth="392px"
            sectionId={columnData.section_id}
          >
            {IllustrationSwitch(columnData.illustration)}
          </JourneyBlock>
        ))}
      </Row>
    </RowContainer>
  );
};

export default JourneyStage;
