import styled from "styled-components";
import MarketButton from "./MarketButton";
import NorwayPin from "assets/images/market_icons/icon_norway.png";
import SwedenPin from "assets/images/market_icons/icon_sweden.png";
import FinlandPin from "assets/images/market_icons/icon_finland.png";
import DenmarkPin from "assets/images/market_icons/icon_denmark.png";
import NetherlandsPin from "assets/images/market_icons/icon_netherlands.png";
import AllPin from "assets/images/market_icons/icon_all_markets.png";

const Container = styled.div`
  width: 1500px;
  height: 300px;
  display: flex;
  justify-content: space-between;
  padding: 10px 50px 10px 50px;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  top: -80px;
  left: -20px;
  a {
    text-decoration: none;
    color: #000000;
  }

  @media (max-width: 1550px) {
    width: 600px;
    top: -30px;
    left: 0px;
  }
`;

const Markets = () => {
  return (
    <>
      <Container>
        <MarketButton
          market="Norway"
          marketPin={NorwayPin}
          link="norway"
          disabled={false}
        />
        <MarketButton
          market="Sweden"
          marketPin={SwedenPin}
          link="sweden"
          disabled={false}
        />
        <MarketButton
          market="Finland"
          marketPin={FinlandPin}
          link="finland"
          disabled={false}
        />
        <MarketButton
          market="Denmark"
          marketPin={DenmarkPin}
          link="denmark"
          disabled={false}
        />
        <MarketButton
          market="Netherlands"
          marketPin={NetherlandsPin}
          link="netherlands"
          disabled={false}
        />
        <MarketButton
          market="All Markets"
          marketPin={AllPin}
          link="allmarkets"
          disabled={false}
        />
      </Container>
    </>
  );
};

export default Markets;
