import styled from "styled-components";
import { useRef, useState, useEffect } from "react";
import axiosConfig from "services/api/axiosConfiig";
import { FaHome } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import PrimaryButton from "components/shared/Buttons/PrimaryButton";
import MapIcon from "assets/images/icon_map.png";
import BeatLoader from "react-spinners/BeatLoader";
import GridLoader from "react-spinners/GridLoader";
import { SPECSAVERS_GREEN } from "constants/index";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { mapSettingsActions } from "redux/store/map-settings-slice";
import { dataActions } from "redux/store/data-slice";

const Container = styled.div`
  margin-left: 10vw;
  width: 80vw;
  height: auto;
  margin-top: 84px;
  margin-bottom: 40px;
`;

const Header = styled.div`
  width: 100%;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #d1d7dc;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 1.4rem;
  margin-bottom: 10px;
`;

const AnimationContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #d1d7dc;
`;

const Badge = styled.div`
  width: 125px;
  height: 125px;
  border-radius: 100px;
  background-color: #009b4f;
  font-size: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  margin-bottom: 20px;
`;

const OriginalMapContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 23px;
  padding-bottom: 13px;
`;

const NewMapContainer = styled.div`
  width: 50%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 23px;
  padding-bottom: 13px;
  border-left: 1px solid #d1d7dc;
`;

const DetailsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #d1d7dc;
  padding-bottom: 20px;
  margin-top: 19px;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
`;

const InputBox = styled.input`
  height: 40px;
  width: 64%;
  padding: 5px 5px 5px 15px;
  margin-top: 6px;
  margin-bottom: 12px;
  font-size: 0.9rem;
  font-weight: 400;
  color: #000;
`;

const Label = styled.label`
  width: 66%;
`;

const UpdateMessage = styled.p`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
`;

const HomeIcon = styled(FaHome)`
  margin-top: 12px;
  font-size: 1.6rem;
  color: #009b4f;
  cursor: pointer;

  &:hover {
    color: #00693b;
  }
`;

const WarningBox = styled.div`
  width: 270px;
  border: 1px #767676 solid;
  margin-bottom: 12px;
  background-color: #ff8282;
  padding: 20px;
  font-size: 0.9rem;
  text-align: center;
  line-height: 18px;
`;

const StyledImage = styled.img`
  max-height: 200px;
  max-width: 200px;
  border: 2px white solid;
  margin-bottom: 5px;
  border-radius: 3px;
  margin-left: 15px;
  margin-right: 15px;
`;

const LoadingDataContainer = styled.div`
  width: 80vw;
  position: relative;
  height: 400px;
  top: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 2;
`;

const LoadingText = styled.p`
  margin-top: 25px;
  font-size: 2rem;
  font-weight: bold;
  color: ${SPECSAVERS_GREEN};
`;

const SelectBox = styled.select`
  height: 54px;
  width: 67%;
  padding: 5px 5px 5px 15px;
  margin-top: 6px;
  margin-bottom: 12px;
  font-size: 0.9rem;
  font-weight: 400;
  color: #000;
`;

const Index = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  let { marketTitle, mapId } = useParams();
  let marketTitleLower = marketTitle.toLowerCase();

  const [loadingMessage, setLoadingMessage] = useState("Loading Map Data...");
  const [loadingMapData, setLoadingMapData] = useState(false);
  const accessToken = localStorage.getItem("token");

  const data = useSelector((state) => state.dataReducer.data);
  const mapDataStringify = JSON.stringify(data);

  const originalMapMarket = useSelector(
    (state) => state.mapSettingsReducer.mapMarket
  );
  const originalMapProductType = useSelector(
    (state) => state.mapSettingsReducer.mapProductType
  );
  const originalMapCustomerType = useSelector(
    (state) => state.mapSettingsReducer.mapCustomerType
  );
  const originalMapType = useSelector(
    (state) => state.mapSettingsReducer.mapType
  );
  const newMapOwner = useSelector((state) => state.authReducer.userId);

  const marketIDSwitch = (marketTitleLower) => {
    switch (marketTitleLower) {
      case "norway":
        return 1;
      case "sweden":
        return 2;
      case "finland":
        return 3;
      case "denmark":
        return 4;
      case "netherlands":
        return 5;

      default:
        return 1;
    }
  };

  const marketID = marketIDSwitch(marketTitleLower);

  const originalMapName = data.header[0].map_name;
  let isOriginalMap = data.header[0].original;
  let originalMapOwner;
  if (isOriginalMap) {
    originalMapOwner = "SpecSavers";
  } else {
    originalMapOwner = data.header[0].map_owner[0];
  }

  const userId = useSelector((state) => state.mapSettingsReducer.data);

  // From settings page - use or remove
  const newMapNameRef = useRef();
  const newMapMarketRef = useRef();
  const newMapProductTypeRef = useRef();
  const newMapCustomerTypeRef = useRef();
  const newMapTypeRef = useRef();

  const [newMapId, setNewMapId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [warningMessage, setWarningMessage] = useState();
  const [passwordUpdated, setPasswordUpdated] = useState(false);

  const getMapData = async () => {
    setLoadingMapData(true);
    const config = {
      method: "get",
      headers: {
        Authorization: accessToken,
      },
    };

    await axiosConfig(`/markets/${marketID}/maps/${mapId}`, config)
      .then(function (response) {
        dispatch(dataActions.setMapData(JSON.parse(response.data.data)));
        dispatch(mapSettingsActions.setMapMarket(response.data.market));
        dispatch(
          mapSettingsActions.setMapProductType(response.data.productType)
        );
        dispatch(
          mapSettingsActions.setMapCustomerType(response.data.customerType)
        );
        dispatch(
          mapSettingsActions.setMapType(response.data.mapType)
        );
        setLoadingMapData(true);
      })
      .catch(function (error) {
        console.log(error);
        // if (error.response.status === 404) {
        //   setLoadingMessage("This map doesn't exist, please try again.");
        // } else {
        //   setLoadingMessage("An unknown error occurred");
        //   console.log(error);
        // }
      });
  };

  useEffect(() => {
    setLoadingMapData(true);
    getMapData();
  }, []);

  const duplicateMapHandler = async (event) => {
    event.preventDefault();

    const accessToken = localStorage.getItem("token");
    const newMapData = data;
    const newMapName = newMapNameRef.current.value;
    const newMapMarket = newMapMarketRef.current.value;
    const newMapProductType = newMapProductTypeRef.current.value;
    const newMapCustomerType = newMapCustomerTypeRef.current.value;
    const newMapType = newMapTypeRef.current.value;

    try {
      setIsLoading(true);
      const { apiData } = axiosConfig
        .post(
          `/markets/${marketID}/maps`,
          {
            productType: newMapProductType,
            customerType: newMapCustomerType,
            mapType: newMapType,
            data: mapDataStringify,
            name: newMapName,
          },
          {
            headers: {
              Authorization: accessToken,
            },
          }
        )
        .then((response) => {
          // Success
          setNewMapId(response.data.id);
          history.replace(`/map/${marketTitleLower}/${response.data.id}`);
        });
    } catch (err) {
      // const errorMessage = err.response.data.message;
      const errorMessage = err;
      console.log(errorMessage);
    }
  };

  const goHomeHandler = () => {
    history.replace("/");
  };

  return (
    <Container>
      <Header>
        <Title>Duplicate Map</Title>Duplicate a map to to make it your own and
        start making changes
      </Header>
      {!loadingMapData ? (
        <LoadingDataContainer>
          <GridLoader color={SPECSAVERS_GREEN} />
          <LoadingText>{loadingMessage}</LoadingText>
        </LoadingDataContainer>
      ) : (
        <>
          <AnimationContainer>
            <StyledImage src={MapIcon} alt="Map Icon" />
            <BeatLoader color={SPECSAVERS_GREEN} />
            <StyledImage src={MapIcon} alt="Map Icon" />
          </AnimationContainer>
          <DetailsContainer>
            <OriginalMapContainer>
              <Label>Original Map ID:</Label>
              <InputBox value={mapId} disabled></InputBox>
              <Label>Original Map Name:</Label>
              <InputBox value={originalMapName} disabled></InputBox>
              <Label>Original Map Owner:</Label>
              <InputBox value={originalMapOwner} disabled></InputBox>
              <Label>Original Map Market:</Label>
              <InputBox value={originalMapMarket} disabled></InputBox>
              <Label>Original Map Product Type:</Label>
              <InputBox value={originalMapProductType} disabled></InputBox>
              <Label>Original Map Customer Type:</Label>
              <InputBox value={originalMapCustomerType} disabled></InputBox>
              <Label>Original Map Visibility Type:</Label>
              <InputBox value={originalMapType} disabled></InputBox>
            </OriginalMapContainer>
            <NewMapContainer>
              {showWarning && <WarningBox>{warningMessage}</WarningBox>}
              <Label>New Map ID:</Label>
              <InputBox value="-" disabled></InputBox>
              <Label>New Map Name:</Label>
              <InputBox
                placeholder="Enter a name for your map"
                ref={newMapNameRef}
              ></InputBox>
              <Label>New Map Owner:</Label>
              <InputBox value={newMapOwner} disabled></InputBox>
              <Label>New Map Market:</Label>
              <SelectBox ref={newMapMarketRef}>
                <option value="norway">Norway</option>
                <option value="sweden">Sweden</option>
                <option value="finland">Finland</option>
                <option value="denmark">Denmark</option>
                <option value="netherlands">Netherlands</option>
              </SelectBox>
              <Label>New Map Product Type:</Label>
              <SelectBox ref={newMapProductTypeRef}>
                <option value="Glasses">Glasses</option>
                <option value="Lenses">Contact Lenses</option>
                <option value="Audiology">Audiology</option>
              </SelectBox>
              <Label>New Map Customer Type:</Label>
              <SelectBox ref={newMapCustomerTypeRef}>
                <option value="New">New</option>
                <option value="Existing">Existing</option>
                {/* <option value="nopurchase">No Purchase</option> */}
              </SelectBox>
              <Label>New Map Visibility Type:</Label>
              <SelectBox ref={newMapTypeRef}>
                <option value="Public">Public</option>
                <option value="Private">Private</option>
              </SelectBox>
            </NewMapContainer>
          </DetailsContainer>
          <ButtonContainer>
            {passwordUpdated ? (
              <UpdateMessage>
                Password Updated
                <HomeIcon onClick={goHomeHandler} />
              </UpdateMessage>
            ) : (
              <PrimaryButton
                width="314px"
                fontSize="1rem"
                onClick={duplicateMapHandler}
              >
                {isLoading ? <p>Duplicating Map...</p> : <p>Duplicate Map</p>}
              </PrimaryButton>
            )}
          </ButtonContainer>
        </>
      )}
    </Container>
  );
};

export default Index;
