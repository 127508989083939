import styled from "styled-components";
import AddButton from "./Add";
import DeleteButton from "./Delete";

const RowButtons = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100px;
  height: 30px;
  position: absolute;
  left: 30px;
  z-index 3;
`;

const Index = ({form}) => {

  let addForm
  let deleteForm
  if(form === "createDeleteHeading") {
    addForm = "addheading"
    deleteForm = "deleteheading"
  } else if(form === "createDeleteStage") {
    addForm = "addstage"
    deleteForm = "deletestage"
  } else if(form === "createDeleteSubStage") {
    addForm = "addsubstage"
    deleteForm = "deletesubstage"
  }


  return (
    <RowButtons>
      <AddButton form={addForm} />
      <DeleteButton form={deleteForm} />
    </RowButtons>
  );
};

export default Index;
