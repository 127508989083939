import styled from "styled-components";
import { string } from "prop-types";
import { useSelector } from "react-redux";

const GreyLine = styled.div`
  background-color: #eff0f2;
  min-width: 3000px;
  max-width: ${(props) => props.width};
  height: 100px;
  position: relative;
  top: ${(props) => props.top};
  height: 80px;
  text-align: center;
`;

const RowTitle = styled.h1`
  display: flex;
  justify-content: center;
  font-size: 1.2em;
  font-weight: 500;
  margin-left: 7px;
  padding-top: 20px;
  width: 145px;
  text-align: center;
  line-height: 24px;
`;

const RowBackground = ({ rowName, top }) => {
  const data = useSelector((state) => state.dataReducer.data);
  const rowCount = data.row[4].column.length
  const rowWidth = rowCount * 473 +'px'

  return (
    <GreyLine top={top} width={rowWidth}>
      <RowTitle> {rowName}</RowTitle>
    </GreyLine>
  );
};

RowBackground.propTypes = {
  rowName: string.isRequired,
};

export default RowBackground;
