import styled from "styled-components";
import { useSelector } from "react-redux";

const StyledRow = styled.div`
  display: ${(props) => props.display};
  width: ${(props) => props.rowWidth};
  padding-left: 160px;
  position: relative;
  align-content: center;
  height: auto;
  top: ${(props) => props.top};
`;

const Row = ({ display, top, marginTop, marginBottom, children }) => {
  const data = useSelector((state) => state.dataReducer.data);
  const rowCount = data.row[4].column.length
  const rowWidth = rowCount * 473

  return (
    <StyledRow
      display={display}
      top={top}
      marginTop={marginTop}
      marginBottom={marginBottom}
      width={rowWidth}
    >
      {children}
    </StyledRow>
  );
};

export default Row;
