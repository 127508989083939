import Timeline from "components/DynamicMappingTool/GanttChart/Timeline";
import GanttChart from "components/DynamicMappingTool/GanttChart";

const GanttContainer = () => {
  return (
    <div>
      <Timeline />
      <GanttChart />
    </div>
  );
};

export default GanttContainer;
