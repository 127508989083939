import { useRef, useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import styled from "styled-components";
import StyledTable from "containers/Pages/Admin/Table";
import axiosConfig from "services/api/axiosConfiig";
import { v4 as uuidv4 } from "uuid";
import { FaRegTrashAlt } from "react-icons/fa";
import PrimaryButton from "components/shared/Buttons/PrimaryButton";
import BarLoader from "react-spinners/BarLoader";
import { SPECSAVERS_GREEN, SPECSAVERS_DARK_GREEN } from "constants/index";
import { FiUploadCloud } from "react-icons/fi";
import { FaDownload } from "react-icons/fa";
import { BsFolderFill } from "react-icons/bs";

const Container = styled.div`
  margin-left: 10vw;
  width: 80vw;
  height: auto;
  margin-top: 84px;
  margin-bottom: 40px;
`;

const Header = styled.div`
  width: 100%;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #d1d7dc;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 1.4rem;
  margin-bottom: 15px;
`;

const UploadContainer = styled.div`
  width: 70%;
  margin-left: 15%;
  height: 400px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 28px;
  padding-bottom: 30px;
  border-bottom: 1px solid #d1d7dc;
  margin-bottom: 40px;
`;

const UploadArea = styled.div`
  background-color: #f0f0f0;
  width: 60vw;
  min-height: 275px;
  max-height: 275px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;
`;

const FilesContainer = styled.div`
  width: 70%;
  margin-left: 15%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 28px;
  padding-bottom: 30px;
  border-bottom: 1px solid #d1d7dc;
  margin-bottom: 40px;
`;

const DeleteIcon = styled(FaRegTrashAlt)`
  color: white;
  font-size: 1.1rem;
  margin-left: 9px;
  margin-bottom: -2px;
`;

const RedDeleteIcon = styled(FaRegTrashAlt)`
  color: red;

  &:hover {
    cursor: pointer;
    color: black;
  }
`;
const RemoveTempFile = styled(FaRegTrashAlt)`
  color: black;
  margin-left: 10px;
  margin-top: 22px;

  &:hover {
    cursor: pointer;
    color: red;
  }
`;

const SelectBox = styled.select`
  padding: 10px;
  height: 55px;
  margin-top: 16px;
  margin-bottom: 22px;
  width: 350px;
  font-size: 0.9rem;
`;

const StlyedUploadIcon = styled(FiUploadCloud)`
  font-size: 20px;
  margin-left: 4px;
  position: relative;
  top: 5px;
`;

const StyledDownloadIcon = styled(FaDownload)`
  font-size: 20px;
  margin-left: 4px;
  position: relative;
  color: ${SPECSAVERS_GREEN};

  &:hover {
    cursor: pointer;
    color: ${SPECSAVERS_DARK_GREEN};
  }
`;
const StyledWhiteDownloadIcon = styled(FaDownload)`
  font-size: 20px;
  margin-left: 4px;
  position: relative;
`;

const StyledFolderIcon = styled(BsFolderFill)`
  font-size: 20px;
  margin-left: 4px;
  position: relative;
  top: 5px;
`;

const StyledUploadInput = styled.input`
  background: grey;
  color: #ffffff;
`;

const FileName = styled.div`
  margin-top: 12px;
  margin-bottom: 15px;
  background-color: #dddddd;
  min-height: 40px;
  max-height: 40px;
  min-width: 400px;
  max-width: 400px;
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding-left: 15px;
  padding-right: 15px;
  overflow: hidden;
`;

const FileNameGap = styled.div`
  min-height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FileNameArea = styled.div`
  width: 455px;
  display: flex;
`;

const Index = () => {
  /** Changes the first character of the parameter a capital */
  let { country } = useParams();
  let marketTitle = country[0].toUpperCase() + country.slice(1);
  const fileTypeRef = useRef();

  const [rendered, setRendered] = useState(true);
  const [deleting, setDeleting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [showSelectBtn, setShowSelectBtn] = useState(true);
  const [showUploadBtn, setShowUploadBtn] = useState(false);
  const [uploadTitle, setUploadTitle] = useState("Upload a new file");
  const [fileData, setFileData] = useState({});
  const [fileName, setFileName] = useState("");
  const accessToken = localStorage.getItem("token");

  let marketTitleLower = country.toLowerCase();

  const marketIDSwitch = (marketTitleLower) => {
    switch (marketTitleLower) {
      case "norway":
        return 1;
      case "sweden":
        return 2;
      case "finland":
        return 3;
      case "denmark":
        return 4;
      case "netherlands":
        return 5;

      default:
        return 1;
    }
  };

  const marketID = marketIDSwitch(marketTitleLower);

  const getDataHandler = async () => {
    const config = {
      method: "get",
      headers: {
        Authorization: accessToken,
      },
    };

    await axiosConfig(`/markets/${marketID}/files`, config)
      .then(function (response) {
        setFileData(response.data);
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const deleteFileHandler = async (userId) => {
    if (window.confirm("Are you sure you want to delete this file?")) {
      setDeleting(true);
      const config = {
        method: "delete",
        headers: {
          Authorization: accessToken,
        },
      };

      await axiosConfig(`/markets/${marketID}/files/${userId}`, config)
        .then(function () {
          setDeleting(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };
  const handleUpload = (event) => {
    event.preventDefault();
    if (event.currentTarget["fileInput"].files[0]) {
      const file = event.currentTarget["fileInput"].files[0];
      const formData = new FormData();
      formData.append("file", file);

      try {
        setIsLoading(true);
        const { data } = axiosConfig
          .post(
            `/markets/${marketID}/files`,
            {
              categoryType: fileTypeRef.current.value,
            },
            {
              headers: {
                Authorization: accessToken,
              },
            }
          )
          .then((response) => {
            // Success
            imageUploadHandler(response.data.id, file);
          });
      } catch (err) {
        // const errorMessage = err.response.data.message;
        const errorMessage = err;
        console.log(errorMessage);
      }
    }
  };

  const imageUploadHandler = (id, file) => {
    setIsUploading(true);
    setUploadTitle("Uploading File...");

    const myHeaders = new Headers();
    myHeaders.append("Authorization", accessToken);

    const formdata = new FormData();
    formdata.append("file", file);

    const config = {
      method: "post",
      headers: {
        Authorization: accessToken,
      },
      data: formdata,
    };

    axiosConfig(`/markets/${marketID}/files/${id}/data`, config)
      .then(function (response) {
        setFileName();
        setShowSelectBtn(true);
        setShowUploadBtn(false);
        setUploadTitle("Upload a new file");
        setIsUploading(false);
        setIsLoading(false);
        setRendered(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const hiddenFileInput = useRef();
  const handleSelectFile = (event) => {
    hiddenFileInput.current.click();
  };

  const onChangeHandler = (event) => {
    setShowSelectBtn(false);
    setShowUploadBtn(true);
    setFileName(event.target.files[0].name);
  };

  const removeTempFileHandler = () => {
    setUploadTitle("Removing file...");
    setIsUploading(true);
    setTimeout(function () {
      setShowSelectBtn(true);
      setShowUploadBtn(false);
      setFileName();
      setUploadTitle("Upload a new file");
      setIsUploading(false);
    }, 500);
  };

  useEffect(() => {
    getDataHandler();
  }, [rendered, uploadTitle, deleting]);

  const fileDownloadHandler = async (fileId, fileName) => {
    const config = {
      method: "get",
      responseType: "arraybuffer",
      headers: {
        Authorization: accessToken,
      },
    };

    await axiosConfig(`/markets/${marketID}/files/${fileId}/data`, config)
      .then(function (response) {
        const type = response.headers["content-type"];
        const blob = new Blob([response.data], {
          type: type,
          encoding: "UTF-8",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <Container>
      <Header>
        <Title>File Manager</Title>Manage the files available on the{" "}
        {marketTitle} Market page.
      </Header>
      <UploadContainer>
        <UploadArea>
          <Title>{uploadTitle}</Title>
          {isUploading ? (
            <BarLoader color={SPECSAVERS_GREEN} />
          ) : (
            <form onSubmit={handleUpload}>
              <SelectBox ref={fileTypeRef}>
                <option value="PainPointProject" selected>
                  Pain Point Project
                </option>
                <option value="StaticMap">Static Map</option>
              </SelectBox>
              <br />
              {showSelectBtn && (
                <PrimaryButton
                  paddingBottom="18px"
                  width="150px"
                  type="submit"
                  onClick={handleSelectFile}
                >
                  {" "}
                  Select File <StyledFolderIcon />
                </PrimaryButton>
              )}
              <StyledUploadInput
                ref={hiddenFileInput}
                onChange={onChangeHandler}
                id="fileInput"
                type="file"
                style={{ display: "none" }}
              />
              {fileName ? (
                <>
                  <FileNameArea>
                    <FileName>{fileName}</FileName>
                    <RemoveTempFile onClick={removeTempFileHandler} />
                  </FileNameArea>
                </>
              ) : (
                <FileNameGap />
              )}
              {showUploadBtn && (
                <PrimaryButton paddingBottom="17px" width="150px" type="submit">
                  {" "}
                  Upload <StlyedUploadIcon />
                </PrimaryButton>
              )}
            </form>
          )}
        </UploadArea>
      </UploadContainer>
      <FilesContainer>
        <Title style={{ marginTop: "-30px", marginBottom: "30px" }}>
          Manage Files
        </Title>
        {!isLoading ? (
          <StyledTable>
            <colgroup>
              <col />
              <col />
              <col />
            </colgroup>
            <thead>
              <tr>
                <th>
                  <StyledWhiteDownloadIcon style={{ marginLeft: "12px" }} />
                </th>
                <th>File Name</th>
                <th>Category Type</th>
                <th>
                  <DeleteIcon />
                </th>
              </tr>
            </thead>
            <tbody>
              {fileData.map((fileItem, index) => (
                <tr key={index}>
                  <td key={uuidv4()}>
                    <StyledDownloadIcon
                      onClick={() => {
                        fileDownloadHandler(fileItem.id, fileItem.name);
                      }}
                    />
                  </td>
                  <td key={uuidv4()}>{fileItem.name}</td>
                  <td key={uuidv4()}>{fileItem.categoryType}</td>
                  <td key={uuidv4()}>
                    <RedDeleteIcon
                      onClick={() => deleteFileHandler(fileItem.id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </StyledTable>
        ) : (
          <>Loading...</>
        )}
        <br />
      </FilesContainer>
    </Container>
  );
};

export default Index;
