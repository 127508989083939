import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { mapSettingsActions } from "redux/store/map-settings-slice";
import { WHITE } from "constants/index";
import { IoGlassesOutline, IoMan } from "react-icons/io5";
import { TiArrowSortedDown } from "react-icons/ti";
import { FaDownload, FaFlag, FaMap } from "react-icons/fa";
import { HiOutlineViewList } from "react-icons/hi";
import { MdShare } from "react-icons/md";
import { AiOutlineColumnWidth } from "react-icons/ai";
import { RiRoadMapFill } from "react-icons/ri";
import MarketMenu from "./Menus/Markets";
import ProductMenu from "./Menus/Products";
import CustomerMenu from "./Menus/Customers";
import LanguageMenu from "./Menus/Language";
import RowMenu from "./Menus/Rows";
import DownloadMenu from "./Menus/Download";

const StyledToolBarButton = styled.div`
  text-align: center;
  width: 5vw;
  height: 5vw;
  font-size: 1rem;
  margin-left: 2px;
`;

const HoverEffect = styled.div`
  &:hover {
    cursor: pointer;
    filter: drop-shadow(0px 0px 8px ${WHITE});
  }
  &:active {
    cursor: pointer;
    filter: drop-shadow(0px 0px 8px ${WHITE});
  }
`;

const ButtonText = styled.div`
  font-size: 0.8vw;
`;

const ArrowIcon = styled(TiArrowSortedDown)`
  font-size: 1vw;
`;

const GlassesIcon = styled(IoGlassesOutline)`
  position: relative;
  font-size: 2.8vw;
  top: 0.3vw;
`;

const MarketIcon = styled(RiRoadMapFill)`
  font-size: 2.4vw;
  margin-bottom: 0.2vw;
  margin-top: 0.2vw;
`;

const FlagIcon = styled(FaFlag)`
  font-size: 1.8vw;
  margin-bottom: 0.4vw;
  margin-top: 0.6vw;
`;

const CustomerIcon = styled(IoMan)`
  font-size: 2.2vw;
  top: 1vw;
  margin-top: 0.5vw;
  margin-bottom: 0.1vw;
`;

const RowsIcon = styled(HiOutlineViewList)`
  font-size: 2.3vw;
  margin-top: 10px;
`;

const DownloadIcon = styled(FaDownload)`
  font-size: 1.8vw;
  margin-bottom: 9px;
  margin-top: 10px;
`;

const ShareIcon = styled(MdShare)`
  font-size: 2.2vw;
  margin-top: 7px;
  margin-bottom: 5px;
`;

const GanttIcon = styled(AiOutlineColumnWidth)`
  font-size: 2.2vw;
  margin-top: 7px;
  margin-bottom: 5px;
`;

const MapIcon = styled(FaMap)`
  font-size: 2.2vw;
  margin-top: 7px;
  margin-bottom: 5px;
`;

const ToolBarButton = ({ icon, buttonText, showArrow, downloadPdf }) => {
  const ref = useRef();
  const dispatch = useDispatch();

  const handleClickOutside = (e) => {
    if (!ref.current.contains(e.target)) {
      setActiveMenu();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  const [activeMenu, setActiveMenu] = useState();

  const shareHandler = () => {
    dispatch(mapSettingsActions.popupForm("share"));
    dispatch(mapSettingsActions.popupToggle());
  };

  const ganttHandler = () => {
    dispatch(mapSettingsActions.toggleMapGantt());
  };

  const menuUpdater = (icon) => {
    if (!activeMenu && icon === "globe") {
      setActiveMenu(<MarketMenu />);
    } else if (!activeMenu && icon === "product") {
      setActiveMenu(<ProductMenu />);
    } else if (!activeMenu && icon === "customer") {
      setActiveMenu(<CustomerMenu />);
    } else if (!activeMenu && icon === "language") {
      setActiveMenu(<LanguageMenu />);
    } else if (!activeMenu && icon === "rows") {
      setActiveMenu(<RowMenu />);
    } else if (!activeMenu && icon === "download") {
      setActiveMenu(<DownloadMenu />);
    } else {
      setActiveMenu();
    }
  };

  const iconSwitch = () => {
    switch (icon) {
      case "globe":
        return <MarketIcon onClick={() => menuUpdater(icon)} />;
      case "product":
        return <GlassesIcon onClick={() => menuUpdater(icon)} />;
      case "customer":
        return <CustomerIcon onClick={() => menuUpdater(icon)} />;
      case "language":
        return <FlagIcon onClick={() => menuUpdater(icon)} />;
      case "rows":
        return <RowsIcon onClick={() => menuUpdater(icon)} />;
      case "download":
        return <DownloadIcon onClick={() => menuUpdater(icon)} />;
      case "share":
        return <ShareIcon onClick={shareHandler} />;
      case "gantt":
        return <GanttIcon onClick={ganttHandler} />;
      case "map":
        return <MapIcon onClick={ganttHandler} />;

      default:
        return <FlagIcon />;
    }
  };

  return (
    <StyledToolBarButton>
      <HoverEffect>
        {iconSwitch()}
        <ButtonText>{buttonText}</ButtonText>
        {showArrow && <ArrowIcon />}
      </HoverEffect>
      <div ref={ref}>{activeMenu}</div>
    </StyledToolBarButton>
  );
};

export default ToolBarButton;
