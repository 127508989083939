import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { authActions } from "redux/store/auth-slice";
import styled from "styled-components";
import { FaUserCircle } from "react-icons/fa";

const Container = styled.div`
  float: right;
  right: 40px;
  position: absolute;
  top: 12px;
  padding: 10px;
`;

const UserIcon = styled(FaUserCircle)`
  font-size: 2.1rem;
  color: #009b4f;
  cursor: pointer;
`;

const UserMenu = styled.div`
  width: 70px;
  height: 40px;
  font-size: 0.9rem;
  text-align: center;
  position: absolute;
  left: -21px;
  border: 1px solid #d1d7dc;
  line-height: 20px;
  padding: 10px;
  border-radius: 3px;
  background-color: white;
  z-index: 2;
`;

const MenuButton = styled.div`
  width: 70px;
  cursor: pointer;

  &:hover {
    color: #009b4f;
  }
`;

const UserButton = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const menuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const settingsHandler = () => {
    setIsMenuOpen(!isMenuOpen);

    history.replace("/settings");
  };

  const logoutHandler = () => {
    setIsMenuOpen(!isMenuOpen);

    dispatch(authActions.logoutHandler());
    history.replace("/login");
  };

  return (
    <Container>
      <UserIcon onClick={menuToggle} />
      {isMenuOpen && (
        <UserMenu>
          <MenuButton onClick={settingsHandler}>Settings</MenuButton>{" "}
          <MenuButton onClick={logoutHandler}>Log Out</MenuButton>
        </UserMenu>
      )}
    </Container>
  );
};

export default UserButton;
