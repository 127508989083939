// Returns a gradient using the section colour
export const backgroundHandler = (primaryHex) => {
  const secondaryHex =
    "#" +
    primaryHex
      .replace(/^#/, "")
      .replace(/../g, (primaryHex) =>
        (
          "0" +
          Math.min(255, Math.max(0, parseInt(primaryHex, 16) + 20)).toString(16)
        ).substr(-2)
      );
  return `linear-gradient(144deg, ${primaryHex} 0%, ${secondaryHex} 100%)`;
};
