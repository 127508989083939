import styled from "styled-components";

const Container = styled.div`
  position: relative;
  top: -35px;
`;

const FormHeading = styled.h1`
  font-size: 1.5rem;
`;

const DocumentList = styled.div`
  margin-top: 20px;
  color: darkblue;
  cursor: pointer;
`;

const NoDocuments = styled.div`
  margin-top: 20px;
`;

const Documents = () => {
  return (
    <div>
      <Container>
        <FormHeading>Documents</FormHeading>
        {/* <DocumentList>Example.pdf</DocumentList> */}
        <NoDocuments>There are no documents for this section</NoDocuments>
      </Container>
    </div>
  );
};

export default Documents;
