import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { mapSettingsActions } from "redux/store/map-settings-slice";
import Menu from "./index";
import { FiCheck } from "react-icons/fi";
import { useParams } from "react-router-dom";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;
const LanguageName = styled.p`
  cursor: pointer;
  &:hover {
    cursor: pointer;
    filter: drop-shadow(0px 0px 8px #fff);
  }
`;

const TickIcon = styled(FiCheck)`
  margin-left: 45px;
  font-size: 1.1rem;
  position: relative;
  top: 3px;
`;

const Language = () => {
  let { marketTitle } = useParams();
  marketTitle = marketTitle.toLowerCase();

  const languageToNameSwitch = (marketTitle) => {
    switch (marketTitle) {
      case "norway":
        return "Norwegian";
      case "sweden":
        return "Swedish";
      case "finland":
        return "Finnish";
      case "denmark":
        return "Danish";
      case "netherlands":
        return "Dutch";

      default:
        return "Market";
    }
  };

  const marketLanguage = languageToNameSwitch(marketTitle);

  const dispatch = useDispatch();
  const currentLanguage = useSelector(
    (state) => state.mapSettingsReducer.primaryMapLanguage
  );

  const mapLanguageHandler = () => {
    dispatch(mapSettingsActions.toggleMapLanguage());
  };

  return (
    <Menu width="180px">
      <Container>
        <LanguageName onClick={mapLanguageHandler}>
          English {currentLanguage && <TickIcon />}
        </LanguageName>
        <LanguageName onClick={mapLanguageHandler}>
          {marketLanguage} {!currentLanguage && <TickIcon />}
        </LanguageName>
      </Container>
    </Menu>
  );
};

export default Language;
