import styled from "styled-components";
import Menu from "./index";
import { mapSettingsActions } from "redux/store/map-settings-slice";
import { useSelector, useDispatch } from "react-redux";

const RowOptions = styled.div`
  accent-color: #b8d432;
  text-align: left;
  padding-left: 15px;
  padding-top: 5px;
  width: 250px;
  max-height: 150px;
  line-height: 25px;
  overflow: scroll;
  overflow-x: hidden;
`;

const StyledCheckbox = styled.input`
  margin-right: 7px;
  background-color: red;
`;

const Rows = () => {
  const dispatch = useDispatch();

  const checkboxHandler = (index) => {
    dispatch(mapSettingsActions.toggleRowVisibility(index));
  };

  const rowVisibilityData = useSelector(
    (state) => state.mapSettingsReducer.rowVisibilityData
  );

  return (
    <Menu width="250px">
      <RowOptions>
        {rowVisibilityData.row.map((rowData, index) => (
          <div>
            {rowData.visible ? (
              <StyledCheckbox
                type="checkbox"
                onChange={() => checkboxHandler(index)}
                checked
              />
            ) : (
              <StyledCheckbox
                type="checkbox"
                onChange={() => checkboxHandler(index)}
              />
            )}
            {rowData.row_name}
          </div>
        ))}
      </RowOptions>
    </Menu>
  );
};

export default Rows;
