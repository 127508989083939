import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { WHITE } from "constants/index";
import { useSelector } from "react-redux";
import NavLinks from "./NavLinks.js";
import NavLogo from "./NavLogo.js";
import NavTop from "./NavTop.js";
import UserButton from "./UserButton.js";

const NavBar = styled.div`
  position: fixed;
  display: block;
  top: 0;
  background-color: ${WHITE};
  z-index: 1;
`;

const Navbar = () => {
  const location = useLocation();
  const pathname = location.pathname;

  let showNavLinks = true;

  if (pathname.substring(0, 4) === "/map") {
    showNavLinks = false;
  }

  const isLoggedIn = useSelector((state) => state.authReducer.isLoggedIn);

  return (
    <NavBar>
      <NavTop>
        <NavLogo />
        {isLoggedIn && <UserButton />}
      </NavTop>
      {showNavLinks && <NavLinks />}
    </NavBar>
  );
};

export default Navbar;
