import { useState, useEffect } from "react";
import styled from "styled-components";
import StyledTable from "containers/Pages/Admin/Table";
import axiosConfig from "services/api/axiosConfiig";
import { v4 as uuidv4 } from "uuid";

const Container = styled.div`
  margin-left: 10vw;
  width: 80vw;
  height: auto;
  margin-top: 84px;
  margin-bottom: 40px;
`;

const Header = styled.div`
  width: 100%;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #d1d7dc;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 1.4rem;
  margin-bottom: 10px;
`;

const UsersContainer = styled.div`
  width: 70%;
  margin-left: 15%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 28px;
  padding-bottom: 30px;
  border-bottom: 1px solid #d1d7dc;
  margin-bottom: 40px;
`;

const Index = () => {
  const [rendered, setRendered] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [inviteData, setInviteData] = useState({});
  const accessToken = localStorage.getItem("token");

  const getDataHandler = async () => {
    const config = {
      method: "get",
      headers: {
        Authorization: accessToken,
      },
    };

    await axiosConfig(`/invitations`, config)
      .then(function (response) {
        setInviteData(response.data);
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getDataHandler();
  }, [rendered]);

  return (
    <Container>
      <Header>
        <Title>Pending Invites</Title>View invites that haven't been accepted
        yet.
      </Header>
      <UsersContainer>
        {!isLoading ? (
          <StyledTable>
            <colgroup>
              <col />
              <col />
              <col />
            </colgroup>
            <thead>
              <tr>
                <th>Email Address</th>
                <th>Account Type</th>
                <th>Market</th>
              </tr>
            </thead>
            <tbody>
              {inviteData.map((inviteItem, index) => (
                <tr key={uuidv4()}>
                  <td key={uuidv4()}>
                    {inviteItem.email}
                  </td>
                  <td key={uuidv4()}>{inviteItem.accountType}</td>
                  <td key={uuidv4()}>{inviteItem.marketType}</td>
                </tr>
              ))}
            </tbody>
          </StyledTable>
        ) : (
          <>Loading...</>
        )}
      </UsersContainer>
    </Container>
  );
};

export default Index;
