import React from "react";
import { useState, useEffect } from "react";
import ReactToPdf from "react-to-pdf";
import Grid from "./Grid";
import MapToolbar from "components/DynamicMappingTool/Toolbar";
import MapContainer from "../Rows";
import GanttContainer from "containers/Gantt";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { dataActions } from "redux/store/data-slice";
import { useParams } from "react-router-dom";
import Popup from "containers/Popup/Popup";
import GridLoader from "react-spinners/GridLoader";
import axiosConfig from "services/api/axiosConfiig";
import { SPECSAVERS_GREEN } from "constants/index";
import { mapSettingsActions } from "redux/store/map-settings-slice";

const ToolContainer = styled.div`
  text-align: center;
  margin-top: 15px;
  margin-bottom: 366px;
`;

const LoadingContainer = styled.div`
  width: 100vw;
  height: 0px;
  position: relative;
  top: 32vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 2;
`;
const LoadingDataContainer = styled.div`
  width: 100vw;
  position: relative;
  height: 400px;
  top: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 2;
`;

const LoadingText = styled.p`
  margin-top: 25px;
  font-size: 2rem;
  font-weight: bold;
  color: ${SPECSAVERS_GREEN};
`;

const DynamicMappingTool = () => {
  const dispatch = useDispatch();
  const [rowWidth, setRowWidth] = useState(1000);
  const [loadingMapData, setLoadingMapData] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Loading Map Data...");
  const [mapName, setMapName] = useState("");
  const [mapOwner, setMapOwner] = useState("");
  const [mapType, setMapType] = useState("");
  const data = useSelector((state) => state.dataReducer.data);
  const userId = useSelector((state) => state.mapSettingsReducer.data);

  const accessToken = localStorage.getItem("token");

  let { marketTitle } = useParams();
  let marketTitleLower = marketTitle.toLowerCase();
  let { mapId } = useParams();

  const marketIDSwitch = (marketTitleLower) => {
    switch (marketTitleLower) {
      case "norway":
        return 1;
      case "sweden":
        return 2;
      case "finland":
        return 3;
      case "denmark":
        return 4;
      case "netherlands":
        return 5;

      default:
        return 1;
    }
  };

  const marketID = marketIDSwitch(marketTitleLower);

  dispatch(mapSettingsActions.setMapId(mapId));
  dispatch(mapSettingsActions.setMapName(mapName));
  dispatch(mapSettingsActions.setMapOwner(mapOwner));
  dispatch(mapSettingsActions.setMapType(mapType));

  // axios to get map data
  const getMapData = async () => {
    const config = {
      method: "get",
      headers: {
        Authorization: accessToken,
      },
    };

    await axiosConfig(`/markets/${marketID}/maps/${mapId}`, config)
      .then(function (response) {
        dispatch(dataActions.setMapData(JSON.parse(response.data.data)));
        setMapName(response.data.name);
        setMapOwner(response.data.createdBy);
        setMapType(response.data.mapType);
        setLoadingMapData(true);
      })
      .catch(function (error) {
        console.log(error);
        // if (error.response.status === 404) {
        //   setLoadingMessage("This map doesn't exist, please try again.");
        // } else {
        //   setLoadingMessage("An unknown error occurred");
        //   console.log(error);
        // }
      });
  };

  // If data loaded
  if (!Object.keys(data).length === 0) {
    const rowCount = data.row[4].column.length;
    setRowWidth(rowCount * 245);
  }

  useEffect(() => {
    getMapData();
  }, []);

  const ref = React.createRef();
  const options = {
    orientation: "landscape",
    unit: "px",
    format: [8000, 8000],
  };

  const showPopup = useSelector((state) => state.mapSettingsReducer.showPopup);
  const showMap = useSelector((state) => state.mapSettingsReducer.showMap);
  const isLoading = useSelector((state) => state.mapSettingsReducer.isLoading);
  const randomNumber = Math.random() * 1000;

  return (
    <>
      {!loadingMapData ? (
        <LoadingDataContainer>
          <GridLoader color={SPECSAVERS_GREEN} />
          <LoadingText>{loadingMessage}</LoadingText>
        </LoadingDataContainer>
      ) : (
        <ReactToPdf
          targetRef={ref}
          filename={`Customer_Journey_PDF_${randomNumber}.pdf`}
          options={options}
          x={1}
          y={20}
          scale={1}
        >
          {({ toPdf }) => (
            <div>
              {showPopup && <Popup />}
              <MapToolbar downloadPdf={toPdf} />
              {isLoading && (
                <LoadingContainer>
                  <GridLoader color={SPECSAVERS_GREEN} />
                  <LoadingText>Loading Map...</LoadingText>
                </LoadingContainer>
              )}
              <Grid>
                <div ref={ref}>
                  {!isLoading && (
                    <ToolContainer>
                      {showMap ? <MapContainer /> : <GanttContainer />}
                    </ToolContainer>
                  )}
                </div>
              </Grid>
            </div>
          )}
        </ReactToPdf>
      )}
    </>
  );
};

export default DynamicMappingTool;
