import styled from "styled-components";

const StyledHexagon = styled.div`
  color: black;
  overflow: hidden;
  background: transparent;
  width: 130px;
  height: 130px;
  transform: rotate(-30deg) skewX(30deg) scaleY(0.866);

  &:before {
    position: absolute;
    right: 6.7%;
    bottom: 0;
    left: 6.7%;
    top: 0;
    transform: scaleY(1.155) skewX(-30deg) rotate(30deg);
    background-color: ${(props) => props.backgroundColour};
    content: "";
  }
`;

const Hexagon = ({ backgroundColour }) => {
  return (
    <div>
      <StyledHexagon backgroundColour={backgroundColour} />
    </div>
  );
};

export default Hexagon;
