import styled from "styled-components";
import TopBar from "./TopBar";
import BottomBar from "./BottomBar";
import img from "assets/images/curves-bg.png";
import { useSelector, useDispatch } from "react-redux";
import { mapSettingsActions } from "redux/store/map-settings-slice";

const Container = styled.div`
  width: 100vw;
  height: 75px;
  padding: 45px 0px 0px 25px;
  display: flex;
  flex-direction: column;
  position: relative;
  top: -45px;
`;

const ContainerBackground = styled.div`
  background: rgb(0, 155, 79);
  background: linear-gradient(
    144deg,
    rgba(0, 155, 79, 1) 0%,
    rgba(0, 180, 92, 1) 100%
  );

  width: 100vw;
  height: 132px;
  position: absolute;
  z-index: -1;
  left: 0px;
  box-shadow: inset 30px 30px 100px 15px #00964c;
    background-image: url(${img});
  }

`;

const Index = ({ downloadPdf }) => {
  const dispatch = useDispatch();

  const downloadPdfBoolean = useSelector(
    (state) => state.mapSettingsReducer.downloadPdf
  );

  const downloadPdfHandler = () => {
    downloadPdf();
  };

  if (downloadPdfBoolean) {
    alert("Generating PDF file... This may take a few seconds!");
    downloadPdfHandler();
    dispatch(mapSettingsActions.toggleDownloadPdf());
  }

  return (
    <Container>
      <ContainerBackground />
      <TopBar />
      <BottomBar />
    </Container>
  );
};

export default Index;
