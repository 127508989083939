import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

const initialDataState = {
  data: {},
  currentId: -1,
  currentChildId: -1,
  sectionId: -1,
};

const dataSlice = createSlice({
  name: "data",
  initialState: initialDataState,
  reducers: {
    // Get map data
    setMapData(state, action) {
      state.data = action.payload;
    },
    // Update
    updateCurrentId(state, action) {
      state.currentId = action.payload;
    },
    updateCurrentChildId(state, action) {
      state.currentChildId = action.payload;
    },
    updateSectionID(state, action) {
      state.sectionId = action.payload;
    },
    updateHeading(state, action) {
      state.data.row[0].column[state.currentId].primary_text =
        action.payload.updatedPrimaryText;
      state.data.row[0].column[state.currentId].secondary_text =
        action.payload.updatedSecondaryText;
      state.data.row[0].column[state.currentId].background_colour =
        action.payload.updatedHexCode;
    },
    updateJourneyStage(state, action) {
      state.data.row[1].column[state.currentId].primary_text =
        action.payload.updatePrimaryText;
      state.data.row[1].column[state.currentId].secondary_text =
        action.payload.updateSecondaryText;
      state.data.row[1].column[state.currentId].illustration =
        action.payload.updateIllustration;
    },
    updateCustomerNeeds(state, action) {
      state.data.row[2].column[state.currentId].primary_text =
        action.payload.updatedPrimaryText;
      state.data.row[2].column[state.currentId].secondary_text =
        action.payload.updatedSecondaryText;
    },
    updateTimedSubStage(state, action) {
      state.data.row[4].column[state.currentId].primary_text =
        action.payload.updatedPrimaryText;
      state.data.row[4].column[state.currentId].secondary_text =
        action.payload.updatedSecondaryText;
    },
    updateColleaguePriorities(state, action) {
      state.data.row[3].column[state.currentId].primary_text =
        action.payload.updatePrimaryText;
      state.data.row[3].column[state.currentId].secondary_text =
        action.payload.updateSecondaryText;
    },
    updateColleaguePrioritiesOrder(state, action) {
      state.data.row[3].column = action.payload.newData;
    },
    updateTime(state, action) {
      // Calculates auto value
      let autoValue = 0;

      if (action.payload.preference === "less") {
        if (action.payload.time > action.payload.unsatisfactory) {
          autoValue = -1;
        }

        if (action.payload.time > action.payload.bad) {
          autoValue = -2;
        }

        if (action.payload.time > action.payload.veryBad) {
          autoValue = -3;
        }
      }

      if (action.payload.preference === "more") {
        if (action.payload.time < action.payload.unsatisfactory) {
          autoValue = -1;
        }

        if (action.payload.time < action.payload.bad) {
          autoValue = -2;
        }

        if (action.payload.time < action.payload.veryBad) {
          autoValue = -3;
        }
      }

      state.data.row[5].column[state.currentId].total_time =
        action.payload.time;
      state.data.row[6].column[state.currentId].auto_value = autoValue;
      state.data.row[6].column[state.currentId].customer_time_preference =
        action.payload.preference;
    },
    updateColleagueActivites(state, action) {
      state.data.row[5].column[state.currentId].primary[
        state.currentChildId
      ].primary_text = action.payload.updatedPrimaryText;
      state.data.row[5].column[state.currentId].primary[
        state.currentChildId
      ].secondary_text = action.payload.updatedSecondaryText;
    },
    updateColleagueActivitesOrder(state, action) {
      state.data.row[5].column[state.currentId].primary =
        action.payload.newData;
    },
    updateVarifocalDifferences(state, action) {
      state.data.row[7].column[state.currentId].primary[
        state.currentChildId
      ].primary_text = action.payload.updatedPrimaryText;
      state.data.row[7].column[state.currentId].primary[
        state.currentChildId
      ].secondary_text = action.payload.updatedSecondaryText;
    },
    updateVarifocalDifferencesSingleColumnOrder(state, action) {
      state.data.row[7].column[action.payload.columnId].primary =
        action.payload.newData;
    },
    updateVarifocalDifferencesMultipleColumnOrder(state, action) {
      state.data.row[7].column[action.payload.sourceColumnId].primary =
        action.payload.newSourceData;
      state.data.row[7].column[action.payload.destinationColumnId].primary =
        action.payload.newDestinationData;
    },
    updateB2BDifferences(state, action) {
      state.data.row[8].column[state.currentId].primary[
        state.currentChildId
      ].primary_text = action.payload.updatedPrimaryText;
      state.data.row[8].column[state.currentId].primary[
        state.currentChildId
      ].secondary_text = action.payload.updatedSecondaryText;
    },
    updateB2BDifferencesSingleColumnOrder(state, action) {
      state.data.row[8].column[action.payload.columnId].primary =
        action.payload.newData;
    },
    updateB2BDifferencesMultipleColumnOrder(state, action) {
      state.data.row[8].column[action.payload.sourceColumnId].primary =
        action.payload.newSourceData;
      state.data.row[8].column[action.payload.destinationColumnId].primary =
        action.payload.newDestinationData;
    },
    updateCustomerTouchpoints(state, action) {
      state.data.row[9].column[state.currentId].primary[
        state.currentChildId
      ].primary_text = action.payload.updatedPrimaryText;
      state.data.row[9].column[state.currentId].primary[
        state.currentChildId
      ].secondary_text = action.payload.updatedSecondaryText;
      state.data.row[9].column[state.currentId].primary[
        state.currentChildId
      ].icon = action.payload.updatedIcon;
    },
    updateCustomerTouchpointsSingleColumnOrder(state, action) {
      state.data.row[9].column[action.payload.columnId].primary =
        action.payload.newData;
    },
    updateCustomerTouchpointsMultipleColumnOrder(state, action) {
      state.data.row[9].column[action.payload.sourceColumnId].primary =
        action.payload.newSourceData;
      state.data.row[9].column[action.payload.destinationColumnId].primary =
        action.payload.newDestinationData;
    },
    updateHygieneProcedures(state, action) {
      state.data.row[10].column[state.currentId].primary[
        state.currentChildId
      ].primary_text = action.payload.updatedPrimaryText;
      state.data.row[10].column[state.currentId].primary[
        state.currentChildId
      ].secondary_text = action.payload.updatedSecondaryText;
    },
    updateHygieneProceduresSingleColumnOrder(state, action) {
      state.data.row[10].column[action.payload.columnId].primary =
        action.payload.newData;
    },
    updateHygieneProceduresMultipleColumnOrder(state, action) {
      state.data.row[10].column[action.payload.sourceColumnId].primary =
        action.payload.newSourceData;
      state.data.row[10].column[action.payload.destinationColumnId].primary =
        action.payload.newDestinationData;
    },
    updateRequiredSkills(state, action) {
      state.data.row[11].column[state.currentId].primary[
        state.currentChildId
      ].primary_text = action.payload.updatedPrimaryText;
      state.data.row[11].column[state.currentId].primary[
        state.currentChildId
      ].secondary_text = action.payload.updatedSecondaryText;
      state.data.row[11].column[state.currentId].primary[
        state.currentChildId
      ].url = action.payload.updatedUrlText;
    },
    updateRequiredSkillsSingleColumnOrder(state, action) {
      state.data.row[11].column[action.payload.columnId].primary =
        action.payload.newData;
    },
    updateRequiredSkillsMultipleColumnOrder(state, action) {
      state.data.row[11].column[action.payload.sourceColumnId].primary =
        action.payload.newSourceData;
      state.data.row[11].column[action.payload.destinationColumnId].primary =
        action.payload.newDestinationData;
    },
    updateTechnology(state, action) {
      state.data.row[12].column[state.currentId].primary[
        state.currentChildId
      ].primary_text = action.payload.updatedPrimaryText;
      state.data.row[12].column[state.currentId].primary[
        state.currentChildId
      ].secondary_text = action.payload.updatedSecondaryText;
    },
    updateTechnologySingleColumnOrder(state, action) {
      state.data.row[12].column[action.payload.columnId].primary =
        action.payload.newData;
    },
    updateTechnologyMultipleColumnOrder(state, action) {
      state.data.row[12].column[action.payload.sourceColumnId].primary =
        action.payload.newSourceData;
      state.data.row[12].column[action.payload.destinationColumnId].primary =
        action.payload.newDestinationData;
    },
    updateSatisfactionColumnType(state, action) {
      state.data.row[6].column[state.currentId].type = action.payload;
    },
    updateManualSatisfactionValue(state, action) {
      state.data.row[6].column[state.currentId].manual_value = action.payload;
    },
    updateAutoSatisfactionValue(state, action) {
      state.data.row[6].column[action.payload.id].auto_value =
        action.payload.newActionValue;
    },
    // Create
    createColumn(state, action) {
      const headingId = uuidv4();
      // Add heading
      state.data.row[0].column.splice(action.payload.newHeadingIndex, 0, {
        id: headingId,
        primary_text: action.payload.newHeadingPrimaryText,
        secondary_text: action.payload.newHeadingSecondaryText,
        background_colour: action.payload.newHeadingHex,
        min_width: "393px",
        stages: 1,
        columns: 1,
      });

      // Add Journey Stage
      const journeyStageId = uuidv4();
      state.data.row[1].column.splice(action.payload.newJourneyStageIndex, 0, {
        id: journeyStageId,
        section_id: headingId,
        primary_text: "...",
        secondary_text: "...",
        illustration: 0,
        columns: 1,
      });

      // Add customer expectation
      state.data.row[2].column.splice(action.payload.newJourneyStageIndex, 0, {
        id: uuidv4(),
        section_id: headingId,
        primary_text: "...",
        secondary_text: "...",
      });

      // Add Priorities
      state.data.row[3].column.splice(action.payload.newPrioritiesIndex, 0, {
        id: uuidv4(),
        section_id: headingId,
        primary_text: "",
        secondary_text: "",
      });

      const secondPriority = action.payload.newPrioritiesIndex + 1;
      state.data.row[3].column.splice(secondPriority, 0, {
        id: uuidv4(),
        section_id: headingId,
        primary_text: "",
        secondary_text: "",
      });

      const thirdPriority = action.payload.newPrioritiesIndex + 1;
      state.data.row[3].column.splice(thirdPriority, 0, {
        id: uuidv4(),
        section_id: headingId,
        primary_text: "",
        secondary_text: "",
      });

      // Add Timed Sub Stage
      state.data.row[4].column.splice(action.payload.newColumnIndex, 0, {
        id: uuidv4(),
        section_id: headingId,
        stage_id: journeyStageId,
        primary_text: "...",
        secondary_text: "...",
      });

      // Add new Activities
      state.data.row[5].column.splice(action.payload.newColumnIndex, 0, {
        id: uuidv4(),
        total_time: 120,
        section_id: headingId,
        primary: [],
      });

      //Add new Satisfaction
      state.data.row[6].column.splice(action.payload.newColumnIndex, 0, {
        id: uuidv4(),
        type: "manual",
        custom_column: true,
        auto_value: 0,
        manual_value: 0,
        customer_time_preference: "less",
        neutral: 140,
        unsatisfactory: 160,
        bad: 200,
        very_bad: 300,
      });

      // Add new Varifocal
      state.data.row[7].column.splice(action.payload.newColumnIndex, 0, {
        id: uuidv4(),
        section_id: headingId,
        primary: [],
      });

      // Add new B2B
      state.data.row[8].column.splice(action.payload.newColumnIndex, 0, {
        id: uuidv4(),
        section_id: headingId,
        primary: [],
      });

      // Add new Customer Touchpoints
      state.data.row[9].column.splice(action.payload.newColumnIndex, 0, {
        id: uuidv4(),
        section_id: headingId,
        primary: [],
      });

      // Add new Hygiene Procedures
      state.data.row[10].column.splice(action.payload.newColumnIndex, 0, {
        id: uuidv4(),
        section_id: headingId,
        primary: [],
      });

      // Add new Required Skills
      state.data.row[11].column.splice(action.payload.newColumnIndex, 0, {
        id: uuidv4(),
        section_id: headingId,
        primary: [],
      });

      // Add new Technology
      state.data.row[12].column.splice(action.payload.newColumnIndex, 0, {
        id: uuidv4(),
        section_id: headingId,
        primary: [],
      });

      // Add new Technology Documents
      state.data.row[13].column.splice(action.payload.newColumnIndex, 0, {
        id: uuidv4(),
        documents: [],
      });

      // Add new Documents
      state.data.row[14].column.splice(action.payload.newColumnIndex, 0, {
        id: uuidv4(),
        documents: [],
      });
    },
    createStage(state, action) {
      const headingId = action.payload.newSectionId;

      // Add stage and column to heading
      state.data.row[0].column[action.payload.headingIndex].columns =
        action.payload.newHeadingColumnCount;
      state.data.row[0].column[action.payload.headingIndex].stages =
        action.payload.newHeadingStageCount;

      // Add Journey Stage
      const journeyStageId = uuidv4();
      state.data.row[1].column.splice(action.payload.newJourneyStageIndex, 0, {
        id: journeyStageId,
        section_id: headingId,
        primary_text: action.payload.newStagePrimaryText,
        secondary_text: action.payload.newStageSecondaryText,
        illustration: 0,
        columns: 1,
      });

      // Add customer expectation
      state.data.row[2].column.splice(action.payload.newJourneyStageIndex, 0, {
        id: uuidv4(),
        section_id: headingId,
        primary_text: "...",
        secondary_text: "...",
      });

      // Add Priorities
      state.data.row[3].column.splice(action.payload.newPrioritiesIndex, 0, {
        id: uuidv4(),
        section_id: headingId,
        primary_text: "",
        secondary_text: "",
      });

      const secondPriority = action.payload.newPrioritiesIndex + 1;
      state.data.row[3].column.splice(secondPriority, 0, {
        id: uuidv4(),
        section_id: headingId,
        primary_text: "",
        secondary_text: "",
      });

      const thirdPriority = action.payload.newPrioritiesIndex + 1;
      state.data.row[3].column.splice(thirdPriority, 0, {
        id: uuidv4(),
        section_id: headingId,
        primary_text: "",
        secondary_text: "",
      });

      // Add Timed Sub Stage
      state.data.row[4].column.splice(action.payload.newColumnIndex, 0, {
        id: uuidv4(),
        section_id: headingId,
        stage_id: journeyStageId,
        primary_text: "...",
        secondary_text: "...",
      });

      // Add new Activities
      state.data.row[5].column.splice(action.payload.newColumnIndex, 0, {
        id: uuidv4(),
        total_time: 120,
        section_id: headingId,
        primary: [],
      });

      //Add new Satisfaction
      state.data.row[6].column.splice(action.payload.newColumnIndex, 0, {
        id: uuidv4(),
        type: "manual",
        custom_column: true,
        auto_value: 0,
        manual_value: 0,
        customer_time_preference: "less",
        neutral: 140,
        unsatisfactory: 160,
        bad: 200,
        very_bad: 300,
      });

      // Add new Varifocal
      state.data.row[7].column.splice(action.payload.newColumnIndex, 0, {
        id: uuidv4(),
        section_id: headingId,
        primary: [],
      });

      // Add new B2B
      state.data.row[8].column.splice(action.payload.newColumnIndex, 0, {
        id: uuidv4(),
        section_id: headingId,
        primary: [],
      });

      // Add new Customer Touchpoints
      state.data.row[9].column.splice(action.payload.newColumnIndex, 0, {
        id: uuidv4(),
        section_id: headingId,
        primary: [],
      });

      // Add new Hygiene Procedures
      state.data.row[10].column.splice(action.payload.newColumnIndex, 0, {
        id: uuidv4(),
        section_id: headingId,
        primary: [],
      });

      // Add new Required Skills
      state.data.row[11].column.splice(action.payload.newColumnIndex, 0, {
        id: uuidv4(),
        section_id: headingId,
        primary: [],
      });

      // Add new Technology
      state.data.row[12].column.splice(action.payload.newColumnIndex, 0, {
        id: uuidv4(),
        section_id: headingId,
        primary: [],
      });

      // Add new Technology Documents
      state.data.row[13].column.splice(action.payload.newColumnIndex, 0, {
        id: uuidv4(),
        documents: [],
      });

      // Add new Documents
      state.data.row[14].column.splice(action.payload.newColumnIndex, 0, {
        id: uuidv4(),
        documents: [],
      });
    },
    createSubStage(state, action) {
      const headingId = action.payload.newSectionId;
      const stageId = action.payload.newStageId;

      // Add column to heading
      state.data.row[0].column[action.payload.headingIndex].columns =
        action.payload.newHeadingColumnCount;

      // Add column to Journey Stage
      state.data.row[1].column[action.payload.newJourneyStageIndex].columns =
        action.payload.newStageColumnCount;

      // Add Priorities
      state.data.row[3].column.splice(action.payload.newPrioritiesIndex, 0, {
        id: uuidv4(),
        section_id: headingId,
        primary_text: "",
        secondary_text: "",
      });

      const secondPriority = action.payload.newPrioritiesIndex + 1;
      state.data.row[3].column.splice(secondPriority, 0, {
        id: uuidv4(),
        section_id: headingId,
        primary_text: "",
        secondary_text: "",
      });

      const thirdPriority = action.payload.newPrioritiesIndex + 1;
      state.data.row[3].column.splice(thirdPriority, 0, {
        id: uuidv4(),
        section_id: headingId,
        primary_text: "",
        secondary_text: "",
      });

      // Add Timed Sub Stage
      state.data.row[4].column.splice(action.payload.newColumnIndex, 0, {
        id: uuidv4(),
        section_id: headingId,
        stage_id: stageId,
        primary_text: action.payload.newStagePrimaryText,
        secondary_text: action.payload.newStageSecondaryText,
      });

      // Add new Activities
      state.data.row[5].column.splice(action.payload.newColumnIndex, 0, {
        id: uuidv4(),
        total_time: 120,
        section_id: headingId,
        primary: [],
      });

      //Add new Satisfaction
      state.data.row[6].column.splice(action.payload.newColumnIndex, 0, {
        id: uuidv4(),
        type: "manual",
        custom_column: true,
        auto_value: 0,
        manual_value: 0,
        customer_time_preference: "less",
        neutral: 140,
        unsatisfactory: 160,
        bad: 200,
        very_bad: 300,
      });

      // Add new Varifocal
      state.data.row[7].column.splice(action.payload.newColumnIndex, 0, {
        id: uuidv4(),
        section_id: headingId,
        primary: [],
      });

      // Add new B2B
      state.data.row[8].column.splice(action.payload.newColumnIndex, 0, {
        id: uuidv4(),
        section_id: headingId,
        primary: [],
      });

      // Add new Customer Touchpoints
      state.data.row[9].column.splice(action.payload.newColumnIndex, 0, {
        id: uuidv4(),
        section_id: headingId,
        primary: [],
      });

      // Add new Hygiene Procedures
      state.data.row[10].column.splice(action.payload.newColumnIndex, 0, {
        id: uuidv4(),
        section_id: headingId,
        primary: [],
      });

      // Add new Required Skills
      state.data.row[11].column.splice(action.payload.newColumnIndex, 0, {
        id: uuidv4(),
        section_id: headingId,
        primary: [],
      });

      // Add new Technology
      state.data.row[12].column.splice(action.payload.newColumnIndex, 0, {
        id: uuidv4(),
        section_id: headingId,
        primary: [],
      });

      // Add new Technology Documents
      state.data.row[13].column.splice(action.payload.newColumnIndex, 0, {
        id: uuidv4(),
        documents: [],
      });

      // Add new Documents
      state.data.row[14].column.splice(action.payload.newColumnIndex, 0, {
        id: uuidv4(),
        documents: [],
      });
    },
    createColleagueActivity(state) {
      const newId = uuidv4();
      const newContainerId =
        state.data.row[5].column[state.currentId].primary.length;
      const newIdToPush = `"${newId}"`;
      state.data.row[5].column[state.currentId].primary.push({
        id: newIdToPush,
        primary_text: "",
      });
      state.currentChildId = newContainerId;
    },
    createVarifocalDifferences(state) {
      const newId = uuidv4();
      const newIdToPush = `"${newId}"`;
      const newContainerId =
        state.data.row[7].column[state.currentId].primary.length;
      state.data.row[7].column[state.currentId].primary.push({
        id: newIdToPush,
        primary_text: "",
        secoondary_text: "",
      });
      state.currentChildId = newContainerId;
    },
    createB2BDifferences(state) {
      const newId = uuidv4();
      const newIdToPush = `"${newId}"`;
      const newContainerId =
        state.data.row[8].column[state.currentId].primary.length;
      state.data.row[8].column[state.currentId].primary.push({
        id: newIdToPush,
        primary_text: "",
        secoondary_text: "",
      });
      state.currentChildId = newContainerId;
    },
    createCustomerTouchPoint(state) {
      const newId = uuidv4();
      const newIdToPush = `"${newId}"`;
      const newContainerId =
        state.data.row[9].column[state.currentId].primary.length;
      state.data.row[9].column[state.currentId].primary.push({
        id: newIdToPush,
        primary_text: "",
        secoondary_text: "",
        icon: 0,
      });
      state.currentChildId = newContainerId;
    },
    createHygieneProcedure(state) {
      const newId = uuidv4();
      const newIdToPush = `"${newId}"`;
      const newContainerId =
        state.data.row[10].column[state.currentId].primary.length;
      state.data.row[10].column[state.currentId].primary.push({
        id: newIdToPush,
        primary_text: "",
        secoondary_text: "",
      });
      state.currentChildId = newContainerId;
    },
    createRequiredSkills(state) {
      const newId = uuidv4();
      const newIdToPush = `"${newId}"`;
      const newContainerId =
        state.data.row[11].column[state.currentId].primary.length;
      state.data.row[11].column[state.currentId].primary.push({
        id: newIdToPush,
        primary_text: "",
        secoondary_text: "",
        url: "",
      });
      state.currentChildId = newContainerId;
    },
    createTechnology(state) {
      const newId = uuidv4();
      const newIdToPush = `"${newId}"`;
      const newContainerId =
        state.data.row[12].column[state.currentId].primary.length;
      state.data.row[12].column[state.currentId].primary.push({
        id: newIdToPush,
        primary_text: "",
        secoondary_text: "",
      });
      state.currentChildId = newContainerId;
    },
    // Delete
    deleteColumn(state, action) {
      // Delete heading
      state.data.row[0].column.splice(action.payload.removeColumnIndex, 1);

      // Delete stage
      state.data.row[1].column.splice(action.payload.removeStageIndex, 1);

      // Delete customer needs
      state.data.row[2].column.splice(action.payload.removeStageIndex, 1);

      // Delete additional priorities
      const firstPriorityIndex = action.payload.removeSubStageIndex * 3;
      state.data.row[3].column.splice(firstPriorityIndex, 1);
      state.data.row[3].column.splice(firstPriorityIndex, 1);
      state.data.row[3].column.splice(firstPriorityIndex, 1);

      // Remove everything else
      for (let i = 4; i < 15; i++) {
        state.data.row[i].column.splice(action.payload.removeSubStageIndex, 1);
      }
    },
    deleteStage(state, action) {
      // If last stage in column remove heading, otherwise minus 1 stage
      if (
        state.data.row[0].column[action.payload.removeHeadingIndex].stages === 1
      ) {
        state.data.row[0].column.splice(action.payload.removeHeadingIndex, 1);
      } else {
        state.data.row[0].column[action.payload.removeHeadingIndex].stages =
          state.data.row[0].column[action.payload.removeHeadingIndex].stages -
          1;
      }

      state.data.row[1].column.splice(action.payload.removeStageIndex, 1);
      state.data.row[2].column.splice(action.payload.removeStageIndex, 1);
      // Remove 3 priorities

      const firstPriorityIndex = action.payload.removeColumnIndex * 3;
      state.data.row[3].column.splice(firstPriorityIndex, 1);
      state.data.row[3].column.splice(firstPriorityIndex, 1);
      state.data.row[3].column.splice(firstPriorityIndex, 1);

      for (let i = 4; i < 15; i++) {
        state.data.row[i].column.splice(action.payload.removeColumnIndex, 1);
      }
    },
    deleteSubStage(state, action) {
      // If last column remove heading, otherwise minus 1 column

      if (
        state.data.row[0].column[action.payload.removeHeadingIndex].columns ===
        1
      ) {
        state.data.row[0].column.splice(action.payload.removeHeadingIndex, 1);
      }

      if (
        state.data.row[1].column[action.payload.removeStageIndex].columns === 1
      ) {
        state.data.row[1].column.splice(action.payload.removeStageIndex, 1);
        state.data.row[2].column.splice(action.payload.removeStageIndex, 1);
        state.data.row[0].column[action.payload.removeHeadingIndex].stages =
          state.data.row[0].column[action.payload.removeHeadingIndex].stages -
          1;
        state.data.row[0].column[action.payload.removeHeadingIndex].columns =
          state.data.row[0].column[action.payload.removeHeadingIndex].columns -
          1;
      } else {
        state.data.row[1].column[action.payload.removeStageIndex].columns =
          state.data.row[1].column[action.payload.removeStageIndex].columns - 1;
        state.data.row[0].column[action.payload.removeHeadingIndex].columns =
          state.data.row[0].column[action.payload.removeHeadingIndex].columns -
          1;
      }

      // Remove 3 priorities
      const firstPriorityIndex = action.payload.removeColumnIndex * 3;
      state.data.row[3].column.splice(firstPriorityIndex, 1);
      state.data.row[3].column.splice(firstPriorityIndex, 1);
      state.data.row[3].column.splice(firstPriorityIndex, 1);

      for (let i = 4; i < 15; i++) {
        state.data.row[i].column.splice(action.payload.removeColumnIndex, 1);
      }
    },
    deleteColleagueActivity(state, action) {
      state.data.row[5].column[state.currentId].primary.splice(
        state.currentChildId,
        1
      );
    },
    deleteVarifocalDifferences(state) {
      state.data.row[7].column[state.currentId].primary.splice(
        state.currentChildId,
        1
      );
    },
    deleteB2BDifferences(state) {
      state.data.row[8].column[state.currentId].primary.splice(
        state.currentChildId,
        1
      );
    },
    deleteCustomerTouchpoints(state) {
      state.data.row[9].column[state.currentId].primary.splice(
        state.currentChildId,
        1
      );
    },
    deleteHygieneProcedure(state) {
      state.data.row[10].column[state.currentId].primary.splice(
        state.currentChildId,
        1
      );
    },
    deleteRequiredSkills(state) {
      state.data.row[11].column[state.currentId].primary.splice(
        state.currentChildId,
        1
      );
    },
    deleteTechnology(state) {
      state.data.row[12].column[state.currentId].primary.splice(
        state.currentChildId,
        1
      );
    },
  },
});

export const dataActions = dataSlice.actions;

export default dataSlice.reducer;
